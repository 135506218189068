import React, { useEffect, useState } from "react";
import { useRagContext } from "../../../contexts/RagContext";
import SourceMaterialFolder from "./SourceMaterialFolder";
import CommonMarkdown from "../../common/CommonMarkdown";
import * as amplitude from "@amplitude/analytics-browser";
import axios from "axios";

interface SourceMaterialContentProps {
  conceptData: any;
  visible?: boolean;
  getConceptDetail: () => void;
}

const SourceMaterialContent: React.FC<SourceMaterialContentProps> = ({
  conceptData,
  visible,
  getConceptDetail,
}) => {
  const { folderList, getFilePath } = useRagContext();
  const [compoenentFileList, setComponentFileList] = useState([]);
  const [lastSelectedTopFolderId, setLastSelectedTopFolderId] =
    useState<any>("");

  const isHighlightedBrainstormFolder = (folder_id: any): boolean => {
    let isSelected = false;
    folderList.forEach((parentFolder) => {
      if (
        isHighlightedBrainstormSubFolder(
          parentFolder?.structure[Object.keys(parentFolder?.structure)[0]],
          folder_id
        )
      ) {
        isSelected = true;
      }
    });

    return isSelected;
  };

  const isSelectedFile = (file_name: any, folder_id: string): boolean => {
    if (
      compoenentFileList.find(
        (e: any) => e === getFilePath(file_name, folder_id)
      )
    ) {
      return true;
    } else {
      return false;
    }
  };

  const isHighlightedBrainstormSubFolder = (
    folder: any,
    folder_id: any
  ): boolean => {
    if (folder.id === folder_id) {
      let isSelected = false;
      if (Object.keys(folder.children).length === 0) return false;
      Object.keys(folder.children).forEach((key) => {
        let item = folder.children[key];
        if (
          (item.isFolder && isHighlightedBrainstormSubFolder(item, item.id)) ||
          (!item.isFolder && isSelectedFile(key, folder_id))
        ) {
          isSelected = true;
        }
      });
      return isSelected;
    } else {
      let isSelected = false;
      if (Object.keys(folder.children).length === 0) return false;
      Object.keys(folder.children).forEach((key) => {
        let item = folder.children[key];
        if (
          item.isFolder &&
          isHighlightedBrainstormSubFolder(item, folder_id)
        ) {
          isSelected = true;
        }
      });
      return isSelected;
    }
  };

  useEffect(() => {
    setComponentFileList(conceptData.file_list || []);
    folderList.forEach((parentFolder) => {
      const key = Object.keys(parentFolder?.structure)[0];
      if (isHighlightedBrainstormFolder(parentFolder?.structure[key].id)) {
        setLastSelectedTopFolderId(parentFolder.id);
      }
    });
  }, [conceptData.file_list, folderList]);

  const handleUpdateFileList = async (file_list: any) => {
    setComponentFileList(file_list);
    let params: any = {
      id: conceptData.id,
      file_list: file_list,
    };
    try {
      amplitude.track("Update concept source material");
      await axios.post(
        process.env.REACT_APP_API_URL + "api/editConcept",
        params
      );
      getConceptDetail();
    } catch (error) {
      console.log(error);
    }
  };

  if (!visible) return null;
  return (
    <div className="w-full p-[15px] xl:p-5">
      <div
        className="w-full overflow-x-auto overflow-y-auto mb-3 xl:mb-4"
        id="concept-source-material-container"
      >
        {folderList.map((folder, index) => {
          return folder.isFolderDeleted === 1 ? (
            <div key={index}></div>
          ) : (
            <SourceMaterialFolder
              key={index}
              folder={folder}
              fileList={conceptData.file_list}
              compoenentFileList={compoenentFileList}
              setComponentFileList={(e: any) => handleUpdateFileList(e)}
              lastSelectedTopFolderId={lastSelectedTopFolderId}
              setLastSelectedTopFolderId={setLastSelectedTopFolderId}
            ></SourceMaterialFolder>
          );
        })}
      </div>
      {conceptData.context && conceptData.context !== "" && (
        <div className="text-[15px] xl:text-xl font-medium text-[#71717A]">
          <CommonMarkdown>{conceptData.context}</CommonMarkdown>
        </div>
      )}
    </div>
  );
};

export default SourceMaterialContent;
