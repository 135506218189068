import React, { useState } from "react";
import {
  Skeleton,
  styled,
  Tooltip,
  tooltipClasses,
  TooltipProps,
} from "@mui/material";
import EditFiveForceItemModal from "./FIveForces/EditFiveForceItemModal";
import DeleteFiveForceItemModal from "./FIveForces/DeleteFiveForceItemModal";
import editPencilIcon from "../../../assets/svg/edit-pen.svg";
import TrashIcon from "../../icons/TrashIcon";
import axios from "axios";
import { useUserContext } from "../../../contexts/UserContext";
import { IoMdAdd } from "react-icons/io";
import AddFiveForceItemModal from "./FIveForces/AddFiveForceItemModal";

interface FiveForcesContentProps {
  conceptData: any;
  visible?: boolean;
  getConceptDetail: () => void;
}

export const fiveForceTabList = [
  { key: "Competitors", title: "Direct Competitors" },
  { key: "Substitutes", title: "Substitutes" },
  { key: "New_Entrants", title: "New Entrants" },
  { key: "Buyers", title: "Buyers" },
  { key: "Suppliers", title: "Suppliers" },
];

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#040404",
    color: "#e5e5e5",
    maxWidth: 220,
    border: "1px solid #dadde9",
    marginBottom: "8px !important",
    padding: "2px 4px !important",
    fontSize: "11px !important",
  },
}));

const FiveForcesContent: React.FC<FiveForcesContentProps> = ({
  conceptData,
  visible,
  getConceptDetail,
}) => {
  const { user } = useUserContext();
  const [currentTab, setCurrentTab] = useState("Competitors");
  const [loadingItems, setLoadingItems] = useState<any>([]);
  const [addModalOpen, setAddModalOpen] = useState(false);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(-1);

  const handleEditFiveForceItem = async (item: any) => {
    let tempConceptData = JSON.parse(JSON.stringify(conceptData));
    tempConceptData["FiveForces"][currentTab][selectedIndex] = item;
    let params: any = {
      id: conceptData.id,
      FiveForces: tempConceptData["FiveForces"],
    };
    try {
      let response = await axios.post(
        process.env.REACT_APP_API_URL + "api/editConcept",
        params
      );
      if (response?.status === 200) {
        getConceptDetail();
        setEditModalOpen(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleAddFiveForceItem = async (item: any) => {
    let tempConceptData = JSON.parse(JSON.stringify(conceptData));
    tempConceptData["FiveForces"][currentTab].push(item);
    let params: any = {
      id: conceptData.id,
      FiveForces: tempConceptData["FiveForces"],
    };
    try {
      let response = await axios.post(
        process.env.REACT_APP_API_URL + "api/editConcept",
        params
      );
      if (response?.status === 200) {
        getConceptDetail();
        setAddModalOpen(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleDeleteFiveForceItem = async () => {
    let tempConceptData = JSON.parse(JSON.stringify(conceptData));
    tempConceptData["FiveForces"][currentTab].splice(selectedIndex, 1);
    let params: any = {
      id: conceptData.id,
      FiveForces: tempConceptData["FiveForces"],
    };
    try {
      let response = await axios.post(
        process.env.REACT_APP_API_URL + "api/editConcept",
        params
      );
      if (response?.status === 200) {
        getConceptDetail();
        setDeleteModalOpen(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleLoadMoreFiveForce = async () => {
    if (!conceptData["FiveForces_Details"]) return;
    setLoadingItems((prev: any) => {
      return [...prev, currentTab];
    });
    try {
      let response = await axios.post(
        process.env.REACT_APP_API_URL + "api/refreshFiveForces",
        {
          team_id: user?.organizationMemberships[0]?.organization.id,
          count: 6,
          idea: conceptData["FiveForces_Details"][`${currentTab}_Question`],
          key: currentTab,
          id: conceptData.id,
        }
      );
      if (response?.status === 200) {
        setLoadingItems((prev: any) =>
          prev.filter((e: any) => e !== currentTab)
        );
        getConceptDetail();
      }
    } catch (error) {
      console.log(error);
    }
  };

  if (!visible) return null;

  return (
    <div className="w-full">
      <div className="flex items-start justify-between gap-2 w-full border-b border-solid border-[#D4D4D8]">
        <div className="flex gap-[10px]">
          {fiveForceTabList.map((fiveFoceTabItem, index) => (
            <div
              className={`px-1 pt-[1px] pb-[11px] cursor-pointer text-sm font-semibold border-b-2 border-solid ${
                fiveFoceTabItem.key === currentTab
                  ? "text-[#3B82F6] border-[#3B82F6]"
                  : "text-[#A1A1AA] border-transparent"
              }`}
              onClick={() => setCurrentTab(fiveFoceTabItem.key)}
              key={index}
            >
              {fiveFoceTabItem.title}
            </div>
          ))}
        </div>
        <button
          className="h-7 rounded-lg bg-[#3B82F6] px-4 py-[6px] flex gap-2 items-center"
          onClick={() => setAddModalOpen(true)}
        >
          <IoMdAdd className="w-4 h-4 flex-none text-white" />
          <span className="text-sm font-bold text-white">Add new</span>
        </button>
      </div>
      <div className="w-full bg-[#FCFCFC] border-l border-r border-b border-solid border-[#D4D4D8] p-4 overflow-x-hidden overflow-y-auto h-[calc(100vh-230px)] rounded-b-2xl">
        {loadingItems.find((e: any) => e === currentTab) ? (
          <div className="flex gap-2 w-full flex-col">
            <Skeleton
              height={10}
              sx={{ width: "67%", transform: "scale(1)", overflow: "hidden" }}
            />
            <Skeleton
              height={10}
              sx={{ width: "46%", transform: "scale(1)", overflow: "hidden" }}
            />
            <Skeleton
              height={10}
              sx={{ width: "37%", transform: "scale(1)", overflow: "hidden" }}
            />
            <Skeleton
              height={10}
              sx={{ width: "46%", transform: "scale(1)", overflow: "hidden" }}
            />
            <Skeleton
              height={10}
              sx={{ width: "56%", transform: "scale(1)", overflow: "hidden" }}
            />
            <Skeleton
              height={10}
              sx={{ width: "52%", transform: "scale(1)", overflow: "hidden" }}
            />
            <Skeleton
              height={10}
              sx={{ width: "43%", transform: "scale(1)", overflow: "hidden" }}
            />
            <Skeleton
              height={10}
              sx={{ width: "75%", transform: "scale(1)", overflow: "hidden" }}
            />
          </div>
        ) : (
          <div className="text-base font-normal text-[#000000cb]">
            {conceptData["FiveForces"][currentTab].length > 0 &&
              conceptData["FiveForces"][currentTab].map(
                (e: any, index: number) => (
                  <div className="mb-6 flex gap-1" key={index}>
                    <div className="flex-1">
                      <div className="font-bold">
                        <span>{e.name}</span>
                      </div>
                      <div className="">{e.relevance}</div>
                      <a
                        className="text-[#3B82F6] underline"
                        href={e.website_url}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {e.website_url}
                      </a>
                    </div>
                    <div className="flex gap-2">
                      <HtmlTooltip title={<div>Edit</div>} placement="top">
                        <button
                          className="outline-none w-7 h-7 border border-[#D4D4D8] items-center justify-center rounded-lg flex"
                          onClick={() => {
                            setSelectedIndex(index);
                            setEditModalOpen(true);
                          }}
                        >
                          <img
                            src={editPencilIcon}
                            alt=""
                            className="w-5 h-5"
                          />
                        </button>
                      </HtmlTooltip>
                      <HtmlTooltip title={<div>Delete</div>} placement="top">
                        <button
                          className="outline-none w-7 h-7 border border-[#D4D4D8] items-center justify-center rounded-lg flex"
                          onClick={() => {
                            setSelectedIndex(index);
                            setDeleteModalOpen(true);
                          }}
                        >
                          <TrashIcon
                            className="w-5 h-5 flex-none"
                            stroke="#09090B"
                          />
                        </button>
                      </HtmlTooltip>
                    </div>
                  </div>
                )
              )}
          </div>
        )}
      </div>
      <EditFiveForceItemModal
        item={conceptData["FiveForces"][currentTab][selectedIndex]}
        open={editModalOpen}
        handleClose={() => setEditModalOpen(false)}
        handleSubmit={(e) => handleEditFiveForceItem(e)}
      />
      <AddFiveForceItemModal
        open={addModalOpen}
        tab={currentTab}
        handleClose={() => setAddModalOpen(false)}
        handleSubmit={(e) => handleAddFiveForceItem(e)}
      />
      <DeleteFiveForceItemModal
        open={deleteModalOpen}
        handleClose={() => setDeleteModalOpen(false)}
        onDelete={() => handleDeleteFiveForceItem()}
      />
      <HtmlTooltip title={<div>Load More</div>} placement="top">
        <button
          className="outline-none w-7 h-7 border border-[#D4D4D8] items-center justify-center rounded-lg flex absolute bottom-0 left-[72px]"
          onClick={() => handleLoadMoreFiveForce()}
        >
          <IoMdAdd className="w-4 h-4" />
        </button>
      </HtmlTooltip>
    </div>
  );
};

export default FiveForcesContent;
