import React, { useEffect } from "react";
import { Switch } from "react-router-dom";
import PrivateRoute from "./Auth/PrivateRoute";
import PublicRoute from "./Auth/PublicRoute";
import routes from "./routes/routes";
import { useUserContext } from "./contexts/UserContext";
import { useRagContext } from "./contexts/RagContext";
import { generateUniqueId } from "./utils";
import { io } from "socket.io-client";
import { ToastContainer, Flip } from "react-toastify";
import useDynamicClickTracking from "./analytics/DynamicClickTracking";
import * as amplitude from "@amplitude/analytics-browser";

const amplitudeKey = process.env.REACT_APP_AMPLITUDE_URL || "";
const DISALLOWED_ORGANIZATIONS =
  process.env.REACT_APP_ANALYTICS_DISABLED_PARTNERS?.split("|");

const App: React.FC = () => {
  const { user } = useUserContext();
  const organization_name =
    user?.organizationMemberships["0"].organization?.name;
  useDynamicClickTracking(organization_name);
  const {
    setting,
    fetchFolders,
    getConceptFolders,
    getSettingData,
    setSocketInterface,
  } = useRagContext();
  useEffect(() => {
    if (
      user?.organizationMemberships[0]?.organization.id &&
      setting?.verified
    ) {
      fetchFolders();
      getConceptFolders();
    }
  }, [user?.organizationMemberships[0]?.organization.id, setting]);

  useEffect(() => {
    if (user?.organizationMemberships[0]?.organization.id) {
      getSettingData();
    }
  }, [user?.organizationMemberships[0]?.organization.id]);

  useEffect(() => {
    if (user?.id) {
      const socketId = generateUniqueId();
      const socket = io(`${process.env.REACT_APP_API_URL}`, {
        query: { socketId: socketId, userId: user.id },
      });

      setSocketInterface(socket, socketId);
    }
  }, [user?.id]);
  useEffect(() => {
    if (
      organization_name &&
      !DISALLOWED_ORGANIZATIONS?.includes(organization_name)
    ) {
      amplitude.init(amplitudeKey, {
        autocapture: {
          elementInteractions: true,
        },
      });
    }
  }, [organization_name]);

  return (
    <>
      <Switch>
        {routes.map((route, index) =>
          route.auth ? (
            <PrivateRoute exact {...route} key={index} />
          ) : (
            <PublicRoute exact {...route} key={index} />
          )
        )}
      </Switch>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable={false}
        pauseOnHover
        limit={1}
        transition={Flip}
      />
    </>
  );
};

export default App;
