import React from "react";

const ExpandIcon: React.FC<any> = (props: any) => {
  return (
    <svg
      viewBox="0 0 18 19"
      fill="#09090B"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M8.94 15.44L3 9.5L8.94 3.56L9.9975 4.625L5.8725 8.75H16.5V10.25H5.8725L10.005 14.375L8.94 15.44ZM3 9.5V2H1.5V17H3V9.5Z"
        fill="inherit"
      />
    </svg>
  );
};

export default ExpandIcon;
