import React from 'react'

const LightOnIcon: React.FC<any> = (props: any) => {
  return (
    <svg
      viewBox="0 0 26 26"
      fill="#3B82F6"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_845_30785)">
        <path
          d="M11.3633 6.33095C8.92447 6.89959 6.96122 8.8337 6.36169 11.2583C5.76029 13.6907 6.44858 16.17 8.20297 17.8904C8.82352 18.499 9.19407 19.4745 9.19407 20.4998V20.6634C9.19407 21.3386 9.48865 21.9462 9.95579 22.3649V23.7103C9.95579 24.9704 10.9809 25.9955 12.2409 25.9955H13.7644C15.0244 25.9955 16.0495 24.9704 16.0495 23.7103V22.3649C16.5167 21.9462 16.8113 21.3387 16.8113 20.6634V20.4968C16.8113 19.487 17.2016 18.4931 17.8555 17.8378C19.1469 16.5436 19.8581 14.8239 19.8581 12.9955C19.8581 8.62428 15.8074 5.29511 11.3633 6.33095ZM14.5261 23.7103C14.5261 24.1303 14.1844 24.472 13.7644 24.472H12.2409C11.8209 24.472 11.4792 24.1303 11.4792 23.7103V22.9486H14.5261V23.7103ZM16.777 16.7617C15.8306 17.7102 15.2878 19.0715 15.2878 20.4968V20.6634C15.2878 21.0835 14.9461 21.4252 14.5261 21.4252H11.4792C11.0592 21.4252 10.7175 21.0835 10.7175 20.6634V20.4998C10.7175 19.0526 10.1897 17.705 9.26958 16.8027C7.90407 15.4636 7.3699 13.5277 7.84054 11.624C8.30392 9.74979 9.82249 8.25449 11.7093 7.81462C15.1965 7.00146 18.3347 9.60471 18.3347 12.9955C18.3347 14.4176 17.7815 15.7551 16.777 16.7617Z"
          fill="inherit"
        />
        <path
          d="M7.07201 6.00255L4.91752 3.84811C4.62009 3.55063 4.13777 3.55063 3.84029 3.84811C3.54282 4.14558 3.54282 4.62785 3.84029 4.92533L5.99479 7.07978C6.29222 7.37725 6.77459 7.37725 7.07201 7.07978C7.36949 6.7823 7.36949 6.30003 7.07201 6.00255Z"
          fill="inherit"
        />
        <path
          d="M3.85938 12.2344H0.761719C0.341047 12.2344 0 12.5754 0 12.9961C0 13.4168 0.341047 13.7578 0.761719 13.7578H3.85938C4.28005 13.7578 4.62109 13.4168 4.62109 12.9961C4.62109 12.5754 4.28005 12.2344 3.85938 12.2344Z"
          fill="inherit"
        />
        <path
          d="M25.2344 12.2344H22.1367C21.716 12.2344 21.375 12.5754 21.375 12.9961C21.375 13.4168 21.716 13.7578 22.1367 13.7578H25.2344C25.655 13.7578 25.9961 13.4168 25.9961 12.9961C25.9961 12.5754 25.655 12.2344 25.2344 12.2344Z"
          fill="inherit"
        />
        <path
          d="M22.158 3.84811C21.8606 3.55063 21.3783 3.55063 21.0807 3.84811L18.9262 6.00255C18.6288 6.29998 18.6288 6.7823 18.9262 7.07978C19.2237 7.37725 19.706 7.37725 20.0035 7.07978L22.158 4.92533C22.4554 4.6279 22.4554 4.14558 22.158 3.84811Z"
          fill="inherit"
        />
        <path
          d="M12.9961 0C12.5754 0 12.2344 0.341047 12.2344 0.761719V3.85938C12.2344 4.28005 12.5754 4.62109 12.9961 4.62109C13.4168 4.62109 13.7578 4.28005 13.7578 3.85938V0.761719C13.7578 0.341047 13.4168 0 12.9961 0Z"
          fill="inherit"
        />
        <path
          d="M13.003 9.1875C12.6899 9.1875 12.3709 9.22503 12.0555 9.29891C10.742 9.60548 9.64247 10.6867 9.31945 11.9893C9.21819 12.3976 9.46707 12.8106 9.87535 12.9119C10.2837 13.0132 10.6968 12.7642 10.798 12.356C10.984 11.6066 11.6434 10.9595 12.4024 10.7823C12.6046 10.735 12.8067 10.7109 13.003 10.7109C13.4236 10.7109 13.7647 10.3699 13.7647 9.94922C13.7647 9.52855 13.4236 9.1875 13.003 9.1875Z"
          fill="inherit"
        />
      </g>
      <defs>
        <clipPath id="clip0_845_30785">
          <rect width={26} height={26} fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default LightOnIcon
