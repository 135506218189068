import React, { useEffect, useMemo, useState } from "react";
import { HiOutlineFolderMinus, HiOutlineFolderPlus } from "react-icons/hi2";
import { MdFileUpload, MdOutlineRefresh } from "react-icons/md";
import $ from "jquery";
import { useRagContext } from "../../contexts/RagContext";
import BpCheckbox from "../common/BPCheckbox";
import { useLayoutContext } from "../../contexts/LayoutContext";
import { getExtension } from "../../utils";

interface FolderComponentProps {
  folder: any;
  handleStartOCR: any;
}

interface TreeNode {
  id: string;
  name: string;
  children?: TreeNode[];
  path: string;
  secured_path: string;
  isExpanded?: boolean;
  isFolder?: boolean;
  isEdit?: boolean;
  isCreate?: boolean;
  isRequireOCR: boolean;
  folder_id: string;
  url?: string;
  isOCRProcessing: boolean;
}

const Folder: React.FC<FolderComponentProps> = ({ folder, handleStartOCR }) => {
  const { deviceType, sidebarWidth, setChatPosition } = useLayoutContext();
  const extraWidth = useMemo(() => {
    return sidebarWidth - 300;
  }, [sidebarWidth]);
  const [tree, setTree] = useState<TreeNode[]>([]);
  const {
    openedDocument,
    pageNumber,
    selectedSubFolderID,
    toggleSelectFolder,
    toggleSelectFile,
    isSelectedFolder,
    isSelectedFile,
    setSelectedFolderID,
    setSelectedSubFolderID,
    setOpenedDocument,
    setPageNumber,
  } = useRagContext();

  useEffect(() => {
    if (folder?.structure) {
      setTree(buildTree(folder?.structure));
    }
  }, [folder?.structure]);

  const handleDocumentClick = (document: string, folder_id: string) => {
    if (window.innerWidth > 1350) {
      setChatPosition(424);
    } else {
      setChatPosition(300);
    }

    setOpenedDocument(document);
    if (!folder_id.includes("org_")) setSelectedFolderID(folder_id);
    else setSelectedFolderID("");
    setPageNumber(pageNumber);
    const extension = getExtension(document);
    if (extension == "pdf" || extension == "pptx") {
      let pdfviewerObject: any = $("#container");
      let pdfViewer = pdfviewerObject[0]?.ej2_instances?.[0];
      if (pdfViewer) {
        pdfViewer.navigation.goToPage(pageNumber);
      }
    } else if (extension == "docx") {
      let documentObject: any = $("#docx_container");
      let documentViewer =
        documentObject[0]?.ej2_instances?.[0]?.documentEditor;
      if (documentViewer) {
        documentViewer.scrollToPage(pageNumber);
      }
    } else if (extension == "xlsx") {
      let excelObject: any = $("#xlsx_container");
      let excelViewer = excelObject[0]?.ej2_instances?.[0];
      if (excelViewer) {
        excelViewer.activeSheetIndex = pageNumber > 0 ? pageNumber - 1 : 0;
      }

      // console.log()
    }
  };

  const toggleNode = (e: any, node: TreeNode) => {
    node.isExpanded = !node.isExpanded;
    setTree([...tree]);
    const slashCount = (node.path.match(/\//g) || []).length || 1;
    setSelectedSubFolderID(node.id);
    setSelectedFolderID(folder?.id);
    let folderContainer: any = $("#folder-container");
    if (folderContainer) {
      folderContainer.animate({ scrollLeft: 50 * slashCount }, 500);
    }
  };

  const buildTree = (
    data: any,
    path: string = "",
    secured_path: string = ""
  ): TreeNode[] => {
    return Object.keys(data).map((key) => ({
      folder_id: folder.id,
      id: data[key].id,
      name: key,
      children: buildTree(
        data[key].children || {},
        path + "/" + key,
        secured_path + "/" + data[key].id
      ),
      path: path + "/" + key,
      secured_path: secured_path,
      isExpanded: false,
      isFolder: data[key]?.isFolder ? data[key]?.isFolder : false,
      isEdit: false,
      isCreate: data[key]?.isCreate,
      isRequireOCR: data[key]?.isRequireOCR ? data[key]?.isRequireOCR : false,
      isOCRProcessing: false,
      url: data[key]?.url || null,
    }));
  };

  const startOCR = async (e: any, node: TreeNode) => {
    e.preventDefault();

    node.isOCRProcessing = true;
    setTree([...tree]);
    await handleStartOCR(folder.id, node.secured_path, node.path, node.name);
    node.isOCRProcessing = false;
    setTree([...tree]);
  };

  const renderUploadFolderStructure = (structure: any, path: string = "") => {
    if (!structure) return null;
    return (
      <>
        <div
          className={`relative pl-[27px] xl:pl-9 flex-col sidebar-folder-items flex}`}
        >
          {Object.keys(structure).map((key) => {
            if (key === "files") {
              return structure.files.map((file: any, index: number) => (
                <div key={index}>
                  <div
                    style={{
                      width: `${
                        220 * (deviceType === "laptop" ? 0.75 : 1) +
                        extraWidth / 4
                      }px`,
                    }}
                    className="sidebar-folder-item px-[9px] xl:px-3 py-[7px] xl:py-[10px] rounded-xl bg-transparent text-[#52525B] flex gap-[3px] xl:gap-1 justify-between cursor-pointer opacity-50"
                  >
                    <span className="text-xs xl:text-base font-normal truncated-text">
                      {file.name}
                    </span>
                    <MdFileUpload className="w-[15px] xl:w-5 h-[15px] xl:h-5 flex-none" />
                  </div>
                </div>
              ));
            }

            return (
              <div key={`${path}/${key}`}>
                <div
                  style={{
                    width: `${
                      259 * (deviceType === "laptop" ? 0.75 : 1) +
                      extraWidth / 4
                    }px`,
                  }}
                  className="px-[9px] xl:px-3 py-[7px] xl:py-[10px] flex gap-[9px] xl:gap-3 rounded-[10px]"
                >
                  <button className="outline-none flex-none">
                    <HiOutlineFolderMinus className="text-lg xl:text-2xl text-[#3B82F6] flex-none" />
                  </button>
                  <p
                    style={{
                      minWidth: `${
                        130 * (deviceType === "laptop" ? 0.75 : 1) +
                        extraWidth / 4
                      }px`,
                    }}
                    className="text-xs xl:text-base font-medium text-[#3B82F6] folder-name text-break "
                  >
                    {key}
                  </p>
                </div>
                {renderUploadFolderStructure(structure[key], `${path}/${key}`)}
              </div>
            );
          })}
        </div>
      </>
    );
  };

  const renderTree = (nodes: TreeNode[], folderId?: string) => (
    <>
      {nodes.map((node, index) => (
        <div key={index}>
          {node.isFolder === true ? (
            <>
              <div
                key={index}
                style={{
                  width: `${
                    242 * (deviceType === "laptop" ? 0.75 : 1) + extraWidth / 4
                  }px`,
                }}
                className={`px-[9px] xl:px-3 py-[7px] xl:py-[10px] flex items-start gap-[9px] xl:gap-3 rounded-[10px] group sidebar-folder ${
                  selectedSubFolderID === node.id ? "bg-[#0000000d]" : ""
                }`}
                data-event-name="Research: Left Nav Folder Clicked"
              >
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    toggleNode(e, node);
                  }}
                  className="outline-none flex-none"
                >
                  {node.isExpanded ? (
                    <HiOutlineFolderMinus className="text-lg xl:text-2xl text-[#3B82F6] flex-none" />
                  ) : (
                    <HiOutlineFolderPlus className="text-lg xl:text-2xl text-[#3B82F6] flex-none" />
                  )}
                </button>
                <p
                  style={{
                    minWidth: `${
                      130 * (deviceType === "laptop" ? 0.75 : 1) +
                      extraWidth / 4
                    }px`,
                  }}
                  className="text-xs xl:text-base font-medium text-[#3B82F6] folder-name text-break"
                  onClick={(e) => {
                    toggleNode(e, node);
                  }}
                  data-event-name="Research: Left Nav Folder Clicked"
                >
                  {node.name}
                </p>
                <button
                  className={`outline-none flex-none ml-auto flex h-[18px] xl:h-6 items-center ${
                    isSelectedFolder(node.id)
                      ? "visible"
                      : "invisible group-hover:visible"
                  }`}
                  onClick={(e) => {
                    e.stopPropagation();
                    toggleSelectFolder(node.id, node.folder_id);
                  }}
                >
                  <BpCheckbox
                    size="small"
                    className="w-[10px] xl:w-[14px] h-[10px] xl:h-[14px]"
                    checked={isSelectedFolder(node.id)}
                  ></BpCheckbox>
                </button>
              </div>
              {node.isExpanded && (
                <div
                  className={`relative pl-[27px] xl:pl-9 flex-col sidebar-folder-items ${
                    node.isExpanded ? "flex" : "hidden"
                  }`}
                >
                  {node.children && node.children.length > 0 ? (
                    renderTree(node.children, node.id)
                  ) : (
                    <></>
                  )}
                </div>
              )}
            </>
          ) : (
            <>
              <div
                style={{
                  width: `${
                    242 * (deviceType === "laptop" ? 0.75 : 1) + extraWidth / 4
                  }px`,
                }}
                className={`sidebar-folder-item px-[9px] xl:px-3 py-[7px] xl:py-[10px] rounded-xl flex items-start gap-[3px] xl:gap-1 justify-between cursor-pointer group ${
                  openedDocument === node.secured_path + "/" + node.name
                    ? "bg-[#E4E4E7] text-[#18181B]"
                    : "bg-transparent text-[#52525B]"
                }`}
                data-event-name="Research: Left Nav File Clicked"
                onClick={(e) => {
                  if (!node.url) {
                    handleDocumentClick(
                      node.secured_path + "/" + node.name,
                      folder?.id
                    );
                  }
                }}
              >
                <p
                  className={`text-xs xl:text-base document-title ${
                    openedDocument === node.secured_path + "/" + node.name
                      ? "font-medium"
                      : "font-normal"
                  }`}
                  data-event-name="Research: Left Nav File Clicked"
                >
                  {node?.url ? (
                    <a
                      href={node?.url}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-blue-500 underline"
                      onClick={(e) => e.stopPropagation()}
                    >
                      {node.name}
                    </a>
                  ) : (
                    node.name
                  )}
                </p>
                <div className="flex gap-[6px] xl:gap-2 ml-auto">
                  {node?.isRequireOCR && (
                    <button
                      onClick={(e) => {
                        startOCR(e, node);
                      }}
                      className="outline-none flex-none"
                      title="Start OCR Processing"
                    >
                      {node?.isOCRProcessing ? (
                        <svg
                          aria-hidden="true"
                          className="w-3 xl:w-4 h-[18px] xl:h-6 mr-3 xl:mr-4 text-[#3B82F6] animate-spin"
                          viewBox="0 0 100 101"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                            fill="currentColor"
                          />
                          <path
                            d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                            fill="currentFill"
                          />
                        </svg>
                      ) : (
                        <MdOutlineRefresh className="text-lg xl:text-2xl text-[#3B82F6] flex-none" />
                      )}
                    </button>
                  )}
                  <button
                    className={`outline-none flex-none ml-auto flex h-[18px] xl:h-6 items-center ${
                      isSelectedFile(node.name, folderId as string)
                        ? "visible"
                        : "invisible group-hover:visible"
                    }`}
                    onClick={(e) => {
                      e.stopPropagation();
                      toggleSelectFile(
                        node.name,
                        folderId as string,
                        node.folder_id
                      );
                    }}
                  >
                    <BpCheckbox
                      size="small"
                      className="w-[10px] xl:w-[14px] h-[10px] xl:h-[14px]"
                      checked={isSelectedFile(node.name, folderId as string)}
                    ></BpCheckbox>
                  </button>
                </div>
              </div>
            </>
          )}
        </div>
      ))}
    </>
  );
  return <>{renderTree(tree)}</>;
};

export default Folder;
