// src/PublicRoute.tsx
import React from 'react';
import { Route, Redirect, RouteProps } from 'react-router-dom';
import PageLoading from '../Components/common/PageLoading';
import { useUserContext } from '../contexts/UserContext';
interface PublicRouteProps extends RouteProps {
    component: React.ComponentType<any>;
}

const PublicRoute: React.FC<PublicRouteProps> = ({ component: Component, ...rest }) => {
    const { isLoaded, isSignedIn } = useUserContext();

    if (!isLoaded) {
        return <PageLoading />;
    }

    return (
        <Route
            {...rest}
            render={props =>
                !isSignedIn ? <Component {...props} /> : <Redirect to="/" />
            }
        />
    );
};

export default PublicRoute;
