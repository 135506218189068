import React from 'react'
import Modal from '@mui/material/Modal'
import { IoCloseOutline } from 'react-icons/io5'
import axios from 'axios'
import { useRagContext } from '../../../contexts/RagContext'

interface DeleteFolderModalProps {
  open?: boolean
  handleClose?: () => void
  folder?: any
}

const DeleteFolderModal: React.FC<DeleteFolderModalProps> = ({
  open,
  handleClose,
  folder,
}) => {
  const { getConceptFolders, getBrainstormList } = useRagContext()
  const handleDelete = async () => {
    try {
      let response = await axios.post(
        process.env.REACT_APP_API_URL + 'api/deleteConceptFolder',
        {
          concept_folder_id: folder?.id,
        },
      )
      if (response?.status === 200) {
        getConceptFolders()
        getBrainstormList()
        if (handleClose) handleClose()
      }
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <Modal
      open={Boolean(open) && folder}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 w-[436px] bg-white rounded-[10px] shadow-lg">
        <div className="py-4 px-6 flex items-center justify-between border-b border-solid border-[#E4E4E7] gap-4">
          <div className="">
            <div className="text-lg leading-[22px] font-bold text-[#18181B] mb-1">
              Delete
            </div>
            <div className="text-sm leading-[22px] font-medium text-[#71717A]">
              Please confirm
            </div>
          </div>
          <button
            className="w-10 h-10 border border-solid border-[#D4D4D8] flex items-center justify-center flex-none rounded-full"
            onClick={() => {
              if (handleClose) handleClose()
            }}
          >
            <IoCloseOutline className="w-6 h-6 text-[#09090B]" />
          </button>
        </div>
        <div className="p-6">
          <div className="w-full text-xl font-normal text-[#18181B] mb-8">
            All selected folder and it's concepts will be permanently deleted.
            This action is irreversible.
          </div>
          <div className="flex gap-4">
            <button
              className="outline-none border border-solid border-[#71717A] h-12 flex items-center justify-center rounded-lg w-full"
              onClick={() => {
                if (handleClose) handleClose()
              }}
            >
              <span className="text-base font-bold text-[#71717A]">Cancel</span>
            </button>
            <button
              className="outline-none bg-[#FA302F] h-12 flex items-center justify-center rounded-lg w-full"
              onClick={() => handleDelete()}
            >
              <span className="text-base font-bold text-white">Delete</span>
            </button>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default DeleteFolderModal
