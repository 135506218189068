import React from 'react'

const DiamondValueIcon: React.FC<any> = (props: any) => {
  return (
    <svg
      viewBox="0 0 24 20"
      xmlns="http://www.w3.org/2000/svg"
      fill="#3B82F6"
      {...props}
    >
      <path
        d="M23.7782 5.80121L20.4689 0.639011C20.2125 0.238909 19.7776 0 19.3057 0H4.70469C4.23351 0 3.79904 0.238309 3.54245 0.637391L0.222861 5.80025C-0.116369 6.32776 -0.0639697 7.02426 0.350296 7.49392L10.9646 19.5312C11.2273 19.8291 11.6044 20 11.9994 20C11.9993 20 11.9995 20 11.9994 20C12.3943 20 12.7716 19.8291 13.0343 19.5312L23.6498 7.49325C24.0635 7.02414 24.1163 6.32836 23.7782 5.80121ZM18.6353 1.20019L15.8522 5.54748L13.0674 1.20019H18.6353ZM14.7603 6.0523H9.23849L11.9994 1.74228L14.7603 6.0523ZM10.9314 1.20031L8.15426 5.53566L5.3781 1.20031H10.9314ZM4.27955 1.69451L7.07003 6.0523H1.4776L4.27955 1.69451ZM1.72855 7.25255H7.70786L10.8694 17.6188L1.72855 7.25255ZM11.9994 17.2472L8.95113 7.25255H15.0477L11.9994 17.2472ZM13.1293 17.6191L16.291 7.25261H22.2708L13.1293 17.6191ZM16.9441 6.0523L19.732 1.69751L22.5236 6.0523H16.9441Z"
        fill="inherit"
      />
    </svg>
  )
}

export default DiamondValueIcon
