import React, { useEffect, useMemo, useState } from 'react'
import Modal from '@mui/material/Modal'
import { IoCloseOutline } from 'react-icons/io5'
import { useRagContext } from '../../../../contexts/RagContext'
import axios from 'axios'
import { conceptItemList } from '../ConceptModal'

interface EditConceptBusinessCanvasModalProps {
  open?: boolean
  handleClose?: () => void
  onEdit?: () => void
  concept: any
}

const businessModelCanvasTitle: any = {
  Key_Partnerships: 'Key Partner',
  Key_Activities: 'Key Activity',
  Key_Resources: 'Key Resources',
  Value_Propositions: 'Value Propositions',
  Customer_Relationships: 'Customer Relationships',
  Channels: 'Channels',
  Customer_Segments: 'Customer Segments',
  Cost_Structure: 'Cost Structure',
  Revenue_Streams: 'Revenue Streams',
}

const EditConceptBusinessCanvasModal: React.FC<EditConceptBusinessCanvasModalProps> = ({
  open,
  handleClose,
  onEdit,
  concept,
}) => {
  const { getConceptFolders } = useRagContext()
  const [value, setValue] = useState<any>({
    Channels: '',
    Cost_Structure: '',
    Customer_Relationships: '',
    Customer_Segments: '',
    Key_Activities: '',
    Key_Partnerships: '',
    Key_Resources: '',
    Revenue_Streams: '',
    Value_Propositions: '',
  })
  const [selectedKey, setSelectedKey] = useState('Key_Partnerships')

  useEffect(() => {
    if (concept && open) {
      setValue(concept['BusinessModelCanvasJSON'])
    } else {
      setValue({
        Channels: '',
        Cost_Structure: '',
        Customer_Relationships: '',
        Customer_Segments: '',
        Key_Activities: '',
        Key_Partnerships: '',
        Key_Resources: '',
        Revenue_Streams: '',
        Value_Propositions: '',
      })
    }
  }, [concept, open])

  const handleSave = async () => {
    let params: any = {
      id: concept?.id,
      BusinessModelCanvasJSON: value,
    }
    try {
      let response = await axios.post(
        process.env.REACT_APP_API_URL + 'api/editConcept',
        params,
      )
      if (response?.status === 200) {
        getConceptFolders()
        if (handleClose) handleClose()
        if (onEdit) onEdit()
      }
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <Modal
      open={Boolean(open)}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 w-[974px] bg-white rounded-[10px] shadow-lg">
        <div className="py-4 px-6 flex items-center justify-between border-b border-solid border-[#E4E4E7] gap-4">
          <div className="">
            <div className="text-lg leading-[22px] font-bold text-[#18181b] mb-1">
              Business Model Canvas
            </div>
          </div>
          <button
            className="w-8 h-8 border border-solid border-[#D4D4D8] flex items-center justify-center flex-none rounded-full"
            onClick={() => {
              if (handleClose) handleClose()
            }}
          >
            <IoCloseOutline className="w-5 h-5 text-[#09090B]" />
          </button>
        </div>
        <div className="p-6">
          <div className="h-[390px] flex gap-4 mb-8">
            <div className="p-1 w-[226px]">
              {Object.keys(businessModelCanvasTitle).map(
                (key: string, index: number) => (
                  <div
                    className={`h-10 flex py-2 px-4 rounded-lg cursor-pointer ${
                      key === selectedKey ? 'bg-[#3B82F6]' : 'bg-transparent'
                    }`}
                    key={index}
                    onClick={() => setSelectedKey(key)}
                  >
                    <span
                      className={`text-sm font-medium ${
                        key === selectedKey ? 'text-[white]' : 'text-[#A1A1AA]'
                      }`}
                    >
                      {businessModelCanvasTitle[key]}
                    </span>
                  </div>
                ),
              )}
            </div>
            <div className="flex-1">
              <textarea
                id="edit-concept-value"
                name="edit-concept-value"
                className="w-full border border-solid border-[#D4D4D8] outline-none px-4 py-3 rounded-2xl text-sm font-medium text-[#3F3F46] h-full resize-none"
                value={value[selectedKey]}
                onChange={(e) =>
                  setValue((prev: any) => {
                    return {
                      ...prev,
                      [selectedKey]: e.target.value,
                    }
                  })
                }
              />
            </div>
          </div>
          <div className="flex gap-4">
            <button
              className="outline-none border border-solid border-[#71717A] h-12 flex items-center justify-center rounded-lg w-full"
              onClick={() => {
                if (handleClose) handleClose()
              }}
            >
              <span className="text-base font-bold text-[#71717A]">Cancel</span>
            </button>
            <button
              className="outline-none bg-[#3B82F6] h-12 flex items-center justify-center rounded-lg w-full"
              onClick={() => handleSave()}
            >
              <span className="text-base font-bold text-white">Save</span>
            </button>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default EditConceptBusinessCanvasModal
