import React from "react";
import { Link } from "react-router-dom";
import HomeIcon from "../Components/icons/HomeIcon";
import ResearchIcon from "../Components/icons/ResearchIcon";
import BagIcon from "../Components/icons/BagIcon";
import TelegramIcon from "../Components/icons/TelegramIcon";

interface SidebarProps {
  page: string;
}

type SidebarItemType = {
  page: string;
  path: string;
  title: string;
  Icon: React.FC<any>;
};

const Sidebar: React.FC<SidebarProps> = ({ page }) => {
  const sidebarItems: SidebarItemType[] = [
    {
      page: "home",
      path: "/",
      title: "Home",
      Icon: HomeIcon,
    },
    {
      page: "research",
      path: "/research",
      title: "Research",
      Icon: ResearchIcon,
    },
    {
      page: "concept",
      path: "/concept",
      title: "Concept",
      Icon: BagIcon,
    },
  ];

  const SidebarItem = (item: SidebarItemType) => {
    return (
      <Link
        to={item.path}
        onClick={(e) => {
          if (item.page === page) {
            e.preventDefault();
          }
        }}
      >
        <div
          className={`w-12 h-12 flex items-center justify-center rounded-xl cursor-pointer ${
            item.page === page ? "border border-[#E4E4E7] shadow-md" : ""
          }`}
          title={item.title}
        >
          <item.Icon className="flex-none w-6 h-6"></item.Icon>
        </div>
        <div className="text-xs font-medium text-[#09090B] text-center w-full mt-1">
          {item.title}
        </div>
      </Link>
    );
  };

  return (
    <div className="w-20 flex-none border-r border-solid border-[#D4D4D8] bg-[#FCFCFC]">
      <div className="flex flex-col items-center gap-[10px] py-4">
        {sidebarItems.map((item, index) => (
          <SidebarItem key={index} {...item} />
        ))}
      </div>
    </div>
  );
};

export default Sidebar;
