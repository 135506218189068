import React from 'react'
import Skeleton from '@mui/material/Skeleton'

const Loading: React.FC = () => {
  return (
    <div className="flex gap-2 w-full flex-col">
      <Skeleton height={10} sx={{ width: '67%', transform: 'scale(1)' }} />
      <Skeleton height={10} sx={{ width: '46%', transform: 'scale(1)' }} />
      <Skeleton height={10} sx={{ width: '37%', transform: 'scale(1)' }} />
      <Skeleton height={10} sx={{ width: '46%', transform: 'scale(1)' }} />
      <Skeleton height={10} sx={{ width: '56%', transform: 'scale(1)' }} />
      <Skeleton height={10} sx={{ width: '52%', transform: 'scale(1)' }} />
      <Skeleton height={10} sx={{ width: '43%', transform: 'scale(1)' }} />
      <Skeleton height={10} sx={{ width: '75%', transform: 'scale(1)' }} />
      <Skeleton height={10} sx={{ width: '40%', transform: 'scale(1)' }} />
      <Skeleton height={10} sx={{ width: '32%', transform: 'scale(1)' }} />
      <Skeleton height={10} sx={{ width: '16%', transform: 'scale(1)' }} />
    </div>
  )
}
export default Loading
