import React from "react";
import "react-toastify/dist/ReactToastify.min.css";
import { RouteComponentProps } from "react-router";
import logoImage from "../../assets/auth/logo.svg";
import bgImage from "../../assets/auth/login-bg-image.png";
import circleImage from "../../assets/auth/login-circle-bg.svg";
import { SignIn } from "@clerk/clerk-react";

type SomeComponentProps = RouteComponentProps;
const Login: React.FC<SomeComponentProps> = ({ history }): JSX.Element => {
  return (
    <div className="w-screen h-screen font-Satoshi">
      <div className="flex h-full">
        <div className="h-full flex flex-col justify-between py-[72px] px-24 bg-white w-[700px]">
          <div className="flex items-center gap-2">
            <img
              src={logoImage}
              alt="Logo"
              className="w-12 h-12 rounded-full flex-none"
            />
            <div className="">
              <div className="text-[22px] leading-none tracking-[-3%] text-[#27272A] font-Host-Grotesk font-medium">
                Growth
                <br />
                Signals
              </div>
            </div>
          </div>

          <form autoComplete="off">
            <div className="flex flex-col gap-8">
              <div>
                <div className="text-heading-4 leading-heading-4 text-[#232323] font-bold mb-2">
                  Welcome back 👋🏻
                </div>
                <div className="text-xl text-[#969696] font-normal">
                  Please login to continue to your account.
                </div>
              </div>
              <SignIn
                signUpUrl="/register"
                appearance={{
                  elements: {
                    formButtonPrimary: "my-custom-class",
                    formFieldLabel: "label-custom-class",
                    formFieldInput: "input-custom-class",
                    socialButtonsBlockButton: "social-custom-class",
                    socialButtonsBlockButton__google: {
                      display: "none",
                    },
                    dividerRow: {
                      display: "none",
                    },
                    footerAction: {
                      display: "none",
                    },
                  },
                }}
              />
              {/* <div className="flex flex-col gap-5">
                  <div className="w-full">
                    <label
                      htmlFor="email"
                      className="text-base text-[#27272A] mb-1 ml-1"
                    >
                      Email Address
                    </label>
                    <input
                      type="email"
                      id="email"
                      className="w-full h-12 border border-[#A1A1AA] rounded-lg px-4 py-3 text-sm leading-6 text-[#71717A] outline-none"
                      placeholder="Enter your email address"
                      {...register('email', { required: 'Email is required!' })}
                    />
                    {errors.email && (
                      <div className="text-red-600 text-xs leading-5 no-underline mt-1">
                        {errors.email.message}
                      </div>
                    )}
                  </div>
                  <div className="w-full">
                    <label
                      htmlFor="password"
                      className="text-base text-[#27272A] mb-1 ml-1"
                    >
                      Password
                    </label>
                    <input
                      type="password"
                      id="password"
                      className="w-full h-12 border border-[#A1A1AA] rounded-lg px-4 py-3 text-sm leading-6 text-[#71717A] outline-none"
                      placeholder="Enter your password"
                      {...register('password', {
                        required: 'Password is required!',
                      })}
                    />
                    {errors.password && (
                      <div className="text-red-600 text-xs leading-5 no-underline mt-1">
                        {errors.password.message}
                      </div>
                    )}
                    <Link
                      to={'/forgot-password'}
                      className="text-xs leading-5 text-[#3B82F6] no-underline mt-1"
                    >
                      Forgot Password?
                    </Link>
                  </div>
                  <button className="w-full h-12 bg-[#3B82F6] rounded-lg py-3 px-6 flex justify-center items-center">
                    <span className="text-base font-bold text-white">
                      Log in
                    </span>
                  </button>
                </div> */}
              {/* <div className="text-lg leading-[26px] font-medium text-center">
                  Don’t have an account?{' '}
                  <Link to={'/register'} className="text-[#3B82F6]">
                    Create New Account
                  </Link>
                  .
                </div> */}
            </div>
          </form>
          <div className="h-12"></div>
        </div>
        <div className="flex-1 h-full relative bg-[#DBEAFE] overflow-hidden">
          <div className="mt-[140px] w-full pl-[72px] pr-[100px] relative z-30">
            {/* <div className="text-[32px] leading-10 text-[#232323] font-bold mb-[18px] tracking-[-1px]">
                Lorem ipsum dolor sit amet consectetur. Nulla varius risus augue
                erat morbi.
              </div> */}
            {/* <div className="flex items-center gap-1">
                <img
                  src={avatarImage}
                  alt="Avatar"
                  className="w-10 h-10 rounded-full flex-none"
                />
                <div className="">
                  <div className="text-sm leading-[22px] font-bold text-black">
                    James Bator
                  </div>
                  <div className="text-[10px] leading-[14px] text-[#838c98] font-normal">
                    CEO of growth signals
                  </div>
                </div>
              </div> */}
          </div>
          <img
            src={bgImage}
            alt="background"
            className="absolute bottom-0 right-0 h-[64.45%] z-20"
          />
          <img
            src={circleImage}
            alt="circle"
            className="absolute bottom-0 left-0 h-1/2 z-10"
          />
        </div>
      </div>
    </div>
  );
};
export default Login;
