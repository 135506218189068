import React, { useEffect } from 'react'
import Modal from '@mui/material/Modal'
import { MdClose } from 'react-icons/md'
import { parseJWT } from '../utils'
import Configuration from './SettingsModal/Configurations'
import TeamManagement from './SettingsModal/TeamManagement'
import UpdatePassword from './SettingsModal/UpdatePassword'
import { useUserContext } from '../contexts/UserContext'
import { useRagContext } from '../contexts/RagContext'
interface SettingsModalComponentProps {
  open: boolean
  handleClose: () => void
}

interface TabPanelProps {
  tab: number
  index: number
  children?: React.ReactNode
}

const CustomTabPanel: React.FC<TabPanelProps> = (props: TabPanelProps) => {
  const { children, tab, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={tab !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      className="flex-1"
      {...other}
    >
      {tab === index && children}
    </div>
  )
}

const SettingsModal: React.FC<SettingsModalComponentProps> = ({
  open,
  handleClose,
}) => {
  const { user, isLoaded, isSignedIn } = useUserContext()
  const { setting } = useRagContext()
  const [openTab, setOpenTab] = React.useState(0)

  useEffect(() => {
    if (user?.organizationMemberships[0]?.role) {
      setOpenTab(user?.organizationMemberships[0]?.role === 'org:admin' ? 0 : 2)
    }
  }, [user?.organizationMemberships[0]?.role])
  return (
    <Modal
      open={open}
      // onClose={() => handleClose()}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <div className="flex items-center justify-center w-screen h-screen">
        <div className="w-[800px] rounded-[10px] bg-white">
          <div className="p-6 border-b border-[#E4E4E7]">
            <div className="flex items-center justify-between mb-6">
              <div className="text-2xl font-bold text-[#18181B]">Settings</div>
              <button className="outline-none" onClick={() => handleClose()}>
                <MdClose className="w-6 h-6 text-[#09090B]" />
              </button>
            </div>
            <div className="w-full flex p-1 bg-[#0000000d] rounded-lg">
              {user?.organizationMemberships[0]?.role === 'org:admin' ? (
                <button
                  className={`w-1/3 h-10 flex items-center justify-center rounded-lg cursor-pointer ${
                    openTab === 0 ? 'bg-[#3B82F6]' : 'bg-transparent'
                  }`}
                  onClick={() => setOpenTab(0)}
                >
                  <span
                    className={`text-sm leading-[22px] font-medium ${
                      openTab === 0 ? 'text-white' : 'text-[#A1A1AA]'
                    }`}
                  >
                    Configurations
                  </span>
                </button>
              ) : (
                <></>
              )}
              {user?.organizationMemberships[0]?.role === 'org:admin' ? (
                <button
                  className={`w-1/3 h-10 flex items-center justify-center rounded-lg cursor-pointer disabled:cursor-none ${
                    openTab === 1 ? 'bg-[#3B82F6]' : 'bg-transparent'
                  }`}
                  onClick={() => setOpenTab(1)}
                  disabled={!setting?.verified}
                >
                  <span
                    className={`text-sm leading-[22px] font-medium ${
                      openTab === 1 ? 'text-white' : 'text-[#A1A1AA]'
                    }`}
                  >
                    Team management
                  </span>
                </button>
              ) : (
                <></>
              )}
              <button
                className={` ${
                  user?.organizationMemberships[0]?.role === 'org:admin'
                    ? 'w-1/3'
                    : 'w-[50%]'
                } h-10 flex items-center justify-center rounded-lg cursor-pointer disabled:cursor-none ${
                  openTab === 2 ? 'bg-[#3B82F6]' : 'bg-transparent'
                }`}
                onClick={() => setOpenTab(2)}
                disabled={!setting?.verified}
              >
                <span
                  className={`text-sm leading-[22px] font-medium ${
                    openTab === 2 ? 'text-white' : 'text-[#A1A1AA]'
                  }`}
                >
                  Update password
                </span>
              </button>
            </div>
          </div>
          {}
          <CustomTabPanel tab={openTab} index={0}>
            <Configuration handleClose={handleClose} />
          </CustomTabPanel>
          <CustomTabPanel tab={openTab} index={1}>
            <TeamManagement />
          </CustomTabPanel>
          <CustomTabPanel tab={openTab} index={2}>
            <UpdatePassword />
          </CustomTabPanel>
        </div>
      </div>
    </Modal>
  )
}

export default SettingsModal
