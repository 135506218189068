import React from "react";
import { useRagContext } from "../../../contexts/RagContext";
import SourceMaterialFolder from "./SourceMaterialFolder";
import CommonMarkdown from "../../common/CommonMarkdown";

interface SourceMaterialContentProps {
  conceptData: any;
  visible?: boolean;
}

const SourceMaterialContent: React.FC<SourceMaterialContentProps> = ({
  conceptData,
  visible,
}) => {
  const { folderList } = useRagContext();
  if (!visible) return null;
  return (
    <div className="w-full p-5">
      {conceptData.file_list?.length > 0 && (
        <div className="w-full overflow-x-auto overflow-y-auto mb-4">
          {folderList.map((folder, index) => {
            return folder.isFolderDeleted === 1 ? (
              <div key={index}></div>
            ) : (
              <SourceMaterialFolder
                key={index}
                folder={folder}
                fileList={conceptData.file_list}
              ></SourceMaterialFolder>
            );
          })}
        </div>
      )}
      {conceptData.context && conceptData.context !== "" && (
        <div className="text-xl font-medium text-[#71717A]">
          <CommonMarkdown>
            {conceptData.context}
          </CommonMarkdown>
        </div>
      )}
    </div>
  );
};

export default SourceMaterialContent;
