import React, { useEffect, useState } from "react";
import { RouteComponentProps } from "react-router-dom";
import { CssBaseline } from "@mui/material";
import Sidebar from "../../Components/Research/Sidebar";
import AIControl from "../../Components/Research/AIControl";
import FileManagerModal from "../../Components/Research/FileManagerModal/FileManagerModal";
import ConceptSelectFolderModal from "../../Components/Concept/ConceptModal/SelectFolderModal/SelectFolderModal";
import ActivityLogsModal from "../../Components/Research/ActivityLogsModal/ActivityLogsModal";
import { useLayoutContext } from "../../contexts/LayoutContext";
import ExpandIcon from "../../Components/icons/ExpandIcon";

import { Editor } from "../../Components/Research/Editor";
import { BreadCrumbMenu } from "../../Components/common/BreadCrumb";
import { useRagContext } from "../../contexts/RagContext";

const Research: React.FC<RouteComponentProps> = ({ history }) => {
  const {
    isChatDragging,
    chatWidth,
    chatDragBarProps,
    deviceType,
    setChatPosition,
    setBreadCrumbs,
  } = useLayoutContext();

  const { closeFileManagerModal } = useRagContext();

  const ResizeSplitter = ({
    id = "drag-bar",
    dir,
    isDragging,
    ...props
  }: any) => {
    const [isFocused, setIsFocused] = useState(false);

    return (
      <button
        id={id}
        data-testid={id}
        tabIndex={0}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
        className={`sample-drag-bar ${
          dir === "horizontal" && "sample-drag-bar--horizontal"
        } ${(isDragging || isFocused) && "sample-drag-bar--dragging"}`}
        {...props}
      />
    );
  };

  const isEditorMinimized = () => {
    return false;
  };

  useEffect(() => {
    const menus: BreadCrumbMenu[] = [
      {
        title: "Research",
        active: true,
        onClick: () => {
          history.push({ pathname: "/research" });
        },
      },
    ];
    setBreadCrumbs(menus);
    closeFileManagerModal();
  }, []);

  return (
    <>
      <div className="font-Satoshi flex h-full overflow-x-hidden">
        <CssBaseline />
        <div className="flex flex-column h-full overflow-hidden w-full">
          <div className="flex grow overflow-hidden">
            <div className="shrink-0 contents w-[243px] xl:w-[324px] border-r border-solid border-[#D4D4D8]">
              <Sidebar />
            </div>
            <div className="flex flex-1 flex-col h-full">
              <div className="flex-1 flex overflow-hidden h-full">
                <div
                  className="contents overflow-hidden relative"
                  style={{
                    width: `calc(100vw - ${
                      chatWidth + 396 * (deviceType === "laptop" ? 0.75 : 1)
                    }px)`,
                  }}
                >
                  <button
                    className="absolute top-[6px] xl:top-2 right-[6px] xl:right-2 z-20 bg-white shadow-[0px_1px_3px_2px_#80808080] rounded-md p-1"
                    onClick={() => {
                      if (isEditorMinimized()) {
                        setChatPosition(
                          424 * (deviceType === "laptop" ? 0.75 : 1)
                        );
                      } else {
                        setChatPosition(10000);
                      }
                    }}
                  >
                    <ExpandIcon
                      className={`w-[13px] xl:w-[18px] h-[13px] xl:h-[18px] ${
                        isEditorMinimized() ? "rotate-180" : ""
                      }`}
                    />
                  </button>
                  <div
                    className="flex-1 h-full text-center bg-white rounded-xl relative w-full"
                    id="editor-panel"
                  >
                    <div>
                      <Editor />
                    </div>
                  </div>
                </div>
                <ResizeSplitter
                  isDragging={isChatDragging}
                  {...chatDragBarProps}
                />
                <div
                  className={`shrink-0 contents ${
                    isChatDragging && "dragging"
                  }`}
                  style={{
                    width: chatWidth,
                    maxWidth: `calc(100vw - ${
                      396 * (deviceType === "laptop" ? 0.75 : 1)
                    }px)`,
                  }}
                >
                  <AIControl />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <FileManagerModal />
      <ActivityLogsModal />
      <ConceptSelectFolderModal />
    </>
  );
};

export default Research;
