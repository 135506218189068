import React from 'react'
import Modal from '@mui/material/Modal'
import { IoCloseOutline } from 'react-icons/io5'
import axios from 'axios'
import { useRagContext } from '../../../../contexts/RagContext'
import { useUserContext } from '../../../../contexts/UserContext'

interface DeleteModalProps {
  open: boolean
  handleClose?: () => void
  folder?: any
  file?: any
  isFolder?: any
  parentFolders: any[]
}

const DeleteModal: React.FC<DeleteModalProps> = ({
  open,
  handleClose,
  folder,
  file,
  isFolder,
  parentFolders,
}) => {
  const { fetchFolders } = useRagContext()
  const { user } = useUserContext()
  const handleDelete = async () => {
    if (isFolder) {
      let params: any = {
        path:
          parentFolders.length > 0
            ? `/${parentFolders.map((e: any) => e.folder_name).join('/')}/${
                folder.folder_name
              }`
            : `/${folder.folder_name}`,
        secured_path:
          parentFolders.length > 0
            ? `/${parentFolders
                .map((e: any, index: number) =>
                  index === 0 ? e.folder_id : e.id,
                )
                .join('/')}/${folder.id}`
            : `/${folder.id}`,
        sub_folder_id: folder.id,
        team_id: user?.organizationMemberships[0]?.organization.id,
      }
      if (parentFolders.length > 0) {
        params.id = parentFolders[0].id
      } else {
        params.id = folder.namespace_id
      }

      try {
        let response = await axios.post(
          process.env.REACT_APP_API_URL + 'api/deleteFolder',
          params,
        )
        if (response?.status === 200) {
          fetchFolders()
          if (handleClose) handleClose()
        }
      } catch (error) {
        console.log(error)
      }
    } else {
      let params: any = {
        file_name: file,
        target_path: `/${parentFolders
          .map((e: any) => e.folder_name)
          .join('/')}/${file}`,
        secured_path: `/${parentFolders
          .map((e: any, index: number) => (index === 0 ? e.folder_id : e.id))
          .join('/')}`,
        team_id: user?.organizationMemberships[0]?.organization.id,
      }
      if (parentFolders.length > 0) {
        params.folder_id = parentFolders[0].id
      } else {
        params.folder_id = folder.namespace_id
      }

      try {
        let response = await axios.post(
          process.env.REACT_APP_API_URL + 'api/deleteDocument',
          params,
        )
        if (response?.status === 200) {
          fetchFolders()
          if (handleClose) handleClose()
        }
      } catch (error) {
        console.log(error)
      }
    }
  }

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 w-[436px] bg-white rounded-[10px] shadow-lg">
        <div className="py-4 px-6 flex items-center justify-between border-b border-solid border-[#E4E4E7] gap-4">
          <div className="">
            <div className="text-lg leading-[22px] font-bold text-[#18181B] mb-1">
              Delete
            </div>
            <div className="text-sm leading-[22px] font-medium text-[#71717A]">
              Please confirm
            </div>
          </div>
          <button
            className="w-10 h-10 border border-solid border-[#D4D4D8] flex items-center justify-center flex-none rounded-full"
            onClick={() => {
              if (handleClose) handleClose()
            }}
          >
            <IoCloseOutline className="w-6 h-6 text-[#09090B]" />
          </button>
        </div>
        <div className="p-6">
          <div className="w-full text-xl font-normal text-[#18181B] mb-8">
            This will be permanently deleted. This action is irreversible.
          </div>
          <div className="flex gap-4">
            <button
              className="outline-none border border-solid border-[#71717A] h-12 flex items-center justify-center rounded-lg w-full"
              onClick={() => {
                if (handleClose) handleClose()
              }}
            >
              <span className="text-base font-bold text-[#71717A]">Cancel</span>
            </button>
            <button
              className="outline-none bg-[#FA302F] h-12 flex items-center justify-center rounded-lg w-full"
              onClick={() => handleDelete()}
            >
              <span className="text-base font-bold text-white">Delete</span>
            </button>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default DeleteModal
