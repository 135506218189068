import React from 'react'

const ArrowFolderIcon: React.FC<any> = (props: any) => {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      stroke="white"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.169 15.482C21.169 19.06 19.06 21.169 15.482 21.169H7.7C4.113 21.169 2 19.06 2 15.482V7.682C2 4.109 3.314 2 6.893 2H8.893C9.611 2.001 10.287 2.338 10.717 2.913L11.63 4.127C12.062 4.701 12.738 5.039 13.456 5.04H16.286C19.873 5.04 21.197 6.866 21.197 10.517L21.169 15.482Z"
        stroke="inherit"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.9981 12.6136H7"
        stroke="inherit"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.3672 9.00013L15.9964 12.6138L12.3672 16.228"
        stroke="inherit"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default ArrowFolderIcon
