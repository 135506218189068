import React from "react";

const ResearchIcon: React.FC<any> = (props: any) => {
  return (
    <svg
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      fill="#09090B"
      {...props}
    >
      <mask
        id="mask0_2427_15461"
        style={{ maskType: "luminance" }}
        maskUnits="userSpaceOnUse"
        x={2}
        y={2}
        width={20}
        height={20}
      >
        <path d="M21.5 2.5H2.5V21.5037H21.5V2.5Z" fill="white" />
      </mask>
      <g mask="url(#mask0_2427_15461)">
        <path
          d="M21.4998 12.961C21.4753 13.0175 21.4526 13.0748 21.4255 13.1302C21.355 13.2798 21.2366 13.4016 21.0889 13.4759C20.9413 13.5503 20.773 13.573 20.6108 13.5405C20.4487 13.508 20.3022 13.422 20.1946 13.2964C20.0871 13.1709 20.0247 13.0128 20.0175 12.8477C20.0083 12.545 20.0146 12.2417 20.0146 11.9387V5.61114C20.0146 4.59176 19.4064 3.97983 18.3929 3.97983H5.5933C4.60142 3.97983 3.98577 4.59584 3.98577 5.5881V18.4101C3.98577 19.3911 4.60402 20.0123 5.58066 20.0123C8.07251 20.0123 10.5646 20.0123 13.057 20.0123C13.6414 20.0123 14.0105 20.5328 13.7956 21.0448C13.6935 21.2876 13.4934 21.409 13.2622 21.4965H5.13602C5.10299 21.4824 5.06877 21.4712 5.03379 21.463C4.33264 21.3599 3.69172 21.0087 3.22744 20.4732C2.76316 19.9379 2.50631 19.2537 2.50353 18.545C2.49882 14.1794 2.49882 9.81391 2.50353 5.44831C2.50422 4.66565 2.81544 3.91523 3.36887 3.3618C3.92229 2.80838 4.67271 2.49716 5.45538 2.49646C9.81991 2.49076 14.1845 2.49076 18.5491 2.49646C19.8916 2.49646 21.0493 3.40284 21.3972 4.70142C21.4344 4.84344 21.4656 4.98695 21.4998 5.12971V12.961Z"
          fill="inherit"
        />
        <path
          d="M20.6104 21.4899C20.4357 21.3685 20.2435 21.267 20.0899 21.1216C19.742 20.797 19.4107 20.455 19.0783 20.1141C19.004 20.0397 18.95 20.0326 18.8552 20.081C16.8035 21.1398 14.4312 20.0015 13.9851 17.7459C13.6222 15.9168 14.9692 14.0598 16.8169 13.8423C18.6304 13.6279 20.2025 14.7632 20.5532 16.5469C20.6967 17.2756 20.5925 18.1456 20.0367 19.0278C20.1691 19.1438 20.3163 19.2635 20.4528 19.3947C20.684 19.6178 20.9186 19.8409 21.132 20.0788C21.2729 20.2367 21.3789 20.4256 21.5008 20.5992V20.8967C21.46 21.038 21.3841 21.1667 21.2801 21.2708C21.176 21.3748 21.0473 21.4507 20.9059 21.4915L20.6104 21.4899ZM17.2674 19.0028C17.6345 19.0036 17.9936 18.8955 18.2993 18.6921C18.6049 18.4887 18.8433 18.1993 18.9843 17.8603C19.1254 17.5214 19.1626 17.1482 19.0915 16.788C19.0203 16.4279 18.8438 16.097 18.5845 15.8371C18.3251 15.5774 17.9945 15.4003 17.6345 15.3285C17.2744 15.2566 16.9012 15.2933 16.562 15.4336C16.2228 15.574 15.9329 15.812 15.7289 16.1172C15.525 16.4224 15.4162 16.7814 15.4163 17.1484C15.4181 17.6392 15.6136 18.1093 15.9603 18.4566C16.307 18.8039 16.7767 19.0002 17.2674 19.0028Z"
          fill="inherit"
        />
        <path
          d="M13.8583 8.16737C12.8017 8.16737 11.7449 8.17034 10.6882 8.16737C10.1488 8.16514 9.79077 7.66549 9.96736 7.17363C10.0741 6.87622 10.3477 6.68662 10.6841 6.68475C11.2158 6.68215 11.7471 6.68475 12.2787 6.68475C13.8542 6.68475 15.4301 6.68475 17.0061 6.68475C17.5533 6.68475 17.9147 7.16322 17.7496 7.66065C17.6466 7.97293 17.3674 8.16737 17.0091 8.16812C15.9602 8.16885 14.9099 8.16861 13.8583 8.16737Z"
          fill="inherit"
        />
        <path
          d="M13.8575 12.6201C12.8006 12.6201 11.7439 12.6227 10.6874 12.6201C10.1484 12.6201 9.7904 12.1171 9.96766 11.6256C10.0744 11.3282 10.3483 11.1393 10.6852 11.1379C11.26 11.1353 11.8344 11.1379 12.409 11.1379C13.942 11.1379 15.4745 11.1379 17.0067 11.1379C17.5536 11.1379 17.915 11.6174 17.7503 12.1145C17.6466 12.4264 17.3674 12.6204 17.0089 12.6209C15.9586 12.6213 14.9081 12.6211 13.8575 12.6201Z"
          fill="inherit"
        />
        <path
          d="M11.1786 17.3459C10.9997 17.3459 10.8206 17.3514 10.6418 17.3459C10.4482 17.3405 10.2644 17.2595 10.1299 17.1202C9.99532 16.9809 9.92066 16.7944 9.92189 16.6008C9.92323 16.4071 10.0002 16.2217 10.1366 16.0841C10.2731 15.9467 10.4578 15.868 10.6515 15.8651C11.0095 15.858 11.3675 15.8569 11.7254 15.8651C11.9169 15.8713 12.0984 15.9515 12.232 16.0887C12.3655 16.226 12.4408 16.4095 12.4418 16.6011C12.443 16.7925 12.3699 16.977 12.2379 17.1157C12.1059 17.2544 11.9254 17.3367 11.734 17.3452C11.5504 17.3522 11.3645 17.3459 11.1786 17.3459Z"
          fill="inherit"
        />
        <path
          d="M7.31619 6.68574C7.45188 6.68574 7.58796 6.68091 7.72514 6.68574C7.91652 6.69345 8.09748 6.77497 8.23006 6.91321C8.36263 7.05144 8.43653 7.23566 8.43624 7.42719C8.43595 7.61873 8.3615 7.8027 8.22851 7.94054C8.09551 8.07838 7.91431 8.15936 7.72291 8.16649C7.45784 8.17505 7.19202 8.17505 6.92695 8.16649C6.7347 8.16012 6.55248 8.07919 6.41884 7.94083C6.28521 7.80249 6.21065 7.61758 6.21094 7.42522C6.21123 7.23287 6.28635 7.04818 6.42041 6.91023C6.55446 6.77229 6.73693 6.69191 6.92918 6.68611C7.05708 6.68128 7.18681 6.68574 7.31619 6.68574Z"
          fill="inherit"
        />
        <path
          d="M7.3177 11.1389C7.4534 11.1389 7.58947 11.1344 7.72665 11.1389C7.92311 11.1395 8.11131 11.218 8.24984 11.3573C8.38837 11.4967 8.46589 11.6852 8.46536 11.8817C8.46481 12.0782 8.38625 12.2664 8.24695 12.4049C8.10765 12.5434 7.91902 12.6209 7.72256 12.6205C7.45749 12.6289 7.19167 12.6293 6.9266 12.6205C6.73436 12.614 6.55217 12.5329 6.4186 12.3945C6.28503 12.2561 6.21056 12.0711 6.21094 11.8788C6.21133 11.6864 6.28655 11.5018 6.42068 11.3639C6.55479 11.226 6.73731 11.1457 6.92957 11.14C7.05822 11.1333 7.18795 11.1389 7.3177 11.1389Z"
          fill="inherit"
        />
        <path
          d="M7.3303 17.3459C7.1946 17.3459 7.05853 17.3507 6.92135 17.3459C6.72909 17.3391 6.54703 17.2577 6.4137 17.119C6.28038 16.9803 6.20627 16.7952 6.20704 16.6028C6.20781 16.4105 6.28342 16.2259 6.41785 16.0884C6.55228 15.9507 6.73499 15.8707 6.92729 15.8655C7.19237 15.8577 7.45819 15.857 7.72326 15.8655C7.91463 15.8737 8.09542 15.9557 8.22769 16.0942C8.35994 16.2328 8.43339 16.4172 8.43262 16.6087C8.43185 16.8003 8.35692 16.9842 8.22355 17.1216C8.09018 17.2591 7.90874 17.3397 7.7173 17.3463C7.58979 17.3522 7.46116 17.3459 7.3303 17.3459Z"
          fill="inherit"
        />
      </g>
    </svg>
  );
};

export default ResearchIcon;
