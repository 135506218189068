import React from 'react'

const ThreeDotsIcon: React.FC<any> = (props: any) => {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      stroke="#93C5FD"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M11 12.0005C11 12.5528 11.4477 13.0005 12 13.0005C12.5523 13.0005 13 12.5528 13 12.0005C13 11.4482 12.5523 11.0005 12 11.0005C11.4477 11.0005 11 11.4482 11 12.0005Z"
        stroke="inherit"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11 19C11 19.5523 11.4477 20 12 20C12.5523 20 13 19.5523 13 19C13 18.4477 12.5523 18 12 18C11.4477 18 11 18.4477 11 19Z"
        stroke="inherit"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11 4.99951C11 5.5518 11.4477 5.99951 12 5.99951C12.5523 5.99951 13 5.5518 13 4.99951C13 4.44723 12.5523 3.99951 12 3.99951C11.4477 3.99951 11 4.44723 11 4.99951Z"
        stroke="inherit"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default ThreeDotsIcon
