import { Drawer } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useRagContext } from '../../../contexts/RagContext'
import searchIcon from '../../../assets/svg/search.svg'
import CreateConceptFolder from './CreateConceptFolder'

const CreateConceptDrawer: React.FC = () => {
  const [searchText, setSearchText] = useState('')
  const [shownFolderList, setShownFolderList] = useState<any[]>([])

  const {
    folderList,
    createConceptDrawerVisible,
    selectedCreateConceptFiles,
    setSelectedCreateConceptFiles,
    closeCreateConceptDrawer,
    openConceptAddFolderModal,
  } = useRagContext()

  useEffect(() => {
    if (searchText === '') {
      setShownFolderList(folderList)
    } else {
      const searchTextLower = searchText.toLowerCase()
      const tempFolderList = JSON.parse(JSON.stringify(folderList))

      const traverseAndFilter = (node: any) => {
        const keysToRemove: string[] = []

        Object.keys(node).forEach((key) => {
          if (key.toLowerCase().indexOf(searchTextLower) === -1) {
            keysToRemove.push(key)
          } else if (node[key].children) {
            traverseAndFilter(node[key].children)
          }
        })

        keysToRemove.forEach((key) => {
          delete node[key]
        })
      }

      tempFolderList.forEach((folder: any) => {
        traverseAndFilter(folder.structure)
      })

      setShownFolderList(tempFolderList)
    }
  }, [folderList, searchText])

  useEffect(() => {
    if (!createConceptDrawerVisible) {
      setSelectedCreateConceptFiles([])
    }
  }, [createConceptDrawerVisible])

  return (
    <Drawer
      open={createConceptDrawerVisible}
      onClose={() => closeCreateConceptDrawer()}
      anchor="right"
    >
      <div className="w-[470px]">
        <div className="w-full h-[46px] px-5 py-[10px] border-b border-solid border-[#D4D4D8]">
          <span className="text-lg leading-[26px] font-medium text-[#09090B]">
            Select Source
          </span>
        </div>
        <div className="px-5 py-4">
          <div
            className={`relative w-full h-[38px] rounded-[4px] border border-solid border-[#D4D4D8] bg-white px-[10px] py-2 flex gap-3 items-center mb-4`}
          >
            <img
              src={searchIcon}
              alt="Search"
              className="w-[18px] h-[18px] flex-none"
            />
            <input
              type="text"
              className="flex-1 text-sm leading-[22px] text-[#A1A1AA] font-medium outline-none"
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
              placeholder="Search"
            />
          </div>

          <div
            className={`w-full overflow-x-auto overflow-y-auto mb-4 ${selectedCreateConceptFiles.length
              ? 'h-[calc(100vh-199px)]'
              : 'h-[calc(100vh-150px)]'
              }`}
            id="create-concept-folder-container"
          >
            {shownFolderList.map((folder, index) => {
              return folder.isFolderDeleted === 1 ? (
                <div key={index}></div>
              ) : (
                <CreateConceptFolder
                  key={index}
                  folder={folder}
                ></CreateConceptFolder>
              )
            })}
          </div>
          {selectedCreateConceptFiles.length > 0 && (
            <button
              className="w-full h-12 rounded-xl bg-[#3B82F6] outline-none flex items-center justify-center gap-2"
              onClick={() => openConceptAddFolderModal('create_concept_drawer')}
            >
              <span className="text-white text-base font-bold font-Satoshi">
                Create New Concept
              </span>
            </button>
          )}
        </div>
      </div>
    </Drawer>
  )
}

export default CreateConceptDrawer
