import React, { useCallback, useState } from "react";
import { Skeleton } from "@mui/material";
import ArrowRightIcon2 from "../../icons/ArrowRightIcon2";
import axios from "axios";
import { useUserContext } from "../../../contexts/UserContext";
import CommonMarkdown from "../../common/CommonMarkdown";
// import { toast } from "react-toastify";

interface BusinessModelContentProps {
  conceptData: any;
  visible?: boolean;
  getConceptDetail: () => void;
}

type ChatAction = "short" | "elaborate" | "regenerate" | "user_input";

type BusinessModelType =
  | "Channels"
  | "Cost_Structure"
  | "Customer_Relationships"
  | "Customer_Segments"
  | "Key_Activities"
  | "Key_Partnerships"
  | "Key_Resources"
  | "Revenue_Streams"
  | "Value_Propositions";

const businessModelCanvasTitle: any = {
  Key_Partnerships: "Key Partner",
  Key_Activities: "Key Activity",
  Key_Resources: "Key Resources",
  Value_Propositions: "Value Propositions",
  Customer_Relationships: "Customer Relationships",
  Channels: "Channels",
  Customer_Segments: "Customer Segments",
  Cost_Structure: "Cost Structure",
  Revenue_Streams: "Revenue Streams",
};

const BusinessModelContent: React.FC<BusinessModelContentProps> = ({
  conceptData,
  visible,
  getConceptDetail,
}) => {
  const { user } = useUserContext();
  const [selectedCanvas, setSelectedCanvas] =
    useState<BusinessModelType | null>(null);
  const [userInput, setUserInput] = useState("");
  const [showLoading, setShowLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  const [chatStatus, setChatStatus] = useState({
    status: "initial",
    response: "",
  });

  const handleGenerateResponse = useCallback(
    async (action: ChatAction) => {
      if (showLoading || saving || selectedCanvas === null) return;

      let params: any = {
        team_id: user?.organizationMemberships[0]?.organization.id,
        file_list: conceptData.file_list,
        pinecone_id: conceptData.pinecone_id,
        action: action,
        previous_response: chatStatus.response,
        selected_section: selectedCanvas,
      };
      if (conceptData.context && conceptData.context !== "") {
        params.context = conceptData.context;
      }

      if (action === "user_input") {
        if (userInput === "") return;
        params.user_input = userInput;
        setShowLoading(true);
        let response = await axios.post(
          `${process.env.REACT_APP_API_URL}api/getBusinessModelCanvasJSON`,
          params
        );
        setChatStatus({
          status: "updated",
          response: response.data.response,
        });
        setShowLoading(false);
        setUserInput("");
        onGenerateComplete();
      } else {
        setShowLoading(true);
        let response = await axios.post(
          `${process.env.REACT_APP_API_URL}api/getBusinessModelCanvasJSON`,
          params
        );
        setChatStatus({
          status: "updated",
          response: response.data.response,
        });
        setShowLoading(false);
        setUserInput("");
        onGenerateComplete();
      }
    },
    [userInput, chatStatus, showLoading, saving, selectedCanvas, conceptData]
  );

  const handleSave = useCallback(async () => {
    if (saving) return;
    let params: any = {
      id: conceptData.id,
      BusinessModelCanvasJSON: {
        ...conceptData["BusinessModelCanvasJSON"],
        [selectedCanvas as string]: chatStatus.response,
      },
    };
    try {
      setSaving(true);
      let response = await axios.post(
        process.env.REACT_APP_API_URL + "api/editConcept",
        params
      );
      setSaving(false);
      setChatStatus({
        status: "initial",
        response: chatStatus.response,
      });
      if (response?.status === 200) {
        getConceptDetail();
      }
    } catch (error) {
      console.log(error);
    }
  }, [conceptData, chatStatus, saving, selectedCanvas]);

  const handleSelectCanvas = (canvas: BusinessModelType) => {
    setSelectedCanvas(canvas);
    setShowLoading(false);
    setChatStatus({
      status: "updated",
      response: conceptData["BusinessModelCanvasJSON"][canvas],
    });
  };

  const handleCancel = useCallback(() => {
    setChatStatus({
      status: "initial",
      response:
        conceptData["BusinessModelCanvasJSON"][selectedCanvas as string],
    });
  }, [conceptData, selectedCanvas]);

  const onGenerateComplete = useCallback(() => {
    if (!visible) {
      // toast.success("Business Model Canvas generate completed!", {
      //   position: "top-right",
      //   autoClose: 3000,
      //   hideProgressBar: true,
      //   closeOnClick: true,
      //   pauseOnHover: true,
      //   draggable: false,
      //   progress: 0,
      //   toastId: "my_toast",
      // });
    }
  }, [visible]);

  if (!visible) return null;

  return (
    <div className="flex flex-col gap-3 h-full">
      <div className="bg-white p-5 rounded-[4px] flex-1">
        <div
          className={`bg-white border border-solid border-[#D4D4D8] rounded-2xl overflow-x-hidden overflow-y-auto ${
            showLoading || chatStatus.status === "updated"
              ? "max-h-[calc(100vh-640px)]"
              : "max-h-[calc(100vh-342px)]"
          }`}
        >
          <div className="grid grid-cols-5">
            <div
              className={`cursor-pointer p-3 border-r border-solid border-[#D4D4D8] rounded-tl-2xl -outline-offset-1 outline-1 ${
                selectedCanvas === "Key_Partnerships"
                  ? "outline-[#3B82F6] outline"
                  : "hover:outline-[#3B82F6] hover:outline outline-none"
              }`}
              onClick={() => handleSelectCanvas("Key_Partnerships")}
            >
              <div className="mb-1 text-sm leading-[22px] font-bold text-[#27272A]">
                Key Partner
              </div>
              <div className="text-sm leading-[22px] font-normal text-[#71717A] break-anywhere">
                <CommonMarkdown>
                  {conceptData["BusinessModelCanvasJSON"]["Key_Partnerships"]}
                </CommonMarkdown>
              </div>
            </div>
            <div className="flex flex-col border-r border-solid border-[#D4D4D8]">
              <div
                className={`cursor-pointer flex-1 p-3 border-b border-solid border-[#D4D4D8] -outline-offset-1 outline-1 ${
                  selectedCanvas === "Key_Activities"
                    ? "outline-[#3B82F6] outline"
                    : "hover:outline-[#3B82F6] hover:outline outline-none"
                }`}
                onClick={() => handleSelectCanvas("Key_Activities")}
              >
                <div className="mb-1 text-sm leading-[22px] font-bold text-[#27272A]">
                  Key Activity
                </div>
                <div className="text-sm leading-[22px] font-normal text-[#71717A] break-anywhere">
                  <CommonMarkdown>
                    {conceptData["BusinessModelCanvasJSON"]["Key_Activities"]}
                  </CommonMarkdown>
                </div>
              </div>
              <div
                className={`cursor-pointer flex-1 p-3 -outline-offset-1 outline-1 ${
                  selectedCanvas === "Key_Resources"
                    ? "outline-[#3B82F6] outline"
                    : "hover:outline-[#3B82F6] hover:outline outline-none"
                }`}
                onClick={() => handleSelectCanvas("Key_Resources")}
              >
                <div className="mb-1 text-sm leading-[22px] font-bold text-[#27272A]">
                  Key Resources
                </div>
                <div className="text-sm leading-[22px] font-normal text-[#71717A] break-anywhere">
                  <CommonMarkdown>
                    {conceptData["BusinessModelCanvasJSON"]["Key_Resources"]}
                  </CommonMarkdown>
                </div>
              </div>
            </div>
            <div
              className={`cursor-pointer p-3 border-r border-solid border-[#D4D4D8] -outline-offset-1 outline-1 ${
                selectedCanvas === "Value_Propositions"
                  ? "outline-[#3B82F6] outline"
                  : "hover:outline-[#3B82F6] hover:outline outline-none"
              }`}
              onClick={() => handleSelectCanvas("Value_Propositions")}
            >
              <div className="mb-1 text-sm leading-[22px] font-bold text-[#27272A]">
                Value Propositions
              </div>
              <div className="text-sm leading-[22px] font-normal text-[#71717A] break-anywhere">
                <CommonMarkdown>
                  {conceptData["BusinessModelCanvasJSON"]["Value_Propositions"]}
                </CommonMarkdown>
              </div>
            </div>
            <div className="flex flex-col border-r border-solid border-[#D4D4D8]">
              <div
                className={`cursor-pointer flex-1 p-3 border-b border-solid border-[#D4D4D8] -outline-offset-1 outline-1 ${
                  selectedCanvas === "Customer_Relationships"
                    ? "outline-[#3B82F6] outline"
                    : "hover:outline-[#3B82F6] hover:outline outline-none"
                }`}
                onClick={() => handleSelectCanvas("Customer_Relationships")}
              >
                <div className="mb-1 text-sm leading-[22px] font-bold text-[#27272A]">
                  Customer Relationships
                </div>
                <div className="text-sm leading-[22px] font-normal text-[#71717A] break-anywhere">
                  <CommonMarkdown>
                    {
                      conceptData["BusinessModelCanvasJSON"][
                        "Customer_Relationships"
                      ]
                    }
                  </CommonMarkdown>
                </div>
              </div>
              <div
                className={`cursor-pointer flex-1 p-3 -outline-offset-1 outline-1 ${
                  selectedCanvas === "Channels"
                    ? "outline-[#3B82F6] outline"
                    : "hover:outline-[#3B82F6] hover:outline outline-none"
                }`}
                onClick={() => handleSelectCanvas("Channels")}
              >
                <div className="mb-1 text-sm leading-[22px] font-bold text-[#27272A]">
                  Channels
                </div>
                <div className="text-sm leading-[22px] font-normal text-[#71717A] break-anywhere">
                  <CommonMarkdown>
                    {conceptData["BusinessModelCanvasJSON"]["Channels"]}
                  </CommonMarkdown>
                </div>
              </div>
            </div>
            <div
              className={`cursor-pointer p-3 rounded-tr-2xl -outline-offset-1 outline-1 ${
                selectedCanvas === "Customer_Segments"
                  ? "outline-[#3B82F6] outline"
                  : "hover:outline-[#3B82F6] hover:outline outline-none"
              }`}
              onClick={() => handleSelectCanvas("Customer_Segments")}
            >
              <div className="mb-1 text-sm leading-[22px] font-bold text-[#27272A]">
                Customer Segments
              </div>
              <div className="text-sm leading-[22px] font-normal text-[#71717A] break-anywhere">
                <CommonMarkdown>
                  {conceptData["BusinessModelCanvasJSON"]["Customer_Segments"]}
                </CommonMarkdown>
              </div>
            </div>
          </div>
          <div className="grid grid-cols-2 border-t border-solid border-[#D4D4D8]">
            <div
              className={`cursor-pointer p-3 border-r border-solid border-[#D4D4D8] -outline-offset-1 outline-1 rounded-bl-2xl ${
                selectedCanvas === "Cost_Structure"
                  ? "outline-[#3B82F6] outline"
                  : "hover:outline-[#3B82F6] hover:outline outline-none"
              }`}
              onClick={() => handleSelectCanvas("Cost_Structure")}
            >
              <div className="mb-1 text-sm leading-[22px] font-bold text-[#27272A]">
                Cost Structure
              </div>
              <div className="text-sm leading-[22px] font-normal text-[#71717A] break-anywhere">
                <CommonMarkdown>
                  {conceptData["BusinessModelCanvasJSON"]["Cost_Structure"]}
                </CommonMarkdown>
              </div>
            </div>
            <div
              className={`cursor-pointer p-3 -outline-offset-1 outline-1 rounded-br-2xl ${
                selectedCanvas === "Revenue_Streams"
                  ? "outline-[#3B82F6] outline"
                  : "hover:outline-[#3B82F6] hover:outline outline-none"
              }`}
              onClick={() => handleSelectCanvas("Revenue_Streams")}
            >
              <div className="mb-1 text-sm leading-[22px] font-bold text-[#27272A]">
                Revenue Streams
              </div>
              <div className="text-sm leading-[22px] font-normal text-[#71717A] break-anywhere">
                <CommonMarkdown>
                  {conceptData["BusinessModelCanvasJSON"]["Revenue_Streams"]}
                </CommonMarkdown>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-white p-5 rounded-[4px] flex-none">
        {showLoading ? (
          <>
            <div className="text-base font-medium text-[#71717A] mb-[10px]">
              Editing{" "}
              <span className="text-[#27272A]">Business Model Canvas</span>
            </div>
            <div className="rounded-[4px] border-[#D4D4D8] border">
              <div className="">
                <div className="flex flex-col gap-2 h-[266px] p-3">
                  <Skeleton
                    height={10}
                    sx={{
                      width: "70%",
                      transform: "scale(1)",
                      overflow: "hidden",
                    }}
                  />
                  <Skeleton
                    height={10}
                    sx={{
                      width: "100%",
                      transform: "scale(1)",
                      overflow: "hidden",
                    }}
                  />
                  <Skeleton
                    height={10}
                    sx={{
                      width: "45%",
                      transform: "scale(1)",
                      overflow: "hidden",
                    }}
                  />
                  <Skeleton
                    height={10}
                    sx={{
                      width: "100%",
                      transform: "scale(1)",
                      overflow: "hidden",
                    }}
                  />
                  <Skeleton
                    height={10}
                    sx={{
                      width: "70%",
                      transform: "scale(1)",
                      overflow: "hidden",
                    }}
                  />
                  <Skeleton
                    height={10}
                    sx={{
                      width: "67%",
                      transform: "scale(1)",
                      overflow: "hidden",
                    }}
                  />
                </div>
                <div className="h-[52px] w-full py-[14px] px-4 gap-2 flex items-center border-t border-[#E4E4E7] ">
                  <input
                    type="text"
                    className="outline-none border-none bg-transparent text-sm text-[#71717A] flex-1"
                    placeholder="Chat to refine the text above"
                    value={userInput}
                    disabled
                  />
                  <button
                    className="w-7 h-7 flex-none bg-[#D4D4D8] rounded-full flex items-center justify-center"
                    onClick={() => handleGenerateResponse("user_input")}
                  >
                    <ArrowRightIcon2 stroke="#ffffff" className="w-4 h-4" />
                  </button>
                </div>
              </div>
            </div>
          </>
        ) : chatStatus.status === "initial" ? (
          <>
            {selectedCanvas ? (
              <>
                <div className="flex gap-2 mb-2">
                  <div
                    className="w-fit h-7 px-3 flex items-center rounded-full border border-[#E4E4E7] text-[#52525B] text-xs cursor-pointer"
                    onClick={() => handleGenerateResponse("short")}
                  >
                    Shorten
                  </div>
                  <div
                    className="w-fit h-7 px-3 flex items-center rounded-full border border-[#E4E4E7] text-[#52525B] text-xs cursor-pointer"
                    onClick={() => handleGenerateResponse("elaborate")}
                  >
                    Elaborate
                  </div>
                  <div
                    className="w-fit h-7 px-3 flex items-center rounded-full border border-[#E4E4E7] text-[#52525B] text-xs cursor-pointer"
                    onClick={() => handleGenerateResponse("regenerate")}
                  >
                    Regenerate
                  </div>
                </div>
                <div className="h-[52px] w-full rounded-[4px] border py-[14px] px-4 gap-2 flex items-center bg-[#FCFCFC] border-[#E4E4E7]">
                  <span className="text-sm text-[#3B82F6] font-bold">
                    {businessModelCanvasTitle[selectedCanvas]}
                  </span>
                  <input
                    type="text"
                    className="outline-none border-none bg-transparent text-sm text-[#71717A] flex-1"
                    placeholder="Chat to refine the text above"
                    value={userInput}
                    onChange={(e) => setUserInput(e.target.value)}
                    onKeyDown={(e) => {
                      if (e.keyCode === 13) {
                        handleGenerateResponse("user_input");
                      }
                    }}
                  />
                  <button
                    className="w-7 h-7 flex-none bg-[#3B82F6] rounded-full flex items-center justify-center"
                    onClick={() => handleGenerateResponse("user_input")}
                  >
                    <ArrowRightIcon2 stroke="#ffffff" className="w-4 h-4" />
                  </button>
                </div>
              </>
            ) : (
              <>
                <div className="flex gap-2 mb-2">
                  <div className="w-fit h-7 px-3 flex items-center rounded-full bg-[#F4F4F5] text-[#71717A] text-xs cursor-pointer">
                    Shorten
                  </div>
                  <div className="w-fit h-7 px-3 flex items-center rounded-full bg-[#F4F4F5] text-[#71717A] text-xs cursor-pointer">
                    Elaborate
                  </div>
                  <div className="w-fit h-7 px-3 flex items-center rounded-full bg-[#F4F4F5] text-[#71717A] text-xs cursor-pointer">
                    Regenerate
                  </div>
                </div>
                <div className="h-[52px] w-full rounded-[4px] border py-[14px] px-4 gap-2 flex items-center bg-[#F4F4F5] border-[#D4D4D8]">
                  <input
                    type="text"
                    className="outline-none border-none bg-transparent text-sm text-[#71717A] flex-1"
                    placeholder="Select a section to refine"
                    readOnly
                    disabled
                  />
                  <button className="w-7 h-7 flex-none bg-[#D4D4D8] rounded-full flex items-center justify-center">
                    <ArrowRightIcon2 stroke="#ffffff" className="w-4 h-4" />
                  </button>
                </div>
              </>
            )}
          </>
        ) : (
          <>
            <div className="text-base font-medium text-[#71717A] mb-[10px]">
              Editing{" "}
              <span className="text-[#27272A]">Business Model Canvas</span>
            </div>
            <div className="rounded-[4px] border-[#3B82F6] border">
              <div className="">
                <textarea
                  className="px-3 py-2 h-[232px] resize-none outline-none w-full bg-transparent text-sm"
                  onChange={(e) =>
                    setChatStatus((prev) => ({
                      ...prev,
                      response: e.target.value,
                    }))
                  }
                  value={chatStatus.response}
                ></textarea>
                <div className="flex gap-4 mb-2 px-3 items-center">
                  {chatStatus.response ===
                    conceptData["BusinessModelCanvasJSON"][
                      selectedCanvas as string
                    ] || chatStatus.response === "" ? (
                    <span className="text-[#3b83f680] text-sm font-bold cursor-pointer underline">
                      Save
                    </span>
                  ) : (
                    <span
                      className="text-[#3B82F6] text-sm font-bold cursor-pointer underline"
                      onClick={() => handleSave()}
                    >
                      {saving ? "Saving..." : "Save"}
                    </span>
                  )}
                  <span
                    className="text-[#3B82F6] text-sm font-bold cursor-pointer underline"
                    onClick={() => handleCancel()}
                  >
                    Cancel
                  </span>
                </div>
                <div className="h-[52px] w-full border-t border-[#E4E4E7] py-[14px] px-4 gap-2 flex items-center">
                  {selectedCanvas && (
                    <span className="text-sm text-[#3B82F6] font-bold">
                      {businessModelCanvasTitle[selectedCanvas]}
                    </span>
                  )}
                  <input
                    type="text"
                    className="outline-none border-none bg-transparent text-sm text-[#71717A] flex-1"
                    placeholder="Chat to refine the text above"
                    value={userInput}
                    onChange={(e) => setUserInput(e.target.value)}
                    onKeyDown={(e) => {
                      if (e.keyCode === 13) {
                        handleGenerateResponse("user_input");
                      }
                    }}
                  />
                  <button
                    className="w-7 h-7 flex-none bg-[#3B82F6] rounded-full flex items-center justify-center"
                    onClick={() => handleGenerateResponse("user_input")}
                  >
                    <ArrowRightIcon2 stroke="#ffffff" className="w-4 h-4" />
                  </button>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default BusinessModelContent;
