import React from 'react'

const GoogleScholarIcon: React.FC<any> = (props: any) => {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      stroke="#52525B"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M17 14.4996V11.494C17 11.3145 17 11.2248 16.9727 11.1456C16.9485 11.0755 16.9091 11.0117 16.8572 10.9587C16.7986 10.8989 16.7183 10.8587 16.5578 10.7785L12 8.49958M4 9.49958V16.3062C4 16.6781 4 16.8641 4.05802 17.0269C4.10931 17.1708 4.1929 17.3011 4.30238 17.4077C4.42622 17.5283 4.59527 17.6057 4.93335 17.7607L11.3334 20.694C11.5786 20.8064 11.7012 20.8626 11.8289 20.8848C11.9421 20.9045 12.0579 20.9045 12.1711 20.8848C12.2988 20.8626 12.4214 20.8064 12.6666 20.694L19.0666 17.7607C19.4047 17.6057 19.5738 17.5283 19.6976 17.4077C19.8071 17.3011 19.8907 17.1708 19.942 17.0269C20 16.8641 20 16.6781 20 16.3062V9.49958M2 8.49958L11.6422 3.67846C11.7734 3.61287 11.839 3.58008 11.9078 3.56717C11.9687 3.55574 12.0313 3.55574 12.0922 3.56717C12.161 3.58008 12.2266 3.61287 12.3578 3.67846L22 8.49958L12.3578 13.3207C12.2266 13.3863 12.161 13.4191 12.0922 13.432C12.0313 13.4434 11.9687 13.4434 11.9078 13.432C11.839 13.4191 11.7734 13.3863 11.6422 13.3207L2 8.49958Z"
        stroke="inherit"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default GoogleScholarIcon
