// ErrorPage.tsx
import React from 'react';

const ErrorPage: React.FC = () => {
    return (
        <div className="flex items-center justify-center h-screen">
            <div className="text-center">
                <h1 className="text-4xl font-bold">Oops! Something went wrong.</h1>
                <p className="text-lg mt-4">An unexpected error has occurred. Please refresh the page or try again later.</p>
            </div>
        </div>
    );
};

export default ErrorPage;
