// src/PrivateRoute.tsx
import React from "react";
import { Route, Redirect, RouteProps } from "react-router-dom";
import { useUserContext } from "../contexts/UserContext";
import PageLoading from "../Components/common/PageLoading";
import Layout from "../layout/layout";
interface PrivateRouteProps extends RouteProps {
  component: React.ComponentType<any>;
  layout: boolean;
  page: string;
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({
  component: Component,
  layout,
  page,
  ...rest
}) => {
  const { isLoaded, isSignedIn } = useUserContext();

  if (!isLoaded) {
    return <PageLoading></PageLoading>;
  }

  if (!isSignedIn) {
    return <Redirect to="/login" />;
  }

  if (layout) {
    return (
      <Route
        {...rest}
        render={(props) => (
          <Layout page={page}>
            <Component {...props} />
          </Layout>
        )}
      />
    );
  } else {
    return <Route {...rest} render={(props) => <Component {...props} />} />;
  }
};

export default PrivateRoute;
