import React, { useEffect, useState } from "react";
import Modal from "@mui/material/Modal";
import { IoCloseOutline } from "react-icons/io5";
import { fiveForceTabList } from "../FiveForcesContent";

interface AddFiveForceItemModalProps {
  open?: boolean;
  tab: string;
  handleClose?: () => void;
  handleSubmit: (arg: any) => void;
}

const AddFiveForceItemModal: React.FC<AddFiveForceItemModalProps> = ({
  open,
  tab,
  handleClose,
  handleSubmit,
}) => {
  const [name, setName] = useState<string>("");
  const [relevance, setRelevance] = useState<string>("");
  const [url, setUrl] = useState<string>("");

  useEffect(() => {
    if (!open) {
      setName("");
      setRelevance("");
      setUrl("");
    }
  }, [open]);

  const handleModalSubmit = () => {
    handleSubmit({
      name: name,
      relevance: relevance,
      website_url: url,
    });
  };

  return (
    <Modal
      open={Boolean(open)}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 w-[730px] xl:w-[974px] bg-white rounded-[10px] shadow-lg">
        <div className="py-3 xl:py-4 px-[18px] xl:px-6 flex items-center justify-between border-b border-solid border-[#E4E4E7] gap-3 xl:gap-4">
          <div className="">
            <div className="text-[13px] xl:text-lg leading-[16px] xl:leading-[22px] font-bold text-[#18181B] mb-[3px] xl:mb-1">
              Add {fiveForceTabList.find((e) => e.key === tab)?.title}
            </div>
          </div>
          <button
            className="w-[30px] xl:w-10 h-[30px] xl:h-10 border border-solid border-[#D4D4D8] flex items-center justify-center flex-none rounded-full"
            onClick={() => {
              if (handleClose) handleClose();
            }}
          >
            <IoCloseOutline className="w-[18px] xl:w-6 h-[18px] xl:h-6 text-[#09090B]" />
          </button>
        </div>
        <div className="p-[18px] xl:p-6">
          <div className="w-full flex flex-col gap-3 xl:gap-4 mb-6 xl:mb-8">
            <div className="w-full">
              <label
                htmlFor="edit-five-force-item-title"
                className="px-[3px] xl:px-1 mb-[3px] xl:mb-1"
              >
                Name
              </label>
              <input
                type="text"
                id="edit-five-force-item-title"
                name="edit-five-force-item-title"
                className="w-full border border-solid border-[#D4D4D8] outline-none px-3 xl:px-4 py-[9px] xl:py-3 rounded-lg h-9 xl:h-12 text-[10px] xl:text-sm font-medium text-[#3F3F46]"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </div>
            <div className="w-full">
              <label
                htmlFor="edit-five-force-item-website-url"
                className="px-[3px] xl:px-1 mb-[3px] xl:mb-1"
              >
                Website Url
              </label>
              <input
                type="text"
                id="edit-five-force-item-website-url"
                name="edit-five-force-item-website-url"
                className="w-full border border-solid border-[#D4D4D8] outline-none px-3 xl:px-4 py-[9px] xl:py-3 rounded-lg h-9 xl:h-12 text-[10px] xl:text-sm font-medium text-[#3F3F46]"
                value={url}
                onChange={(e) => setUrl(e.target.value)}
              />
            </div>
            <div className="w-full">
              <label
                htmlFor="edit-five-force-item-description"
                className="px-[3px] xl:px-1 mb-[3px] xl:mb-1"
              >
                Relevance
              </label>
              <textarea
                id="edit-five-force-item-description"
                name="edit-five-force-item-description"
                className="w-full border border-solid border-[#D4D4D8] outline-none px-3 xl:px-4 py-[9px] xl:py-3 rounded-lg h-[126px] xl:h-[168px] text-[10px] xl:text-sm font-medium text-[#3F3F46]"
                value={relevance}
                onChange={(e) => setRelevance(e.target.value)}
              />
            </div>
          </div>
          <div className="flex gap-3 xl:gap-4">
            <button
              className="outline-none border border-solid border-[#71717A] h-9 xl:h-12 flex items-center justify-center rounded-lg w-full"
              onClick={() => {
                if (handleClose) handleClose();
              }}
            >
              <span className="text-xs xl:text-base font-bold text-[#71717A]">
                Cancel
              </span>
            </button>
            <button
              className="outline-none bg-[#3B82F6] h-9 xl:h-12 flex items-center justify-center rounded-lg w-full"
              onClick={() => handleModalSubmit()}
            >
              <span className="text-xs xl:text-base font-bold text-white">
                Save
              </span>
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default AddFiveForceItemModal;
