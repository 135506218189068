import { Drawer } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useRagContext } from "../../../contexts/RagContext";
import searchIcon from "../../../assets/svg/search.svg";
import CreateConceptFolder from "./CreateConceptFolder";
import { useLayoutContext } from "../../../contexts/LayoutContext";

const CreateConceptDrawer: React.FC = () => {
  const [searchText, setSearchText] = useState("");
  const [shownFolderList, setShownFolderList] = useState<any[]>([]);

  const {
    folderList,
    createConceptDrawerVisible,
    selectedCreateConceptFiles,
    setSelectedCreateConceptFiles,
    closeCreateConceptDrawer,
    openConceptAddFolderModal,
  } = useRagContext();
  const { deviceType } = useLayoutContext();

  useEffect(() => {
    if (searchText === "") {
      setShownFolderList(folderList);
    } else {
      const searchTextLower = searchText.toLowerCase();
      const tempFolderList = JSON.parse(JSON.stringify(folderList));

      const traverseAndFilter = (node: any) => {
        const keysToRemove: string[] = [];

        Object.keys(node).forEach((key) => {
          if (key.toLowerCase().indexOf(searchTextLower) === -1) {
            keysToRemove.push(key);
          } else if (node[key].children) {
            traverseAndFilter(node[key].children);
          }
        });

        keysToRemove.forEach((key) => {
          delete node[key];
        });
      };

      tempFolderList.forEach((folder: any) => {
        traverseAndFilter(folder.structure);
      });

      setShownFolderList(tempFolderList);
    }
  }, [folderList, searchText]);

  useEffect(() => {
    if (!createConceptDrawerVisible) {
      setSelectedCreateConceptFiles([]);
    }
  }, [createConceptDrawerVisible]);

  return (
    <Drawer
      open={createConceptDrawerVisible}
      onClose={() => closeCreateConceptDrawer()}
      anchor="right"
    >
      <div className="w-[352px] xl:w-[470px]">
        <div className="w-full h-[34px] xl:h-[46px] px-[15px] xl:px-5 py-[7px] xl:py-[10px] border-b border-solid border-[#D4D4D8]">
          <span className="text-[13px] xl:text-lg leading-[19px] xl:leading-[26px] font-medium text-[#09090B]">
            Select Source
          </span>
        </div>
        <div className="px-[15px] xl:px-5 py-3 xl:py-4">
          <div
            className={`relative w-full h-[28px] xl:h-[38px] rounded-[4px] border border-solid border-[#D4D4D8] bg-white px-[7px] xl:px-[10px] py-[6px] xl:py-2 flex gap-[9px] xl:gap-3 items-center mb-3 xl:mb-4`}
          >
            <img
              src={searchIcon}
              alt="Search"
              className="w-[13px] xl:w-[18px] h-[13px] xl:h-[18px] flex-none"
            />
            <input
              type="text"
              className="flex-1 text-[10px] xl:text-sm leading-[16px] xl:leading-[22px] text-[#A1A1AA] font-medium outline-none"
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
              placeholder="Search"
            />
          </div>

          <div
            className="w-full overflow-x-auto overflow-y-auto mb-3 xl:mb-4"
            style={{
              height: selectedCreateConceptFiles.length
                ? `calc(100vh - ${
                    199 * (deviceType === "laptop" ? 0.75 : 1)
                  }px)`
                : `calc(100vh - ${
                    150 * (deviceType === "laptop" ? 0.75 : 1)
                  }px)`,
            }}
            id="create-concept-folder-container"
          >
            {shownFolderList.map((folder, index) => {
              return folder.isFolderDeleted === 1 ? (
                <div key={index}></div>
              ) : (
                <CreateConceptFolder
                  key={index}
                  folder={folder}
                ></CreateConceptFolder>
              );
            })}
          </div>
          {selectedCreateConceptFiles.length > 0 && (
            <button
              className="w-full h-9 xl:h-12 rounded-xl bg-[#3B82F6] outline-none flex items-center justify-center gap-[6px] xl:gap-2"
              onClick={() => openConceptAddFolderModal("create_concept_drawer")}
            >
              <span className="text-white text-xs xl:text-base font-bold font-Satoshi">
                Create New Concept
              </span>
            </button>
          )}
        </div>
      </div>
    </Drawer>
  );
};

export default CreateConceptDrawer;
