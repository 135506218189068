import React from 'react'

const GridIcon: React.FC<any> = (props: any) => {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      stroke="#71717A"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M20.3333 11.0832V5.4165C20.3333 4.1665 19.8 3.6665 18.475 3.6665H15.1083C13.7833 3.6665 13.25 4.1665 13.25 5.4165V11.0832C13.25 12.3332 13.7833 12.8332 15.1083 12.8332H18.475C19.8 12.8332 20.3333 12.3332 20.3333 11.0832Z"
        stroke="inherit"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.3333 18.583V17.083C20.3333 15.833 19.8 15.333 18.475 15.333H15.1083C13.7833 15.333 13.25 15.833 13.25 17.083V18.583C13.25 19.833 13.7833 20.333 15.1083 20.333H18.475C19.8 20.333 20.3333 19.833 20.3333 18.583Z"
        stroke="inherit"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.7474 12.9165V18.5832C10.7474 19.8332 10.2141 20.3332 8.88906 20.3332H5.5224C4.1974 20.3332 3.66406 19.8332 3.66406 18.5832V12.9165C3.66406 11.6665 4.1974 11.1665 5.5224 11.1665H8.88906C10.2141 11.1665 10.7474 11.6665 10.7474 12.9165Z"
        stroke="inherit"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.7474 5.4165V6.9165C10.7474 8.1665 10.2141 8.6665 8.88906 8.6665H5.5224C4.1974 8.6665 3.66406 8.1665 3.66406 6.9165V5.4165C3.66406 4.1665 4.1974 3.6665 5.5224 3.6665H8.88906C10.2141 3.6665 10.7474 4.1665 10.7474 5.4165Z"
        stroke="inherit"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default GridIcon
