import { Skeleton } from "@mui/material";
import React, { useCallback, useState } from "react";
import ArrowRightIcon2 from "../../icons/ArrowRightIcon2";
import axios from "axios";
import { useUserContext } from "../../../contexts/UserContext";
import CommonMarkdown from "../../common/CommonMarkdown";
// import { toast } from "react-toastify";

interface GoodnessFactorContentProps {
  conceptData: any;
  visible?: boolean;
  getConceptDetail: () => void;
}

type ChatAction = "short" | "elaborate" | "regenerate" | "user_input";

const GoodnessFactorContent: React.FC<GoodnessFactorContentProps> = ({
  conceptData,
  visible,
  getConceptDetail,
}) => {
  const { user } = useUserContext();
  const [userInput, setUserInput] = useState("");
  const [showLoading, setShowLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  const [chatStatus, setChatStatus] = useState({
    status: "initial",
    response: conceptData["GoodnessFactor"],
  });

  const handleGenerateResponse = useCallback(
    async (action: ChatAction) => {
      if (showLoading || saving) return;

      let params: any = {
        team_id: user?.organizationMemberships[0]?.organization.id,
        file_list: conceptData.file_list,
        pinecone_id: conceptData.pinecone_id,
        action: action,
        previous_response: chatStatus.response,
      };
      if (conceptData.context && conceptData.context !== "") {
        params.context = conceptData.context;
      }

      if (action === "user_input") {
        if (userInput === "") return;
        params.user_input = userInput;
        setShowLoading(true);
        let response = await axios.post(
          `${process.env.REACT_APP_API_URL}api/getGoodnessFactor`,
          params
        );
        setChatStatus({
          status: "updated",
          response: response.data.response,
        });
        setShowLoading(false);
        setUserInput("");
        onGenerateComplete();
      } else {
        setShowLoading(true);
        let response = await axios.post(
          `${process.env.REACT_APP_API_URL}api/getGoodnessFactor`,
          params
        );
        setChatStatus({
          status: "updated",
          response: response.data.response,
        });
        setShowLoading(false);
        setUserInput("");
        onGenerateComplete();
      }
    },
    [userInput, chatStatus, showLoading, saving, conceptData]
  );

  const handleSave = useCallback(async () => {
    if (saving) return;
    let params: any = {
      id: conceptData.id,
      GoodnessFactor: chatStatus.response,
    };
    try {
      setSaving(true);
      let response = await axios.post(
        process.env.REACT_APP_API_URL + "api/editConcept",
        params
      );
      setSaving(false);
      setChatStatus({
        status: "initial",
        response: chatStatus.response,
      });
      if (response?.status === 200) {
        getConceptDetail();
      }
    } catch (error) {
      console.log(error);
    }
  }, [conceptData, chatStatus, saving]);

  const handleCancel = useCallback(() => {
    setChatStatus({
      status: "initial",
      response: conceptData["GoodnessFactor"],
    });
  }, [conceptData]);

  const onGenerateComplete = useCallback(() => {
    if (!visible) {
      // toast.success("Solution / Goodness Factor generate completed!", {
      //   position: "top-right",
      //   autoClose: 3000,
      //   hideProgressBar: true,
      //   closeOnClick: true,
      //   pauseOnHover: true,
      //   draggable: false,
      //   progress: 0,
      //   toastId: "my_toast",
      // });
    }
  }, [visible]);

  if (!visible) return null;

  return (
    <div className="flex flex-col gap-3 h-full">
      <div className="bg-white p-5 rounded-[4px] flex-1">
        <div className="text-[#27272A] text-base font-medium mb-2">
          Solution / Goodness Factor
        </div>
        <div
          className={`text-base font-normal text-[#000000cb] c-markdown-text overflow-x-hidden overflow-y-auto cursor-pointer ${
            showLoading || chatStatus.status === "updated"
              ? "max-h-[calc(100vh-640px)]"
              : "max-h-[calc(100vh-373px)]"
          }`}
          onClick={() => {
            if (chatStatus.status !== "updated")
              setChatStatus({
                status: "updated",
                response: conceptData["GoodnessFactor"],
              });
          }}
        >
          {conceptData["GoodnessFactor"] === "" ? (
            <div className="flex gap-2 w-full flex-col">
              <Skeleton
                height={10}
                sx={{ width: "67%", transform: "scale(1)", overflow: "hidden" }}
              />
              <Skeleton
                height={10}
                sx={{ width: "46%", transform: "scale(1)", overflow: "hidden" }}
              />
              <Skeleton
                height={10}
                sx={{ width: "37%", transform: "scale(1)", overflow: "hidden" }}
              />
              <Skeleton
                height={10}
                sx={{ width: "46%", transform: "scale(1)", overflow: "hidden" }}
              />
              <Skeleton
                height={10}
                sx={{ width: "56%", transform: "scale(1)", overflow: "hidden" }}
              />
              <Skeleton
                height={10}
                sx={{ width: "52%", transform: "scale(1)", overflow: "hidden" }}
              />
              <Skeleton
                height={10}
                sx={{ width: "43%", transform: "scale(1)", overflow: "hidden" }}
              />
              <Skeleton
                height={10}
                sx={{ width: "75%", transform: "scale(1)", overflow: "hidden" }}
              />
            </div>
          ) : (
            <CommonMarkdown>{conceptData["GoodnessFactor"]}</CommonMarkdown>
          )}
        </div>
      </div>
      <div className="bg-white p-5 rounded-[4px] flex-none">
        {showLoading ? (
          <>
            <div className="text-base font-medium text-[#71717A] mb-[10px]">
              Editing{" "}
              <span className="text-[#27272A]">Solution / Goodness Factor</span>
            </div>
            <div className="rounded-[4px] border-[#D4D4D8] border">
              <div className="">
                <div className="flex flex-col gap-2 h-[266px] p-3">
                  <Skeleton
                    height={10}
                    sx={{
                      width: "70%",
                      transform: "scale(1)",
                      overflow: "hidden",
                    }}
                  />
                  <Skeleton
                    height={10}
                    sx={{
                      width: "100%",
                      transform: "scale(1)",
                      overflow: "hidden",
                    }}
                  />
                  <Skeleton
                    height={10}
                    sx={{
                      width: "45%",
                      transform: "scale(1)",
                      overflow: "hidden",
                    }}
                  />
                  <Skeleton
                    height={10}
                    sx={{
                      width: "100%",
                      transform: "scale(1)",
                      overflow: "hidden",
                    }}
                  />
                  <Skeleton
                    height={10}
                    sx={{
                      width: "70%",
                      transform: "scale(1)",
                      overflow: "hidden",
                    }}
                  />
                  <Skeleton
                    height={10}
                    sx={{
                      width: "67%",
                      transform: "scale(1)",
                      overflow: "hidden",
                    }}
                  />
                </div>
                <div className="h-[52px] w-full py-[14px] px-4 gap-2 flex items-center border-t border-[#E4E4E7] ">
                  <input
                    type="text"
                    className="outline-none border-none bg-transparent text-sm text-[#71717A] flex-1"
                    placeholder="Chat to refine the text above"
                    value={userInput}
                    onChange={(e) => setUserInput(e.target.value)}
                    onKeyDown={(e) => {
                      if (e.keyCode === 13) {
                        handleGenerateResponse("user_input");
                      }
                    }}
                  />
                  <button
                    className="w-7 h-7 flex-none bg-[#D4D4D8] rounded-full flex items-center justify-center"
                    onClick={() => handleGenerateResponse("user_input")}
                  >
                    <ArrowRightIcon2 stroke="#ffffff" className="w-4 h-4" />
                  </button>
                </div>
              </div>
            </div>
          </>
        ) : chatStatus.status === "initial" ? (
          <>
            <div className="flex gap-2 mb-2">
              <div
                className="w-fit h-7 px-3 flex items-center rounded-full border border-[#E4E4E7] text-[#52525B] text-xs cursor-pointer"
                onClick={() => handleGenerateResponse("short")}
              >
                Shorten
              </div>
              <div
                className="w-fit h-7 px-3 flex items-center rounded-full border border-[#E4E4E7] text-[#52525B] text-xs cursor-pointer"
                onClick={() => handleGenerateResponse("elaborate")}
              >
                Elaborate
              </div>
              <div
                className="w-fit h-7 px-3 flex items-center rounded-full border border-[#E4E4E7] text-[#52525B] text-xs cursor-pointer"
                onClick={() => handleGenerateResponse("regenerate")}
              >
                Regenerate
              </div>
            </div>
            <div className="h-[52px] w-full bg-[#FCFCFC] rounded-[4px] border border-[#E4E4E7] py-[14px] px-4 gap-2 flex items-center">
              <input
                type="text"
                className="outline-none border-none bg-transparent text-sm text-[#71717A] flex-1"
                placeholder="Chat to refine the text above"
                value={userInput}
                onChange={(e) => setUserInput(e.target.value)}
                onKeyDown={(e) => {
                  if (e.keyCode === 13) {
                    handleGenerateResponse("user_input");
                  }
                }}
              />
              <button
                className="w-7 h-7 flex-none bg-[#3B82F6] rounded-full flex items-center justify-center"
                onClick={() => handleGenerateResponse("user_input")}
              >
                <ArrowRightIcon2 stroke="#ffffff" className="w-4 h-4" />
              </button>
            </div>
          </>
        ) : (
          <>
            <div className="text-base font-medium text-[#71717A] mb-[10px]">
              Editing{" "}
              <span className="text-[#27272A]">Solution / Goodness Factor</span>
            </div>
            <div className="rounded-[4px] border-[#3B82F6] border">
              <div className="">
                <textarea
                  className="px-3 py-2 h-[232px] resize-none outline-none w-full bg-transparent text-sm"
                  onChange={(e) =>
                    setChatStatus((prev) => ({
                      ...prev,
                      response: e.target.value,
                    }))
                  }
                  value={chatStatus.response}
                ></textarea>
                <div className="flex gap-4 mb-2 px-3 items-center">
                  {chatStatus.response === conceptData["GoodnessFactor"] ||
                  chatStatus.response === "" ? (
                    <span className="text-[#3b83f680] text-sm font-bold cursor-pointer underline">
                      Save
                    </span>
                  ) : (
                    <span
                      className="text-[#3B82F6] text-sm font-bold cursor-pointer underline"
                      onClick={() => handleSave()}
                    >
                      {saving ? "Saving..." : "Save"}
                    </span>
                  )}
                  <span
                    className="text-[#3B82F6] text-sm font-bold cursor-pointer underline"
                    onClick={() => handleCancel()}
                  >
                    Cancel
                  </span>
                </div>
                <div className="h-[52px] w-full border-t border-[#E4E4E7] py-[14px] px-4 gap-2 flex items-center">
                  <input
                    type="text"
                    className="outline-none border-none bg-transparent text-sm text-[#71717A] flex-1"
                    placeholder="Chat to refine the text above"
                    value={userInput}
                    onChange={(e) => setUserInput(e.target.value)}
                    onKeyDown={(e) => {
                      if (e.keyCode === 13) {
                        handleGenerateResponse("user_input");
                      }
                    }}
                  />
                  <button
                    className="w-7 h-7 flex-none bg-[#3B82F6] rounded-full flex items-center justify-center"
                    onClick={() => handleGenerateResponse("user_input")}
                  >
                    <ArrowRightIcon2 stroke="#ffffff" className="w-4 h-4" />
                  </button>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default GoodnessFactorContent;
