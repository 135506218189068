import React from 'react'

const ICPIcon: React.FC<any> = (props: any) => {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      stroke="#3B82F6"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.983 15.3462C8.11536 15.3462 4.8125 15.931 4.8125 18.2729C4.8125 20.6148 8.0944 21.2205 11.983 21.2205C15.8506 21.2205 19.1525 20.6348 19.1525 18.2938C19.1525 15.9529 15.8715 15.3462 11.983 15.3462Z"
        stroke="inherit"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.9868 12.0059C14.5249 12.0059 16.5821 9.94779 16.5821 7.40969C16.5821 4.8716 14.5249 2.81445 11.9868 2.81445C9.44875 2.81445 7.39065 4.8716 7.39065 7.40969C7.38208 9.93922 9.42589 11.9973 11.9545 12.0059H11.9868Z"
        stroke="inherit"
        strokeWidth="1.42857"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default ICPIcon
