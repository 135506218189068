import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import searchIcon from "../../assets/svg/search.svg";
import { GrLinkNext } from "react-icons/gr";
import axios from "axios";
import Folder from "./Folder";
import { useUserContext } from "../../contexts/UserContext";
import { useRagContext } from "../../contexts/RagContext";
import moment from "moment";
import { useHistory } from "react-router";
import { useLayoutContext } from "../../contexts/LayoutContext";

export interface MyCustomCSS extends React.CSSProperties {
  "--line-clamp": number;
  "--width": string;
  "--font-size": string;
  "--align": string;
}

const Sidebar: React.FC = () => {
  const [searchText, setSearchText] = useState("");
  const [shownFolderList, setShownFolderList] = useState<any[]>([]);
  const { user } = useUserContext();
  const history = useHistory();
  const {
    folderList,
    selectedFiles,
    setting,
    socketId,
    getFilePath,
    fetchFolders,
    openConceptAddFolderModal,
    getBrainstormList,
    openFileManagerModal,
    openShareFeedbackModal,
  } = useRagContext();

  const { setChatPosition } = useLayoutContext();

  useEffect(() => {
    if (searchText === "") {
      setShownFolderList(folderList);
    } else {
      const searchTextLower = searchText.toLowerCase();
      const tempFolderList = JSON.parse(JSON.stringify(folderList));

      const traverseAndFilter = (node: any) => {
        const keysToRemove: string[] = [];

        Object.keys(node).forEach((key) => {
          if (key.toLowerCase().indexOf(searchTextLower) === -1) {
            keysToRemove.push(key);
          } else if (node[key].children) {
            traverseAndFilter(node[key].children);
          }
        });

        keysToRemove.forEach((key) => {
          delete node[key];
        });
      };

      tempFolderList.forEach((folder: any) => {
        traverseAndFilter(folder.structure);
      });

      setShownFolderList(tempFolderList);
    }
  }, [folderList, searchText]);

  const handleStartOCR = async (
    id: string,
    secured_path: string,
    target_path: string,
    file_name: string
  ) => {
    try {
      const result = await axios.post(
        process.env.REACT_APP_API_URL + "api/startOCR",
        {
          folder_id: id,
          file_name: file_name,
          target_path: target_path,
          secured_path: secured_path,
          team_id: user?.organizationMemberships[0]?.organization.id,
        }
      );
      if (result.status === 200) {
        fetchFolders();
        toast.success("OCR processed successfully", {
          position: "top-right",
          autoClose: 1000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: 0,
          toastId: "my_toast",
        });
      }
    } catch (error) {}
  };

  const handleCreateNewChat = () => {
    setChatPosition(10000);
    window.postMessage(
      {
        type: "create_new_chat",
      },
      "*"
    );
  };

  const handleGetHMWQuestions = async () => {
    try {
      let fileList = selectedFiles.map((file: any) => {
        return getFilePath(file.file_name, file.folder_id);
      });
      let params: any = {
        team_id: user?.organizationMemberships[0]?.organization.id,
        file_list: fileList,
        name: "Brainstorm " + moment().format("YYYY-MM-DD h:mm a"),
        context: "",
        fromSource: true,
        socketId,
      };
      if (fileList.length > 0) {
        params.pinecone_id = fileList[0].split("/")[2];
      } else if (folderList.length > 0) {
        params.pinecone_id = folderList[0].id;
      } else {
        params.pinecone_id = "";
      }
      const result = await axios.post(
        process.env.REACT_APP_API_URL + "api/getHMWQuestions",
        params
      );

      const searchParams = new URLSearchParams();
      searchParams.set("render_type", "modal");
      searchParams.set("render_modal", "brainstorm_modal");
      searchParams.set("brainstorm_id", result.data.brainstorm_id);

      history.push({
        pathname: "/concept",
        search: `?${searchParams.toString()}`,
      });
      getBrainstormList();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="flex flex-col h-full w-[324px]">
      <div className="flex flex-col gap-4 flex-1 p-5">
        <div className="relative w-full rounded-xl bg-white px-4 py-3 flex gap-3 items-center">
          <img src={searchIcon} alt="Search" className="w-6 h-6 flex-none" />
          <input
            type="text"
            className="flex-1 text-sm leading-[22px] text-[#A1A1AA] font-medium outline-none"
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
            placeholder="Search or type a command"
          />
        </div>
        <div className="flex flex-col justify-between h-[calc(100vh-210px)]">
          <div className="flex-1 overflow-y-auto overflow-x-hidden block">
            <div className="">
              <div className="flex items-center justify-between">
                <div className="py-1 px-[10px] text-sm leading-[22px] font-bold text-[#00000067]">
                  {shownFolderList.length || ""} Folders
                </div>
                <button
                  className="p-0 outline-none border-none bg-transparent text-[#2563EB] text-sm leading-[22px] font-bold"
                  onClick={() => openFileManagerModal()}
                  disabled={!setting?.verified}
                >
                  Manage
                </button>
              </div>
              <div
                className={`overflow-x-auto overflow-y-auto w-full ${
                  selectedFiles.length
                    ? "h-[calc(100vh-472px)]"
                    : "h-[calc(100vh-298px)]"
                }`}
                id="folder-container"
              >
                {shownFolderList.map((folder, index) => {
                  return folder.isFolderDeleted === 1 ? (
                    <div key={index}></div>
                  ) : (
                    <Folder
                      key={index}
                      folder={folder}
                      handleStartOCR={handleStartOCR}
                    ></Folder>
                  );
                })}
              </div>
              <div
                className={`flex-col gap-[10px] mt-[10px] ${
                  selectedFiles.length ? "flex" : "hidden"
                }`}
              >
                <button
                  className="w-full h-12 rounded-xl bg-[#3B82F6] outline-none flex items-center justify-center gap-2"
                  onClick={() => openConceptAddFolderModal("selected_source")}
                >
                  <span className="text-white text-base font-bold font-Satoshi">
                    Create New Concept
                  </span>
                  <GrLinkNext className="text-white text-base font-bold font-Satoshi" />
                </button>
                <button
                  className="w-full h-12 rounded-xl bg-[#3B82F6] outline-none flex items-center justify-center gap-2"
                  onClick={() => handleCreateNewChat()}
                >
                  <span className="text-white text-base font-bold font-Satoshi">
                    Create New Chat
                  </span>
                  <GrLinkNext className="text-white text-base font-bold font-Satoshi" />
                </button>
                <button
                  className="w-full h-12 rounded-xl bg-[#3B82F6] outline-none flex items-center justify-center gap-2"
                  onClick={() => handleGetHMWQuestions()}
                >
                  <span className="text-white text-base font-bold font-Satoshi">
                    Start Brainstorm
                  </span>
                  <GrLinkNext className="text-white text-base font-bold font-Satoshi" />
                </button>
              </div>
            </div>
          </div>

          <button
            className="w-full h-12 rounded-xl border border-solid border-[#3B82F6] outline-none flex items-center justify-center gap-2"
            onClick={() => openShareFeedbackModal("Chat")}
          >
            <span className="text-[#3B82F6] text-base font-bold font-Satoshi">
              Share Feedback
            </span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
