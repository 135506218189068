import React, { useEffect, useRef, useState } from "react";
import { useRagContext } from "../../../contexts/RagContext";
import GridIcon from "../../icons/GridIcon";
import ListIcon from "../../icons/ListIcon";
import FilterIcon from "../../icons/FilterIcon";
import HambugerIcon from "../../icons/HambugerIcon";
import FileManagerEmptyFileIcon from "../../../assets/svg/file-mananger-empty-file.svg";
import { GoPlus } from "react-icons/go";
import RenameFolderModal from "./RenameFolderModal/RenameFolderModal";
import AddFolderModal from "./AddFolderModal/AddFolderModal";
import { IoCloseOutline } from "react-icons/io5";
import FolderIcon from "../../../assets/svg/folder.svg";
import FilePDFIcon from "../../../assets/svg/file-pdf.svg";
import FileDOCIcon from "../../../assets/svg/file-doc.svg";
import FilePPTIcon from "../../../assets/svg/file-ppt.svg";
import FileXLSXIcon from "../../../assets/svg/file-xlsx.svg";
import ContentIcon from "../../../assets/svg/content-icon.svg";
import ThreeDotsIcon from "../../icons/ThreeDotsIcon";
import BpCheckbox from "../../common/BPCheckbox";
import { Menu, MenuItem, MenuProps, styled } from "@mui/material";
import TrashIcon from "../../icons/TrashIcon";

import EditIcon from "../../icons/EditIcon";
import { getExtension } from "../../../utils";
import axios from "axios";
import { useUserContext } from "../../../contexts/UserContext";
import { MdFileUpload, MdOutlineDriveFolderUpload } from "react-icons/md";
import UploadFileModal from "./UploadFileModal/UploadFileModal";
import { useLayoutContext } from "../../../contexts/LayoutContext";
import DeleteModal from "./DeleteModal/DeleteModal";

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    transformOrigin={{ horizontal: "right", vertical: "top" }}
    anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 14,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "6px",
    },
    "& .MuiMenuItem-root": {
      padding: "10px 12px",
      gap: "12px",
      width: "224px",
      borderRadius: "12px",
    },
  },
}));

const fileIcon: any = {
  pdf: FilePDFIcon,
  doc: FileDOCIcon,
  docx: FileDOCIcon,
  ppt: FilePPTIcon,
  pptx: FilePPTIcon,
  xlsx: FileXLSXIcon,
  xls: FileXLSXIcon,
};

const fileTypeOrder = ["pdf", "docx", "pptx", "xlsx"];

const FileManagerModal: React.FC = () => {
  const {
    fileManagerModalVisible,
    closeFileManagerModal,
    folderList,
    setting,
    socketId,
    // socket,
    selectedUploadFiles,
    setSelectedUploadFiles,
    fetchFolders,
  } = useRagContext();
  const { setBreadCrumbs } = useLayoutContext();
  // for dev ui
  const [listType, setListType] = useState<"grid" | "list">("grid");
  const [shownFolderList, setShownFolderList] = useState<any>(folderList);
  const [parentFolders, setParentFolders] = useState<any[]>([]);
  const [isFolderUpload, setFolderUpload] = useState(false);
  // const [uploadFolderStructure, setUploadFolderStructure] = useState<any>(null)
  // const [isUploading, setUploading] = useState(false)
  const fileUploadRef = useRef<HTMLInputElement>(null);
  const folderUploadRef = useRef<HTMLInputElement>(null);

  const { user } = useUserContext();

  const [folderMenuAnchorEl, setFolderMenuAnchorEl] =
    React.useState<null | HTMLElement>(null);
  const folderMenuOpen = Boolean(folderMenuAnchorEl);

  const [filterMenuAnchorEl, setFilterMenuAnchorEl] =
    React.useState<null | HTMLElement>(null);
  const filterMenuOpen = Boolean(filterMenuAnchorEl);

  const [sortMenuAnchorEl, setSortMenuAnchorEl] =
    React.useState<null | HTMLElement>(null);
  const sortMenuOpen = Boolean(sortMenuAnchorEl);

  const [uploadMenuAnchorEl, setUploadMenuAnchorEl] =
    React.useState<null | HTMLElement>(null);
  const uploadMenuOpen = Boolean(uploadMenuAnchorEl);

  const [deleteModalOpen, setDeleteModalOpen] = React.useState<boolean>(false);

  const [renameFolderModalOpen, setRenameFolderModalOpen] =
    React.useState<boolean>(false);
  const [isSelectFolder, setIsSelectFolder] = useState(false);
  const [selectedFolder, setSelectedFolder] = useState<any>(null);
  const [selectedFile, setSelectedFile] = useState<any>(null);

  const [addFolderModalOpen, setAddFolderModalOpen] =
    React.useState<boolean>(false);

  const [filterType, setFilterType] = useState("all");
  const [sortType, setSortType] = useState("");

  useEffect(() => {
    if (folderList) {
      setCurrentFolderState();
    }
  }, [folderList, parentFolders, sortType, filterType]);

  useEffect(() => {
    if (selectedUploadFiles) {
      handleAddDocument();
    }
  }, [selectedUploadFiles]);

  const setCurrentFolderState = () => {
    let resultFolderList = JSON.parse(JSON.stringify(folderList));
    parentFolders.forEach((folderList, index) => {
      if (index === 0) {
        let topFolder = resultFolderList.find(
          (folder: any) => folder.id === folderList.id
        );
        resultFolderList =
          topFolder.structure[Object.keys(topFolder.structure)[0]];
      } else {
        resultFolderList = resultFolderList.children[folderList.folder_name];
      }
    });

    if (parentFolders.length > 0) {
      const sortedKeys = Object.keys(resultFolderList.children).sort(
        (a: any, b: any) => {
          if (sortType === "ascending") {
            if (resultFolderList.children[a].isFolder) {
              return -1;
            }
            return a.localeCompare(b);
          } else if (sortType === "descending") {
            if (resultFolderList.children[a].isFolder) {
              return 1;
            }
            return b.localeCompare(a);
          } else {
            if (resultFolderList.children[a].isFolder) {
              return sortType === "typeasc" ? -1 : 1;
            } else {
              let aIndex = fileTypeOrder.findIndex(
                (order) => order === getExtension(a)
              );
              let bIndex = fileTypeOrder.findIndex(
                (order) => order === getExtension(b)
              );
              if (sortType === "typeasc") {
                return aIndex > bIndex ? 1 : -1;
              } else {
                return aIndex > bIndex ? -1 : 1;
              }
            }
          }
        }
      );

      const sortedObj: any = {};
      sortedKeys.forEach((key) => {
        if (
          !resultFolderList.children[key].isFolder &&
          filterType !== "all" &&
          getExtension(key) !== filterType
        ) {
        } else {
          sortedObj[key] = resultFolderList.children[key];
        }
      });

      resultFolderList.children = sortedObj;
    } else {
      if (sortType === "ascending") {
        resultFolderList.sort((a: any, b: any) =>
          Object.keys(a.structure)[0].localeCompare(Object.keys(b.structure)[0])
        );
      } else if (sortType === "descending") {
        resultFolderList.sort((a: any, b: any) =>
          Object.keys(b.structure)[0].localeCompare(Object.keys(a.structure)[0])
        );
      }
    }

    setShownFolderList(resultFolderList);
  };

  const handleFolderMenuClose = () => {
    setFolderMenuAnchorEl(null);
  };

  const openTopFolder = (folder: any) => {
    setParentFolders([
      {
        id: folder.id,
        folder_name: Object.keys(folder.structure)[0],
        folder_id: folder.structure[Object.keys(folder.structure)[0]].id,
      },
    ]);
  };

  const openFolder = (fodler_name: any, folder_id: any) => {
    setParentFolders((prev) => [
      ...prev,
      {
        id: folder_id,
        folder_name: fodler_name,
      },
    ]);
  };

  const handleFilterMenuClose = () => {
    setFilterMenuAnchorEl(null);
  };

  const handleSortMenuClose = () => {
    setSortMenuAnchorEl(null);
  };

  const handleUploadMenuClose = () => {
    setUploadMenuAnchorEl(null);
  };

  const handleFileUpload = (
    event: React.ChangeEvent<HTMLInputElement>,
    isFolder: boolean
  ) => {
    setUploadMenuAnchorEl(null);
    if (event.target.files) {
      const filesArray = Array.from(event.target.files);
      const allowedExtensions = /\.(pdf|docx|pptx|xlsx)$/i; // Allow PDF, DOCX, PPTX, and XLSX files
      const uploaded: any = [];
      const folderStructure: any = {};

      filesArray.forEach((file) => {
        if (allowedExtensions.exec(file.name)) {
          uploaded.push(file);

          // Construct folder structure
          if (isFolder) {
            const pathParts = file.webkitRelativePath.split("/");
            let currentLevel = folderStructure;

            pathParts.forEach((part, index) => {
              if (index === pathParts.length - 1) {
                if (!currentLevel.files) {
                  currentLevel.files = [];
                }
                currentLevel.files.push(file);
              } else {
                if (!currentLevel[part]) {
                  currentLevel[part] = {};
                }
                currentLevel = currentLevel[part];
              }
            });
          }
        }
      });

      if (isFolder) {
        // setUploadFolderStructure(folderStructure)
      }
      setSelectedUploadFiles(uploaded);
    }
  };

  const handleAddDocument = async () => {
    if (selectedUploadFiles?.length === 0) {
      return;
    }

    const target_path = parentFolders.map((e: any) => e.folder_name).join("/");
    const teamId = user?.organizationMemberships[0]?.organization.id || "";

    const uploadBatch = async (filesBatch: any, index: any) => {
      const batchData = new FormData();
      batchData.append("folder_id", parentFolders[0].id);
      batchData.append("team_id", teamId);
      batchData.append("target_path", "/" + target_path);
      batchData.append("socketId", socketId);
      batchData.append("batchCount", index);
      for (let i = 0; i < filesBatch.length; i++) {
        isFolderUpload
          ? batchData.append(
              "file",
              filesBatch[i],
              filesBatch[i].webkitRelativePath
            )
          : batchData.append("file", filesBatch[i]);
      }

      try {
        await axios.post(
          process.env.REACT_APP_API_URL + "api/uploadDocument",
          batchData
        );
      } catch (error) {
        console.log(error);
      }
    };

    const chunkSize = 5;
    // Convert FileList to Array
    if (selectedUploadFiles) {
      const filesArray = Array.from(selectedUploadFiles);
      for (let i = 0; i < filesArray.length; i += chunkSize) {
        const chunk = filesArray.slice(i, i + chunkSize);
        await uploadBatch(chunk, i);
      }
      // Call dbSaveSuccess after all batches are uploaded
      if (fileUploadRef.current) fileUploadRef.current.value = "";
      if (folderUploadRef.current) folderUploadRef.current.value = "";
      fetchFolders();
    }

    // setUploadFolderStructure(null)
    // setUploading(false)
  };

  useEffect(() => {
    if (fileManagerModalVisible) {
      setBreadCrumbs([
        {
          title: "Research",
          active: false,
          onClick: () => {
            closeFileManagerModal();
          },
        },
        {
          title: "File Manager",
          active: parentFolders.length === 0,
          onClick: () => {
            setParentFolders([]);
          },
        },
        ...parentFolders.map((folder, index) => {
          return {
            title: folder.folder_name,
            active: parentFolders.length - 1 === index,
            onClick: () => {
              if (parentFolders.length - 1 !== index) {
                setParentFolders((prev) => prev.slice(0, index + 1));
              }
            },
          };
        }),
      ]);
    } else {
      setBreadCrumbs([
        {
          title: "Research",
          active: true,
          onClick: () => {},
        },
      ]);
    }
  }, [fileManagerModalVisible, parentFolders]);

  return (
    <div
      className="w-full h-full absolute top-0 left-0 z-[1000] bg-white"
      hidden={!fileManagerModalVisible}
    >
      <div className="px-[30px] xl:px-10 pt-3 xl:pt-4 pb-[18px] xl:pb-6 flex justify-between">
        <div className="flex justify-between items-center w-full">
          <div className="">
            <div className="text-[15px] xl:text-xl font-bold text-[#27272A]">
              File Manager
            </div>
            <div className="items-center gap-[6px] xl:gap-2 hidden">
              <IoCloseOutline className="w-[18px] xl:w-6 h-[18px] xl:h-6 text-[#09090B]" />
              <div className="text-[15px] xl:text-xl font-bold text-[#27272A]">
                1 Items Selected
              </div>
            </div>
          </div>
          <div className="flex items-center gap-[3px] xl:gap-1">
            <div className={`file-mananger-list-type ${listType}-type`}>
              <div
                className="file-mananger-list-type-grid"
                onClick={() => setListType("grid")}
              >
                <GridIcon className="w-[18px] xl:w-6 h-[18px] xl:h-6" />
              </div>
              <div
                className="file-mananger-list-type-list"
                onClick={() => setListType("list")}
              >
                <ListIcon className="w-[18px] xl:w-6 h-[18px] xl:h-6" />
              </div>
              <div className="file-mananger-list-type-bg"></div>
            </div>
            <button
              className="outline-none w-[30px] xl:w-10 h-[30px] xl:h-10 border border-[#D4D4D8] items-center justify-center rounded-lg flex"
              onClick={(e) => setFilterMenuAnchorEl(e.currentTarget)}
            >
              <FilterIcon className="w-[15px] xl:w-5 h-[15px] xl:h-5" />
            </button>
            <button
              className="outline-none w-[30px] xl:w-10 h-[30px] xl:h-10 border border-[#D4D4D8] items-center justify-center rounded-lg flex"
              onClick={(e) => setSortMenuAnchorEl(e.currentTarget)}
            >
              <HambugerIcon className="w-[15px] xl:w-5 h-[15px] xl:h-5" />
            </button>
          </div>
        </div>
      </div>
      <div className="px-[30px] xl:px-10 relative h-[calc(100vh-130px)] xl:h-[calc(100vh-174px)]">
        {folderList.length === 0 ? (
          <div className="py-[111px] xl:py-[148px]" hidden>
            <div className="flex flex-col items-center">
              <img src={FileManagerEmptyFileIcon} alt="" />
              <div className="mb-[6px] xl:mb-2 text-[15px] xl:text-xl text-[#27272A] font-bold">
                No Items Yet!
              </div>
              <div className="text-xs xl:text-base text-[#71717A] font-normal mb-[30px] xl:mb-10">
                Looks like haven’t added a items. Add some items here.
              </div>
              <button
                className="h-[21px] xl:h-12 rounded-xl bg-[#3B82F6] outline-none flex items-center justify-center gap-[6px] xl:gap-2 w-[165px] xl:w-[220px]"
                onClick={() => setAddFolderModalOpen(true)}
              >
                <GoPlus className="w-[18px] xl:w-6 h-[18px] xl:h-6 text-white" />
                <span className="text-white text-xs xl:text-base font-bold font-Satoshi">
                  Create New Folder
                </span>
              </button>
            </div>
          </div>
        ) : (
          <>
            {listType === "grid" ? (
              <div className="flex flex-wrap gap-3 xl:gap-4 h-[calc(100vh-156px)] xl:h-[calc(100vh-209px)] overflow-auto content-baseline">
                {parentFolders.length === 0 ? (
                  <>
                    {shownFolderList.length !== undefined &&
                      shownFolderList.map((folder: any, index: number) => (
                        <div
                          className="w-[160px] xl:w-[213px] h-[140px] xl:h-[186px] rounded-xl border border-solid border-[#E4E4E7] relative flex items-center justify-center cursor-pointer group"
                          key={index}
                          onClick={() => openTopFolder(folder)}
                        >
                          <img
                            src={FolderIcon}
                            alt=""
                            className="-translate-y-[7px] xl:-translate-y-[10px] w-[55px] xl:w-[74px] h-[55px] xl:h-[74px]"
                          />
                          <div className="absolute w-full left-0 bottom-0 py-[6px] xl:py-2 pl-3 xl:pl-4 pr-[9px] xl:pr-3 flex gap-[3px] xl:gap-1 justify-between">
                            <div className="w-[calc(100%-21px)] xl:w-[calc(100%-28px)]">
                              <div className="text-[10px] xl:text-sm leading-[15px] xl:leading-[20px] text-[#09090B] font-semibold mb-[1.5px] xl:mb-[2px] w-full whitespace-nowrap overflow-hidden text-ellipsis">
                                {Object.keys(folder.structure)[0]}
                              </div>
                              <div className="text-[9px] xl:text-xs leading-[15px] xl:leading-[20px] text-[#71717A] font-normal">
                                {
                                  Object.keys(
                                    folder.structure[
                                      Object.keys(folder.structure)[0]
                                    ].children
                                  ).length
                                }{" "}
                                Items
                              </div>
                            </div>
                            <button
                              className="border-none outline-none flex-none w-[18px] xl:w-6 h-[18px] xl:h-6"
                              onClick={(e) => {
                                setIsSelectFolder(true);
                                setSelectedFolder({
                                  ...folder.structure[
                                    Object.keys(folder.structure)[0]
                                  ],
                                  folder_name: Object.keys(folder.structure)[0],
                                  namespace_id: folder.id,
                                });
                                e.stopPropagation();
                                setFolderMenuAnchorEl(e.currentTarget);
                              }}
                            >
                              <ThreeDotsIcon className="w-[18px] xl:w-6 h-[18px] xl:h-6" />
                            </button>
                          </div>
                          <BpCheckbox
                            size="small"
                            className={`w-[14px] xl:w-[18px] h-[14px] xl:h-[18px] !absolute top-[11px] xl:top-[15px] right-[11px] xl:right-[15px] !hidden`}
                            checked={true}
                          ></BpCheckbox>
                        </div>
                      ))}
                  </>
                ) : (
                  <>
                    {shownFolderList.children &&
                      Object.keys(shownFolderList.children).map(
                        (key, index) => {
                          // Check if the item contains a URL and render a clickable link
                          if (shownFolderList.children[key].url) {
                            const item = shownFolderList.children[key];
                            return (
                              <div
                                className="w-[160px] xl:w-[213px] h-[140px] xl:h-[186px] rounded-xl border border-solid border-[#E4E4E7] relative flex items-center justify-center cursor-pointer group"
                                key={index}
                              >
                                <a
                                  href={item.url}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  className="text-blue-500 underline"
                                >
                                  {key}
                                </a>
                                <button
                                  className="border-none outline-none flex-none w-[18px] xl:w-6 h-[18px] xl:h-6"
                                  onClick={(e) => {
                                    if (
                                      shownFolderList.children[key].isFolder
                                    ) {
                                      setSelectedFolder({
                                        ...shownFolderList.children[key],
                                        folder_name: key,
                                      });
                                    } else {
                                      setSelectedFile(key);
                                    }
                                    setIsSelectFolder(
                                      shownFolderList.children[key].isFolder
                                    );
                                    e.stopPropagation();
                                    setFolderMenuAnchorEl(e.currentTarget);
                                  }}
                                >
                                  <ThreeDotsIcon
                                    stroke="#18181B"
                                    className="w-[18px] xl:w-6 h-[18px] xl:h-6"
                                  />
                                </button>
                              </div>
                            );
                          }
                          if (shownFolderList.children[key].isFolder) {
                            return (
                              <div
                                className="w-[160px] xl:w-[213px] h-[140px] xl:h-[186px] rounded-xl border border-solid border-[#E4E4E7] relative flex items-center justify-center cursor-pointer group"
                                key={index}
                                onClick={() =>
                                  openFolder(
                                    key,
                                    shownFolderList.children[key].id
                                  )
                                }
                              >
                                <img
                                  src={FolderIcon}
                                  alt=""
                                  className="-translate-y-[7px] xl:-translate-y-[10px] w-[55px] xl:w-[74px] h-[55px] xl:h-[74px]"
                                />
                                <div className="absolute w-full left-0 bottom-0 py-[6px] xl:py-2 pl-3 xl:pl-4 pr-[9px] xl:pr-3 flex gap-[3px] xl:gap-1 justify-between">
                                  <div className="w-[calc(100%-21px)] xl:w-[calc(100%-28px)]">
                                    <div className="text-[10px] xl:text-sm leading-[15px] xl:leading-[20px] text-[#09090B] font-semibold mb-[1.5px] xl:mb-[2px] w-full whitespace-nowrap overflow-hidden text-ellipsis">
                                      {key}
                                    </div>
                                    <div className="text-[9px] xl:text-xs leading-[15px] xl:leading-[20px] text-[#71717A] font-normal">
                                      {
                                        Object.keys(
                                          shownFolderList.children[key].children
                                        ).length
                                      }{" "}
                                      Items
                                    </div>
                                  </div>
                                  <button
                                    className="border-none outline-none flex-none w-[18px] xl:w-6 h-[18px] xl:h-6"
                                    onClick={(e) => {
                                      setIsSelectFolder(true);
                                      setSelectedFolder({
                                        ...shownFolderList.children[key],
                                        folder_name: key,
                                      });
                                      e.stopPropagation();
                                      setFolderMenuAnchorEl(e.currentTarget);
                                    }}
                                  >
                                    <ThreeDotsIcon className="w-[18px] xl:w-6 h-[18px] xl:h-6" />
                                  </button>
                                </div>
                                <BpCheckbox
                                  size="small"
                                  className={`w-[14px] xl:w-[18px] h-[14px] xl:h-[18px] !absolute top-[11px] xl:top-[15px] right-[11px] xl:right-[15px] !hidden`}
                                  checked={true}
                                ></BpCheckbox>
                              </div>
                            );
                          } else {
                            return (
                              <div
                                className="w-[160px] xl:w-[213px] h-[140px] xl:h-[186px] rounded-xl border border-solid border-[#E4E4E7] relative flex items-center justify-center cursor-pointer group"
                                key={index}
                              >
                                <img
                                  src={fileIcon[getExtension(key)]}
                                  alt=""
                                  className="-translate-y-[7px] xl:-translate-y-[10px] w-[55px] xl:w-[74px] h-[55px] xl:h-[74px]"
                                />
                                <div className="absolute w-full left-0 bottom-0 py-[6px] xl:py-2 pl-3 xl:pl-4 pr-[9px] xl:pr-3 flex gap-[3px] xl:gap-1 justify-between">
                                  <div className="w-[calc(100%-21px)] xl:w-[calc(100%-28px)]">
                                    <div className="text-[10px] xl:text-sm leading-[15px] xl:leading-[20px] text-[#09090B] font-semibold mb-[1.5px] xl:mb-[2px] w-full whitespace-nowrap overflow-hidden text-ellipsis">
                                      {key}
                                    </div>
                                    <div className="text-[9px] xl:text-xs leading-[15px] xl:leading-[20px] text-[#71717A] font-normal">
                                      5.6mb
                                    </div>
                                  </div>
                                  <button
                                    className="border-none outline-none flex-none w-[18px] xl:w-6 h-[18px] xl:h-6"
                                    onClick={(e) => {
                                      setIsSelectFolder(false);
                                      setSelectedFile(key);
                                      e.stopPropagation();
                                      setFolderMenuAnchorEl(e.currentTarget);
                                    }}
                                  >
                                    <ThreeDotsIcon className="w-[18px] xl:w-6 h-[18px] xl:h-6" />
                                  </button>
                                </div>
                                <BpCheckbox
                                  size="small"
                                  className={`w-[14px] xl:w-[18px] h-[14px] xl:h-[18px] !absolute top-[11px] xl:top-[15px] right-[11px] xl:right-[15px] !hidden`}
                                  checked={true}
                                ></BpCheckbox>
                              </div>
                            );
                          }
                        }
                      )}
                  </>
                )}
              </div>
            ) : (
              <div className="relative rounded-2xl overflow-auto h-[calc(100vh-199px)] xl:h-[calc(100vh-265px)]">
                <div className="shadow-sm overflow-hidden">
                  <table className="border-collapse table-auto w-full text-[10px] xl:text-sm">
                    <colgroup>
                      <col className="w-[54px] xl:w-[72px]" />
                      <col className="w-auto" />
                      <col className="w-[184px] xl:w-[245px]" />
                      <col className="w-[184px] xl:w-[245px]" />
                      <col className="w-[75px] xl:w-[100px]" />
                    </colgroup>
                    <thead className="bg-[#DBEAFE]">
                      <tr>
                        <th className="border-b font-medium p-[18px] xl:p-6 py-[9px] xl:py-3 text-[#09090B] text-[10px] xl:text-sm leading-[16px] xl:leading-[22px] text-left">
                          <div className="">
                            <BpCheckbox
                              size="small"
                              className={`w-[13px] xl:w-[18px] h-[14px] xl:h-[18px]`}
                            ></BpCheckbox>
                          </div>
                        </th>
                        <th className="border-b font-bold p-[18px] xl:p-6 py-[9px] xl:py-3 text-[#09090B] text-[10px] xl:text-sm leading-[16px] xl:leading-[22px] text-left">
                          Folder Name
                        </th>
                        <th className="border-b font-bold p-[18px] xl:p-6 py-[9px] xl:py-3 text-[#09090B] text-[10px] xl:text-sm leading-[16px] xl:leading-[22px] text-center">
                          Type
                        </th>
                        <th className="border-b font-bold p-[18px] xl:p-6 py-[9px] xl:py-3 text-[#09090B] text-[10px] xl:text-sm leading-[16px] xl:leading-[22px] text-center">
                          Items
                        </th>
                        <th className="border-b font-bold p-[18px] xl:p-6 py-[9px] xl:py-3 text-[#09090B] text-[10px] xl:text-sm leading-[16px] xl:leading-[22px] text-center">
                          Action
                        </th>
                      </tr>
                    </thead>
                    <tbody className="bg-white">
                      {parentFolders.length === 0 ? (
                        <>
                          {shownFolderList.length !== undefined &&
                            shownFolderList.map(
                              (folder: any, index: number) => (
                                <tr
                                  key={index}
                                  className="cursor-pointer"
                                  onClick={() => openTopFolder(folder)}
                                >
                                  <td className="border-b font-medium p-[18px] xl:p-6 py-[9px] xl:py-3 text-[#09090B] text-[10px] xl:text-sm leading-[16px] xl:leading-[22px] text-left">
                                    <div className="">
                                      <BpCheckbox
                                        size="small"
                                        className={`w-[13px] xl:w-[18px] h-[14px] xl:h-[18px]`}
                                      ></BpCheckbox>
                                    </div>
                                  </td>
                                  <td className="border-b font-medium p-[18px] xl:p-6 py-[9px] xl:py-3 text-[#09090B] text-[10px] xl:text-sm leading-[16px] xl:leading-[22px] text-left">
                                    <div className="flex items-center gap-[3px] xl:gap-1">
                                      <img
                                        src={FolderIcon}
                                        alt=""
                                        className="w-[18px] xl:w-6 h-[18px] xl:h-6 -translate-y-[1.5px] xl:-translate-y-[2px]"
                                      />
                                      <span>
                                        {Object.keys(folder.structure)[0]}
                                      </span>
                                    </div>
                                  </td>
                                  <td className="border-b font-medium p-[18px] xl:p-6 py-[9px] xl:py-3 text-[#09090B] text-[10px] xl:text-sm leading-[16px] xl:leading-[22px] text-center">
                                    Folder
                                  </td>
                                  <td className="border-b font-medium p-[18px] xl:p-6 py-[9px] xl:py-3 text-[#09090B] text-[10px] xl:text-sm leading-[16px] xl:leading-[22px] text-center">
                                    {
                                      Object.keys(
                                        folder.structure[
                                          Object.keys(folder.structure)[0]
                                        ].children
                                      ).length
                                    }{" "}
                                    Items
                                  </td>
                                  <td className="border-b font-medium p-[18px] xl:p-6 py-[9px] xl:py-3 text-[#09090B] text-[10px] xl:text-sm leading-[16px] xl:leading-[22px] text-center">
                                    <button
                                      className="border-none outline-none flex-none w-[18px] xl:w-6 h-[18px] xl:h-6"
                                      onClick={(e) => {
                                        setIsSelectFolder(true);
                                        setSelectedFolder({
                                          ...folder.structure[
                                            Object.keys(folder.structure)[0]
                                          ],
                                          folder_name: Object.keys(
                                            folder.structure
                                          )[0],
                                          namespace_id: folder.id,
                                        });
                                        e.stopPropagation();
                                        setFolderMenuAnchorEl(e.currentTarget);
                                      }}
                                    >
                                      <ThreeDotsIcon className="w-[18px] xl:w-6 h-[18px] xl:h-6" />
                                    </button>
                                  </td>
                                </tr>
                              )
                            )}
                        </>
                      ) : (
                        <>
                          {shownFolderList.children &&
                            Object.keys(shownFolderList.children).map(
                              (key, index) => {
                                const item = shownFolderList.children[key];

                                // Render clickable URL if present
                                if (item.url) {
                                  return (
                                    <tr key={index} className="cursor-pointer">
                                      <td className="border-b font-medium p-[18px] xl:p-6 py-[9px] xl:py-3 text-[#09090B] text-[10px] xl:text-sm leading-[16px] xl:leading-[22px] text-left">
                                        <div className="">
                                          <BpCheckbox
                                            size="small"
                                            className={`w-[13px] xl:w-[18px] h-[13px] xl:h-[18px]`}
                                          ></BpCheckbox>
                                        </div>
                                      </td>
                                      <td className="border-b font-medium p-[18px] xl:p-6 py-[9px] xl:py-3 text-[#09090B] text-[10px] xl:text-sm leading-[16px] xl:leading-[22px] text-left">
                                        <div className="flex items-center gap-[3px] xl:gap-1">
                                          <a
                                            href={item.url}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className="text-blue-500 underline"
                                          >
                                            {key}
                                          </a>
                                        </div>
                                      </td>
                                      <td className="border-b font-medium p-[18px] xl:p-6 py-[9px] xl:py-3 text-[#09090B] text-[10px] xl:text-sm leading-[16px] xl:leading-[22px] text-center">
                                        URL
                                      </td>
                                      <td className="border-b font-medium p-[18px] xl:p-6 py-[9px] xl:py-3 text-[#09090B] text-[10px] xl:text-sm leading-[16px] xl:leading-[22px] text-center"></td>
                                      <td className="border-b font-medium p-[18px] xl:p-6 py-[9px] xl:py-3 text-[#09090B] text-[10px] xl:text-sm leading-[16px] xl:leading-[22px] text-center">
                                        <button
                                          className="border-none outline-none flex-none w-[18px] xl:w-6 h-[18px] xl:h-6"
                                          onClick={(e) => {
                                            setIsSelectFolder(false);
                                            setSelectedFile(key);
                                            e.stopPropagation();
                                            setFolderMenuAnchorEl(
                                              e.currentTarget
                                            );
                                          }}
                                        >
                                          <ThreeDotsIcon className="w-[18px] xl:w-6 h-[18px] xl:h-6" />
                                        </button>
                                      </td>
                                    </tr>
                                  );
                                }
                                return (
                                  <tr
                                    key={index}
                                    className="cursor-pointer"
                                    onClick={() => {
                                      if (
                                        shownFolderList.children[key].isFolder
                                      )
                                        openFolder(
                                          key,
                                          shownFolderList.children[key].id
                                        );
                                    }}
                                  >
                                    <td className="border-b font-medium p-[18px] xl:p-6 py-[9px] xl:py-3 text-[#09090B] text-[10px] xl:text-sm leading-[16px] xl:leading-[22px] text-left">
                                      <div className="">
                                        <BpCheckbox
                                          size="small"
                                          className={`w-[13px] xl:w-[18px] h-[14px] xl:h-[18px]`}
                                        ></BpCheckbox>
                                      </div>
                                    </td>
                                    <td className="border-b font-medium p-[18px] xl:p-6 py-[9px] xl:py-3 text-[#09090B] text-[10px] xl:text-sm leading-[16px] xl:leading-[22px] text-left">
                                      <div className="flex items-center gap-[3px] xl:gap-1">
                                        {shownFolderList.children[key]
                                          .isFolder ? (
                                          <img
                                            src={FolderIcon}
                                            alt=""
                                            className="w-[18px] xl:w-6 h-[18px] xl:h-6 -translate-y-[1.5px] xl:-translate-y-[2px]"
                                          />
                                        ) : (
                                          <img
                                            src={fileIcon[getExtension(key)]}
                                            alt=""
                                            className="w-[18px] xl:w-6 h-[18px] xl:h-6 -translate-y-[1.5px] xl:-translate-y-[2px]"
                                          />
                                        )}
                                        <span>{key}</span>
                                      </div>
                                    </td>
                                    <td className="border-b font-medium p-[18px] xl:p-6 py-[9px] xl:py-3 text-[#09090B] text-[10px] xl:text-sm leading-[16px] xl:leading-[22px] text-center">
                                      {shownFolderList.children[key].isFolder
                                        ? "Folder"
                                        : "File"}
                                    </td>
                                    <td className="border-b font-medium p-[18px] xl:p-6 py-[9px] xl:py-3 text-[#09090B] text-[10px] xl:text-sm leading-[16px] xl:leading-[22px] text-center">
                                      {shownFolderList.children[key].isFolder
                                        ? `${
                                            Object.keys(
                                              shownFolderList.children[key]
                                                .children
                                            ).length
                                          } Items`
                                        : ""}
                                    </td>
                                    <td className="border-b font-medium p-[18px] xl:p-6 py-[9px] xl:py-3 text-[#09090B] text-[10px] xl:text-sm leading-[16px] xl:leading-[22px] text-center">
                                      <button
                                        className="border-none outline-none flex-none w-[18px] xl:w-6 h-[18px] xl:h-6"
                                        onClick={(e) => {
                                          if (
                                            shownFolderList.children[key]
                                              .isFolder
                                          ) {
                                            setSelectedFolder({
                                              ...shownFolderList.children[key],
                                              folder_name: key,
                                            });
                                          } else {
                                            setSelectedFile(key);
                                          }
                                          setIsSelectFolder(
                                            shownFolderList.children[key]
                                              .isFolder
                                          );
                                          e.stopPropagation();
                                          setFolderMenuAnchorEl(
                                            e.currentTarget
                                          );
                                        }}
                                      >
                                        <ThreeDotsIcon className="w-[18px] xl:w-6 h-[18px] xl:h-6" />
                                      </button>
                                    </td>
                                  </tr>
                                );
                              }
                            )}
                        </>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            )}
          </>
        )}
        {selectedUploadFiles.length === 0 && (
          <button
            className="w-9 xl:w-12 h-9 xl:h-12 border-none outline-none flex items-center justify-center bg-[#3B82F6] rounded-lg absolute bottom-10 right-10"
            onClick={(e) => setUploadMenuAnchorEl(e.currentTarget)}
          >
            <GoPlus className="w-[18px] xl:w-6 h-[18px] xl:h-6 text-white" />
          </button>
        )}
      </div>

      <StyledMenu
        id="folder-menu"
        anchorEl={folderMenuAnchorEl}
        open={folderMenuOpen}
        onClose={handleFolderMenuClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        {isSelectFolder && (
          <MenuItem
            onClick={() => {
              setFolderMenuAnchorEl(null);
              setRenameFolderModalOpen(true);
            }}
            disableRipple
          >
            <EditIcon className="w-[18px] xl:w-6 h-[18px] xl:h-6" />
            <span className="text-[#3F3F46] text-xs xl:text-base font-medium">
              Rename
            </span>
          </MenuItem>
        )}
        <MenuItem
          onClick={() => {
            setFolderMenuAnchorEl(null);
            setDeleteModalOpen(true);
          }}
          disableRipple
        >
          <TrashIcon className="w-[18px] xl:w-6 h-[18px] xl:h-6" />
          <span className="text-[#EF4444] text-xs xl:text-base font-medium">
            Delete
          </span>
        </MenuItem>
      </StyledMenu>

      <StyledMenu
        id="filter-menu"
        anchorEl={filterMenuAnchorEl}
        open={filterMenuOpen}
        onClose={handleFilterMenuClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <MenuItem
          className={`${filterType === "all" ? "!bg-[#DBEAFE]" : ""}`}
          onClick={() => {
            setFilterType("all");
            handleFilterMenuClose();
          }}
          disableRipple
        >
          <img
            src={ContentIcon}
            alt=""
            className="w-[18px] xl:w-6 h-[18px] xl:h-6"
          />
          <span className="text-[#3F3F46] text-xs xl:text-base font-medium">
            All File
          </span>
        </MenuItem>
        <MenuItem
          className={`${filterType === "docx" ? "!bg-[#DBEAFE]" : ""}`}
          onClick={() => {
            setFilterType("docx");
            handleFilterMenuClose();
          }}
          disableRipple
        >
          <img
            src={FileDOCIcon}
            alt=""
            className="w-[18px] xl:w-6 h-[18px] xl:h-6"
          />
          <span className="text-[#3F3F46] text-xs xl:text-base font-medium">
            Documents
          </span>
        </MenuItem>
        <MenuItem
          className={`${filterType === "pptx" ? "!bg-[#DBEAFE]" : ""}`}
          onClick={() => {
            setFilterType("pptx");
            handleFilterMenuClose();
          }}
          disableRipple
        >
          <img
            src={FilePPTIcon}
            alt=""
            className="w-[18px] xl:w-6 h-[18px] xl:h-6"
          />
          <span className="text-[#3F3F46] text-xs xl:text-base font-medium">
            Powerpoint
          </span>
        </MenuItem>
        <MenuItem
          className={`${filterType === "xlsx" ? "!bg-[#DBEAFE]" : ""}`}
          onClick={() => {
            setFilterType("xlsx");
            handleFilterMenuClose();
          }}
          disableRipple
        >
          <img
            src={FileXLSXIcon}
            alt=""
            className="w-[18px] xl:w-6 h-[18px] xl:h-6"
          />
          <span className="text-[#3F3F46] text-xs xl:text-base font-medium">
            XLSX
          </span>
        </MenuItem>
        <MenuItem
          className={`${filterType === "pdf" ? "!bg-[#DBEAFE]" : ""}`}
          onClick={() => {
            setFilterType("pdf");
            handleFilterMenuClose();
          }}
          disableRipple
        >
          <img
            src={FilePDFIcon}
            alt=""
            className="w-[18px] xl:w-6 h-[18px] xl:h-6"
          />
          <span className="text-[#3F3F46] text-xs xl:text-base font-medium">
            PDF
          </span>
        </MenuItem>
      </StyledMenu>

      <StyledMenu
        id="sort-menu"
        anchorEl={sortMenuAnchorEl}
        open={sortMenuOpen}
        onClose={handleSortMenuClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <MenuItem
          className={`${sortType === "ascending" ? "!bg-[#DBEAFE]" : ""}`}
          onClick={() => {
            setSortType("ascending");
            handleSortMenuClose();
          }}
          disableRipple
        >
          <span className="text-[#3F3F46] text-xs xl:text-base font-medium">
            Ascending
          </span>
        </MenuItem>
        <MenuItem
          className={`${sortType === "descending" ? "!bg-[#DBEAFE]" : ""}`}
          onClick={() => {
            setSortType("descending");
            handleSortMenuClose();
          }}
          disableRipple
        >
          <span className="text-[#3F3F46] text-xs xl:text-base font-medium">
            Descending
          </span>
        </MenuItem>
        <MenuItem
          className={`${
            sortType === "typeasc" || sortType === "typedisc"
              ? "!bg-[#DBEAFE]"
              : ""
          }`}
          onClick={() => {
            setSortType((prev) =>
              prev === "typeasc" ? "typedisc" : "typeasc"
            );
            handleSortMenuClose();
          }}
          disableRipple
        >
          <span className="text-[#3F3F46] text-xs xl:text-base font-medium">
            Type
          </span>
        </MenuItem>
      </StyledMenu>

      <StyledMenu
        id="upload-menu"
        anchorEl={uploadMenuAnchorEl}
        open={uploadMenuOpen}
        onClose={handleUploadMenuClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        transformOrigin={{ horizontal: "right", vertical: "bottom" }}
        anchorOrigin={{ horizontal: "right", vertical: "top" }}
      >
        {parentFolders.length > 0 && (
          <>
            <MenuItem
              disableRipple
              onClick={() => {
                if (fileUploadRef.current) fileUploadRef.current.click();
              }}
            >
              <MdFileUpload className="text-lg xl:text-2xl flex-none text-[#3F3F46]" />
              <span className="text-[#3F3F46] text-xs xl:text-base font-medium">
                Upload File
              </span>
            </MenuItem>
            <MenuItem
              disableRipple
              onClick={() => {
                if (folderUploadRef.current) folderUploadRef.current.click();
              }}
            >
              <MdOutlineDriveFolderUpload className="text-lg xl:text-2xl flex-none text-[#3F3F46]" />
              <span className="text-[#3F3F46] text-xs xl:text-base font-medium">
                Upload Folder
              </span>
            </MenuItem>
          </>
        )}
        <MenuItem onClick={() => setAddFolderModalOpen(true)} disableRipple>
          <GoPlus className="text-lg xl:text-2xl flex-none text-[#3F3F46]" />
          <span
            className="text-[#3F3F46] text-xs xl:text-base font-medium"
            data-event-name="Research: Folder Created"
          >
            Create Folder
          </span>
        </MenuItem>
      </StyledMenu>
      <DeleteModal
        open={deleteModalOpen}
        folder={selectedFolder}
        file={selectedFile}
        isFolder={isSelectFolder}
        parentFolders={parentFolders}
        handleClose={() => setDeleteModalOpen(false)}
      />
      {/* <DeleteConceptModal
        conceptIds={deleteConceptList}
        open={deleteConceptModalOpen}
        handleClose={() => setDeleteConceptModalOpen(false)}
        onDelete={(list) => {
          setSelectedConceptList((prev: string[]) => {
            return prev.filter((e) => !list.includes(e))
          })
        }}
      />
      <EditConceptModal
        open={editConceptModalOpen}
        concept={selectedConcept}
        handleClose={() => setEditConceptModalOpen(false)}
      /> */}
      <RenameFolderModal
        open={renameFolderModalOpen}
        folder={selectedFolder}
        parentFolders={parentFolders}
        handleClose={() => setRenameFolderModalOpen(false)}
      />
      <AddFolderModal
        open={addFolderModalOpen}
        parentFolders={parentFolders}
        handleClose={() => setAddFolderModalOpen(false)}
      />
      <UploadFileModal />
      <input
        type="file"
        accept=".docx, .pdf, .docx, .pptx, .xlsx"
        ref={fileUploadRef}
        multiple
        hidden
        name="file_upload"
        onChange={(e) => {
          setFolderUpload(false);
          handleFileUpload(e, false);
        }}
        disabled={!setting?.verified}
      />
      <input
        type="file"
        accept=".docx, .pdf, .docx, .pptx, .xlsx"
        ref={folderUploadRef}
        multiple
        hidden
        {...({ webkitdirectory: "true" } as any)}
        onChange={(e) => {
          setFolderUpload(true);
          handleFileUpload(e, true);
        }}
      />
    </div>
  );
};

export default FileManagerModal;
