import React from 'react'

const RisingStarIcon: React.FC<any> = (props: any) => {
  return (
    <svg
      viewBox="0 0 17 16"
      fill="white"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M11.575 2.76068L11.5284 5.28735C11.5217 5.63401 11.7417 6.09401 12.0217 6.30068L13.675 7.55401C14.735 8.35401 14.5617 9.33401 13.295 9.73402L11.1417 10.4073C10.7817 10.5207 10.4017 10.914 10.3084 11.2807L9.79502 13.2407C9.38836 14.7873 8.37502 14.9407 7.53502 13.5807L6.36169 11.6807C6.14836 11.334 5.64169 11.074 5.24169 11.094L3.01502 11.2073C1.42169 11.2873 0.968358 10.3673 2.00836 9.15401L3.32836 7.62068C3.57502 7.33401 3.68836 6.80068 3.57502 6.44068L2.15317 3.7904C1.75983 2.52373 2.99843 2.11391 4.25843 2.52724L6.43502 3.37401C6.76836 3.48068 7.26836 3.40735 7.54836 3.20068L9.60169 1.72068C10.715 0.927347 11.6017 1.39401 11.575 2.76068Z"
        fill="inherit"
      />
      <path
        d="M14.3404 13.6474L12.3204 11.6274C12.1271 11.4341 11.8071 11.4341 11.6138 11.6274C11.4204 11.8208 11.4204 12.1408 11.6138 12.3341L13.6337 14.3541C13.7337 14.4541 13.8604 14.5008 13.9871 14.5008C14.1137 14.5008 14.2404 14.4541 14.3404 14.3541C14.5338 14.1608 14.5338 13.8408 14.3404 13.6474Z"
        fill="inherit"
      />
    </svg>
  )
}

export default RisingStarIcon
