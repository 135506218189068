import React from 'react'

const DiamondIcon: React.FC<any> = (props: any) => {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      stroke="#09090B"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M22 7.78477L17 3H13.4792M22 7.78477L11.9583 21M22 7.78477H15.9792M2 7.78477L6.91667 3H10.4375M2 7.78477H7.58333M2 7.78477L11.9583 21M11.9583 21L7.58333 7.78477M11.9583 21L15.9792 7.78477M10.4375 3H11.9583H13.4792M10.4375 3L7.58333 7.78477M7.58333 7.78477H11.9583H15.9792M13.4792 3L15.9792 7.78477"
        stroke="inherit"
        strokeWidth="1.5"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default DiamondIcon
