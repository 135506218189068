import React from "react";

const SettingCheckIcon: React.FC<any> = (props: any) => {
  return (
    <svg
      viewBox="0 0 24 25"
      xmlns="http://www.w3.org/2000/svg"
      stroke="#52525B"
      {...props}
      fill="none"
    >
      <path
        d="M9.39333 20.3418L9.97778 21.6563C10.1515 22.0475 10.4351 22.38 10.794 22.6133C11.153 22.8466 11.5719 22.9708 12 22.9707C12.4281 22.9708 12.847 22.8466 13.206 22.6133C13.5649 22.38 13.8485 22.0475 14.0222 21.6563L14.6067 20.3418C14.8147 19.8754 15.1647 19.4866 15.6067 19.2307C16.0515 18.9741 16.566 18.8649 17.0767 18.9185L18.5067 19.0707C18.9323 19.1157 19.3619 19.0363 19.7434 18.842C20.1248 18.6478 20.4417 18.347 20.6556 17.9763C20.8697 17.6057 20.9718 17.181 20.9493 16.7536C20.9268 16.3262 20.7808 15.9145 20.5289 15.5685L19.6822 14.4051C19.3807 13.9878 19.2197 13.4855 19.2222 12.9707C19.2221 12.4573 19.3847 11.9571 19.6867 11.5418L20.5333 10.3785C20.7852 10.0325 20.9312 9.62077 20.9537 9.19338C20.9762 8.76598 20.8742 8.34124 20.66 7.9707C20.4461 7.59994 20.1292 7.29919 19.7478 7.10493C19.3664 6.91067 18.9368 6.83123 18.5111 6.87626L17.0811 7.02848C16.5704 7.08211 16.0559 6.97282 15.6111 6.71626C15.1682 6.45896 14.8182 6.06806 14.6111 5.59959L14.0222 4.28515C13.8485 3.89387 13.5649 3.56142 13.206 3.3281C12.847 3.09479 12.4281 2.97063 12 2.9707C11.5719 2.97063 11.153 3.09479 10.794 3.3281C10.4351 3.56142 10.1515 3.89387 9.97778 4.28515L9.39333 5.59959C9.18626 6.06806 8.83621 6.45896 8.39333 6.71626C7.94855 6.97282 7.434 7.08211 6.92333 7.02848L5.48889 6.87626C5.06322 6.83123 4.63363 6.91067 4.2522 7.10493C3.87078 7.29919 3.5539 7.59994 3.34 7.9707C3.12582 8.34124 3.02378 8.76598 3.04627 9.19338C3.06875 9.62077 3.21479 10.0325 3.46667 10.3785L4.31333 11.5418C4.61527 11.9571 4.77787 12.4573 4.77778 12.9707C4.77787 13.4841 4.61527 13.9844 4.31333 14.3996L3.46667 15.5629C3.21479 15.9089 3.06875 16.3206 3.04627 16.748C3.02378 17.1754 3.12582 17.6002 3.34 17.9707C3.55412 18.3413 3.87103 18.6419 4.2524 18.8361C4.63377 19.0303 5.06325 19.1099 5.48889 19.0651L6.91889 18.9129C7.42956 18.8593 7.9441 18.9686 8.38889 19.2251C8.83342 19.4817 9.1851 19.8727 9.39333 20.3418Z"
        stroke="inherit"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.99829 12.9707L10.9983 14.9707L14.9983 10.9707"
        stroke="inherit"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default SettingCheckIcon;
