// useGlobalErrorHandler.ts
import { useEffect, useState } from 'react';
import ErrorPage from './ErrorPage';

const useGlobalErrorHandler = () => {
    const [hasError, setHasError] = useState(false);

    useEffect(() => {
        const handleError = (event: ErrorEvent) => {
            // Log the error to an error reporting service
            console.error('Global error handler caught an error', event.message, event.error);
            // Prevent default error handling behavior
            event.preventDefault();
            // Set state to trigger rendering of error page
            setHasError(true);
        };

        const handleRejection = (event: PromiseRejectionEvent) => {
            // Log the error to an error reporting service
            console.error('Global error handler caught a rejection', event.reason);
            // Prevent default rejection handling behavior
            event.preventDefault();
            // Set state to trigger rendering of error page
            setHasError(true);
            return false
        };

        window.addEventListener('error', handleError);
        window.addEventListener('unhandledrejection', handleRejection);

        return () => {
            window.removeEventListener('error', handleError);
            window.removeEventListener('unhandledrejection', handleRejection);
        };
    }, []);

    return hasError;
};

export default useGlobalErrorHandler;
