import React from 'react'

const DoubleCheckIcon: React.FC<any> = (props: any) => {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      stroke="#71717A"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.5 16.584V7.915C21.5 4.895 19.361 3 16.334 3H8.166C5.139 3 3 4.885 3 7.915L3 16.584C3 19.614 5.139 21.5 8.166 21.5H16.334C19.361 21.5 21.5 19.614 21.5 16.584Z"
        stroke="inherit"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.66406 12.3501L10.154 14.8394L15.1319 9.86084M12.4873 15.3058L16.9985 10.794"
        stroke="inherit"
        strokeWidth="1.40022"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default DoubleCheckIcon
