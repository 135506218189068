import { Drawer } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useRagContext } from "../../../contexts/RagContext";
import searchIcon from "../../../assets/svg/search.svg";
import BrainstormFolder from "./BrainstormFolder";
import { useUserContext } from "../../../contexts/UserContext";
import moment from "moment";
import axios from "axios";
import { useHistory } from "react-router";
import { BiLoaderAlt } from "react-icons/bi";
import { useLayoutContext } from "../../../contexts/LayoutContext";

const StartBrainstormDrawer: React.FC = () => {
  const [searchText, setSearchText] = useState("");
  const [shownFolderList, setShownFolderList] = useState<any[]>([]);
  const [showLoading, setShowLoading] = useState<boolean>(false);

  const {
    folderList,
    startBrainstormDrawerVisible,
    selectedBrainstormFiles,
    socketId,
    getFilePath,
    getBrainstormList,
    closeStartBrainstormDrawer,
    setSelectedBrainstormFiles,
  } = useRagContext();
  const { user } = useUserContext();
  const { deviceType } = useLayoutContext();
  const history = useHistory();

  useEffect(() => {
    if (searchText === "") {
      setShownFolderList(folderList);
    } else {
      const searchTextLower = searchText.toLowerCase();
      const tempFolderList = JSON.parse(JSON.stringify(folderList));

      const traverseAndFilter = (node: any) => {
        const keysToRemove: string[] = [];

        Object.keys(node).forEach((key) => {
          if (key.toLowerCase().indexOf(searchTextLower) === -1) {
            keysToRemove.push(key);
          } else if (node[key].children) {
            traverseAndFilter(node[key].children);
          }
        });

        keysToRemove.forEach((key) => {
          delete node[key];
        });
      };

      tempFolderList.forEach((folder: any) => {
        traverseAndFilter(folder.structure);
      });

      setShownFolderList(tempFolderList);
    }
  }, [folderList, searchText]);

  const handleGetHMWQuestions = async () => {
    try {
      let fileList = selectedBrainstormFiles.map((file: any) => {
        return getFilePath(file.file_name, file.folder_id);
      });
      let params: any = {
        team_id: user?.organizationMemberships[0]?.organization.id,
        file_list: fileList,
        name: "Brainstorm " + moment().format("YYYY-MM-DD h:mm a"),
        context: "",
        fromSource: true,
        socketId,
      };
      if (fileList.length > 0) {
        params.pinecone_id = fileList[0].split("/")[2];
      } else if (folderList.length > 0) {
        params.pinecone_id = folderList[0].id;
      } else {
        params.pinecone_id = "";
      }
      setShowLoading(true);
      const result = await axios.post(
        process.env.REACT_APP_API_URL + "api/getHMWQuestions",
        params
      );
      setShowLoading(false);

      const searchParams = new URLSearchParams();
      searchParams.set("render_type", "modal");
      searchParams.set("render_modal", "brainstorm_modal");
      searchParams.set("brainstorm_id", result.data.brainstorm_id);

      history.push({
        pathname: "/concept",
        search: `?${searchParams.toString()}`,
      });
      getBrainstormList();
      closeStartBrainstormDrawer();
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (!startBrainstormDrawerVisible) {
      setSelectedBrainstormFiles([]);
    }
  }, [startBrainstormDrawerVisible]);

  return (
    <Drawer
      open={startBrainstormDrawerVisible}
      onClose={() => closeStartBrainstormDrawer()}
      anchor="right"
    >
      <div className="w-[352px] xl:w-[470px]">
        <div className="w-full h-[34px] xl:h-[46px] px-[15px] xl:px-5 py-[7px] xl:py-[10px] border-b border-solid border-[#D4D4D8]">
          <span className="text-[13px] xl:text-lg leading-[19px] xl:leading-[26px] font-medium text-[#09090B]">
            Select Source
          </span>
        </div>
        <div className="px-[15px] xl:px-5 py-3 xl:py-4">
          <div
            className={`relative w-full h-[28px] xl:h-[38px] rounded-[4px] border border-solid border-[#D4D4D8] bg-white px-[7px] xl:px-[10px] py-[6px] xl:py-2 flex gap-[9px] xl:gap-3 items-center mb-3 xl:mb-4`}
          >
            <img
              src={searchIcon}
              alt="Search"
              className="w-[13px] xl:w-[18px] h-[13px] xl:h-[18px] flex-none"
            />
            <input
              type="text"
              className="flex-1 text-[10px] xl:text-sm leading-[16px] xl:leading-[22px] text-[#A1A1AA] font-medium outline-none"
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
              placeholder="Search"
            />
          </div>

          <div
            className="w-full overflow-x-auto overflow-y-auto mb-3 xl:mb-4"
            style={{
              height: selectedBrainstormFiles.length
                ? `calc(100vh - ${
                    199 * (deviceType === "laptop" ? 0.75 : 1)
                  }px)`
                : `calc(100vh - ${
                    150 * (deviceType === "laptop" ? 0.75 : 1)
                  }px)`,
            }}
            id="start-brainstorm-folder-container"
          >
            {shownFolderList.map((folder, index) => {
              return folder.isFolderDeleted === 1 ? (
                <div key={index}></div>
              ) : (
                <BrainstormFolder
                  key={index}
                  folder={folder}
                ></BrainstormFolder>
              );
            })}
          </div>
          {selectedBrainstormFiles.length > 0 && (
            <button
              className={`w-full h-9 xl:h-12 rounded-xl bg-[#3B82F6] outline-none flex items-center justify-center gap-[6px] xl:gap-2 ${
                showLoading ? "opacity-60" : ""
              }`}
              onClick={() => handleGetHMWQuestions()}
              disabled={showLoading}
              data-event-name="Brainstorm: Started"
            >
              <span
                className="text-white text-xs xl:text-base font-bold font-Satoshi"
                data-event-name="Brainstorm: Started"
              >
                Start Brainstorm
              </span>
              {showLoading && (
                <BiLoaderAlt className="w-[18px] xl:w-6 h-[18px] xl:h-6 text-white animate-spin" />
              )}
            </button>
          )}
        </div>
      </div>
    </Drawer>
  );
};

export default StartBrainstormDrawer;
