import React, { useEffect, useState } from "react";
import { useRagContext } from "../../../contexts/RagContext";
import editPencilIcon from "../../../assets/svg/edit-pen.svg";
import { BiLoaderAlt } from "react-icons/bi";
import ChatIcon from "../../icons/ChatIcon";
import ICPIcon from "../../icons/ICPIcon";
import InfoIcon from "../../icons/InfoIcon";
import ContentIcon from "../../icons/ContentIcon";
import { useHistory } from "react-router";
import axios from "axios";
import { toast } from "react-toastify";
import { styled, Tooltip, tooltipClasses, TooltipProps } from "@mui/material";
import DuplicateIcon from "../../icons/DuplicateIcon";
import EditConceptMainModal from "./EditConceptSubModal/EditConceptMainModal";
import EditConceptBusinessCanvasModal from "./EditConceptSubModal/EditConceptBusinessCanvasModal";
import PieChartIcon from "../../icons/PieChartIcon";
import FiveForceIcon from "../../icons/FiveForceIcon";
import DiamondIcon from "../../icons/DiamondIcon";
import QuestionIcon from "../../icons/QuestionIcon";
import SettingCheckIcon from "../../icons/SettingCheckIcon";
import EditConceptFiveForceModal from "./EditConceptSubModal/EditConceptFiveForceModal";
import OverviewIcon from "../../icons/OverviewIcon";
import { useLayoutContext } from "../../../contexts/LayoutContext";
import FolderIcon from "../../icons/FolderIcon";
import SourceMaterialContent from "./SourceMaterialContent";
import OverviewContent from "./OverviewContent";
import ElevatorPitchContent from "./ElevatorPitchContent";
import ICPContent from "./ICPContent";
import ProblemStatementsContent from "./ProblemStatementsContent";
import MarketSizeContent from "./MarketSizeContent";
import ValuePropositionContent from "./ValuePropositionContent";
import Top5QuestionsContent from "./Top5QuestionsContent";
import FiveForcesContent from "./FiveForcesContent";
import BusinessModelContent from "./BusinessModelContent";
import GoodnessFactorContent from "./GoodnessFactorContent";

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#040404",
    color: "#e5e5e5",
    maxWidth: 220,
    border: "1px solid #dadde9",
    marginBottom: "8px !important",
    padding: "2px 4px !important",
    fontSize: "11px !important",
  },
}));

export const conceptItemList =
  process.env.REACT_APP_BOSCH_INSTANCE === "true"
    ? [
        {
          key: "SourceMaterial",
          title: "Source Material",
          icon: (
            <FolderIcon
              className="flex-none w-6 h-6"
              fill={"none"}
              stroke="inherit"
            />
          ),
        },
        {
          key: "Overview",
          title: "Overview",
          icon: (
            <OverviewIcon
              className="flex-none w-6 h-6"
              fill={"inherit"}
              stroke="none"
            />
          ),
        },
        {
          key: "ICP",
          title: "Ideal Customer Profile",
          icon: <ICPIcon className="flex-none w-6 h-6" stroke={"inherit"} />,
        },
        {
          key: "ProblemStatements",
          title: "Problem Statements",
          icon: <InfoIcon className="flex-none w-6 h-6" stroke={"inherit"} />,
        },
        {
          key: "GoodnessFactor",
          title: "Solution / Goodness Factor",
          icon: (
            <SettingCheckIcon
              className="flex-none w-6 h-6"
              stroke={"inherit"}
            />
          ),
        },
        {
          key: "MarketSizeReport",
          title: "Market Size",
          icon: (
            <PieChartIcon className="flex-none w-6 h-6" stroke={"inherit"} />
          ),
        },
        {
          key: "FiveForces",
          title: "Five Forces",
          icon: (
            <FiveForceIcon
              className="flex-none w-6 h-6"
              stroke={"inherit"}
              fill={"inherit"}
            />
          ),
        },
        {
          key: "BusinessModelCanvasJSON",
          title: "Business Model Canvas",
          icon: (
            <ContentIcon className="flex-none w-6 h-6" stroke={"inherit"} />
          ),
        },
      ]
    : [
        {
          key: "SourceMaterial",
          title: "Source Material",
          icon: (
            <FolderIcon
              className="flex-none w-6 h-6"
              fill={"none"}
              stroke="inherit"
            />
          ),
        },
        {
          key: "Overview",
          title: "Overview",
          icon: (
            <OverviewIcon
              className="flex-none w-6 h-6"
              fill={"inherit"}
              stroke="none"
            />
          ),
        },
        {
          key: "Elevator",
          title: "Elevator Pitch",
          icon: <ChatIcon className="flex-none w-6 h-6" stroke={"inherit"} />,
        },
        {
          key: "ICP",
          title: "Ideal Customer Profile",
          icon: <ICPIcon className="flex-none w-6 h-6" stroke={"inherit"} />,
        },
        {
          key: "MarketSizeReport",
          title: "Market Size",
          icon: (
            <PieChartIcon className="flex-none w-6 h-6" stroke={"inherit"} />
          ),
        },
        {
          key: "ProblemStatements",
          title: "Problem Statements",
          icon: <InfoIcon className="flex-none w-6 h-6" stroke={"inherit"} />,
        },
        {
          key: "GoodnessFactor",
          title: "Solution / Goodness Factor",
          icon: (
            <SettingCheckIcon
              className="flex-none w-6 h-6"
              stroke={"inherit"}
              fill={"inherit"}
            />
          ),
        },
        {
          key: "FiveForces",
          title: "Five Forces",
          icon: (
            <FiveForceIcon
              className="flex-none w-6 h-6"
              stroke={"inherit"}
              fill={"inherit"}
            />
          ),
        },
        {
          key: "BusinessModelCanvasJSON",
          title: "Business Model Canvas",
          icon: (
            <ContentIcon className="flex-none w-6 h-6" stroke={"inherit"} />
          ),
        },
        {
          key: "ValuePropositionCanvas",
          title: "Value Proposition Canvas",
          icon: (
            <DiamondIcon className="flex-none w-6 h-6" stroke={"inherit"} />
          ),
        },
        {
          key: "Top5Questions",
          title: "Top 5 Questions",
          icon: (
            <QuestionIcon className="flex-none w-6 h-6" stroke={"inherit"} />
          ),
        },
      ];

export const fiveForceTabList = [
  { key: "Competitors", title: "Direct Competitors" },
  { key: "Substitutes", title: "Substitutes" },
  { key: "New_Entrants", title: "New Entrants" },
  { key: "Buyers", title: "Buyers" },
  { key: "Suppliers", title: "Suppliers" },
];

const ConceptModal: React.FC = () => {
  const history = useHistory();
  const {
    conceptModalVisible,
    selectedConceptId,
    socketId,
    socket,
    openShareFeedbackModal,
    getConceptFolders,
  } = useRagContext();
  const { setBreadCrumbs } = useLayoutContext();
  const [conceptData, setConceptData] = useState<any>(null);
  const [selectedConceptList, setSelectedListConcept] =
    useState<any>("Overview");
  const [editConceptSubModalOpen, setEditConceptSubModalOpen] =
    React.useState<boolean>(false);

  useEffect(() => {
    if (conceptModalVisible && selectedConceptId) {
      getConceptDetail("initial");
    }
  }, [conceptModalVisible, selectedConceptId]);

  useEffect(() => {
    if (conceptData && conceptModalVisible) {
      if (conceptData.type === "brainstorm") {
        if (conceptData.brainstorm_id === "") {
          setBreadCrumbs([
            {
              title: "Concept",
              active: false,
              onClick: () => {
                closeConceptModal();
              },
            },
            {
              title: conceptData.concept_title,
              active: true,
              onClick: () => {},
            },
          ]);
        } else {
          setBreadCrumbs([
            {
              title: "Concept",
              active: false,
              onClick: () => {
                closeConceptModal();
              },
            },
            {
              title: "Generated Concepts",
              active: false,
              onClick: () => {
                openBrainstormModal(conceptData.brainstorm_id);
              },
            },
            {
              title: conceptData.concept_title,
              active: true,
              onClick: () => {},
            },
          ]);
        }
      } else {
        setBreadCrumbs([
          {
            title: "Concept",
            active: false,
            onClick: () => {
              closeConceptModal();
            },
          },
          {
            title: conceptData.concept_title,
            active: true,
            onClick: () => {},
          },
        ]);
      }
    }
  }, [conceptData, conceptModalVisible]);

  useEffect(() => {
    if (socket) {
      socket.on("concept_sub_creation", (data: any) => {
        if (data.key === "concept_title") {
          getConceptFolders();
        }
        setConceptData((prev: any) => {
          return {
            ...prev,
            [data.key]: data.value,
          };
        });
      });
    }

    return () => {
      if (socket) {
        return socket.off("concept_sub_creation");
      }
    };
  }, [socket, socketId]);

  const closeConceptModal = () => {
    setBreadCrumbs([
      {
        title: "Concept",
        active: true,
        onClick: () => {},
      },
    ]);
    history.push({
      pathname: "/concept",
    });
  };

  const getConceptDetail = async (renderType?: string) => {
    try {
      let response = await axios.post(
        process.env.REACT_APP_API_URL + "api/getConcept",
        {
          id: selectedConceptId,
        }
      );
      setConceptData(response.data.data);
      if (renderType === "initial") {
        setSelectedListConcept("Overview");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const copyToClipBoard = (message: string) => {
    navigator.clipboard.writeText(message);
    toast.success("Copied to Clipboard!", {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
      progress: 0,
      toastId: "my_toast",
    });
  };

  const openBrainstormModal = (id: string) => {
    const searchParams = new URLSearchParams();
    searchParams.set("render_type", "modal");
    searchParams.set("render_modal", "brainstorm_modal");
    searchParams.set("brainstorm_id", id);

    history.push({
      pathname: "/concept",
      search: `?${searchParams.toString()}`,
    });
  };

  const handleConceptCopyText = () => {
    if (selectedConceptList === "BusinessModelCanvasJSON") {
      copyToClipBoard(
        `Key Partner\n${conceptData[selectedConceptList]["Key_Partnerships"]}\nKey Activity\n${conceptData[selectedConceptList]["Key_Activities"]}\nKey Resources\n${conceptData[selectedConceptList]["Key_Resources"]}\nValue Propositions\n${conceptData[selectedConceptList]["Value_Propositions"]}\nCustomer Relationships\n${conceptData[selectedConceptList]["Customer_Relationships"]}\nChannels\n${conceptData[selectedConceptList]["Channels"]}\nCustomer Segments\n${conceptData[selectedConceptList]["Customer_Segments"]}\nCost Structure\n${conceptData[selectedConceptList]["Cost_Structure"]}\nRevenue Streams\n${conceptData[selectedConceptList]["Revenue_Streams"]}\n`
      );
    } else if (selectedConceptList === "FiveForces") {
      copyToClipBoard(
        fiveForceTabList
          .map((tab) => {
            return `${tab.title}:\n${conceptData[selectedConceptList][tab.key]
              .map((e: any) => {
                return `${e.name}\n${e.relevance}\n${e.website_url}`;
              })
              .join(`\n`)}`;
          })
          .join("\n")
      );
    } else {
      copyToClipBoard(conceptData[selectedConceptList]);
    }
  };
  const handleConceptExportText = () => {
    window.open(
      `${process.env.REACT_APP_API_URL}api/exportCanvas?id=${selectedConceptId}`
    );
  };

  return (
    <div
      className="w-full h-full absolute top-0 left-0 z-20 bg-[#F4F4F5]"
      hidden={!conceptModalVisible}
    >
      {conceptData && conceptModalVisible && (
        <>
          <div className="px-10 flex gap-2 pt-4">
            <div className="flex flex-col w-[336px] gap-4 h-[calc(100vh-160px)] overflow-auto">
              <div className="bg-white rounded-2xl w-full border border-solid border-[#D4D4D8] flex-1">
                <div className="flex flex-col gap-4 p-4">
                  <div className="text-xl font-bold text-[rgb(39,39,42)]">
                    Validate Concept
                  </div>
                  <div className="flex flex-col gap-4">
                    {conceptItemList.map((item, index) => {
                      return (
                        <div
                          className={`p-4 flex gap-2 ${
                            selectedConceptList === item.key
                              ? "stroke-[#3B82F6] fill-[#3B82F6]"
                              : "stroke-[#09090B] fill-[#09090B]"
                          } ${
                            conceptData[item.key] === ""
                              ? "cursor-not-allowed"
                              : "cursor-pointer"
                          }`}
                          key={index}
                          onClick={() => {
                            if (conceptData[item.key] !== "")
                              setSelectedListConcept(item.key);
                          }}
                        >
                          {item.icon}
                          <div
                            className={`text-base font-medium whitespace-nowrap overflow-hidden text-ellipsis flex-1 ${
                              selectedConceptList === item.key
                                ? "text-[#3B82F6]"
                                : "text-[#71717A]"
                            } ${
                              conceptData[item.key] === ""
                                ? "cursor-not-allowed"
                                : "cursor-pointer"
                            }`}
                          >
                            {item.title}
                          </div>
                          {conceptData[item.key] === "" && (
                            <BiLoaderAlt className="w-6 h-6 text-[#71717A] animate-spin" />
                          )}
                        </div>
                      );
                    })}
                  </div>
                  {process.env.REACT_APP_BOSCH_INSTANCE === "true" && (
                    <button
                      className="w-full h-12 rounded-xl bg-[#3B82F6] outline-none flex items-center justify-center gap-2"
                      onClick={() => handleConceptExportText()}
                    >
                      <span className="text-white text-base font-bold font-Satoshi">
                        Export
                      </span>
                    </button>
                  )}
                </div>
              </div>
              <div className="px-4">
                <button
                  className="w-full h-12 rounded-xl border border-solid border-[#3B82F6] outline-none flex items-center justify-center gap-2"
                  onClick={() => openShareFeedbackModal("Concept")}
                >
                  <span className="text-[#3B82F6] text-base font-bold font-Satoshi">
                    Share Feedback
                  </span>
                </button>
              </div>
            </div>
            <div className="flex-1 h-[calc(100vh-160px)] overflow-auto relative">
              <SourceMaterialContent
                conceptData={conceptData}
                visible={selectedConceptList === "SourceMaterial"}
              />
              <OverviewContent
                concept_title={conceptData.concept_title}
                concept_description={conceptData.concept_description}
                problem={conceptData.problem}
                need={conceptData.need}
                solution={conceptData.solution}
                value={conceptData.value}
                conceptData={conceptData}
                visible={selectedConceptList === "Overview"}
                getConceptDetail={getConceptDetail}
              />
              <ElevatorPitchContent
                conceptData={conceptData}
                visible={selectedConceptList === "Elevator"}
                getConceptDetail={getConceptDetail}
              />
              <ICPContent
                conceptData={conceptData}
                visible={selectedConceptList === "ICP"}
                getConceptDetail={getConceptDetail}
              />
              <MarketSizeContent
                conceptData={conceptData}
                visible={selectedConceptList === "MarketSizeReport"}
                getConceptDetail={getConceptDetail}
              />
              <ProblemStatementsContent
                conceptData={conceptData}
                visible={selectedConceptList === "ProblemStatements"}
                getConceptDetail={getConceptDetail}
              />
              <GoodnessFactorContent
                conceptData={conceptData}
                visible={selectedConceptList === "GoodnessFactor"}
                getConceptDetail={getConceptDetail}
              />
              <FiveForcesContent
                conceptData={conceptData}
                visible={selectedConceptList === "FiveForces"}
                getConceptDetail={getConceptDetail}
              />
              <BusinessModelContent
                conceptData={conceptData}
                visible={selectedConceptList === "BusinessModelCanvasJSON"}
                getConceptDetail={getConceptDetail}
              />
              <ValuePropositionContent
                conceptData={conceptData}
                visible={selectedConceptList === "ValuePropositionCanvas"}
                getConceptDetail={getConceptDetail}
              />
              <Top5QuestionsContent
                conceptData={conceptData}
                visible={selectedConceptList === "Top5Questions"}
                getConceptDetail={getConceptDetail}
              />
              {/* <ConceptContent
                concept_title={conceptData.concept_title}
                concept_description={conceptData.concept_description}
                problem={conceptData.problem}
                need={conceptData.need}
                solution={conceptData.solution}
                value={conceptData.value}
                conceptData={conceptData}
                selectedConceptList={selectedConceptList}
                getConceptDetail={getConceptDetail}
              /> */}
              {selectedConceptList === "FiveForces" && (
                <div className="flex flex-col gap-4 absolute bottom-0">
                  <div className="flex gap-2">
                    <HtmlTooltip title={<div>Copy</div>} placement="top">
                      <button
                        className="outline-none w-7 h-7 border border-[#D4D4D8] items-center justify-center rounded-lg flex"
                        onClick={() => handleConceptCopyText()}
                      >
                        <DuplicateIcon className="w-4 h-4" />
                      </button>
                    </HtmlTooltip>
                    <HtmlTooltip title={<div>Edit</div>} placement="top">
                      <button
                        className="outline-none w-7 h-7 border border-[#D4D4D8] items-center justify-center rounded-lg flex"
                        onClick={() => setEditConceptSubModalOpen(true)}
                      >
                        <img src={editPencilIcon} alt="" className="w-5 h-5" />
                      </button>
                    </HtmlTooltip>
                  </div>
                </div>
              )}
            </div>
          </div>
          {selectedConceptList === "BusinessModelCanvasJSON" ? (
            <EditConceptBusinessCanvasModal
              open={editConceptSubModalOpen}
              concept={conceptData}
              handleClose={() => setEditConceptSubModalOpen(false)}
              onEdit={() => getConceptDetail()}
            />
          ) : selectedConceptList === "FiveForces" ? (
            <EditConceptFiveForceModal
              open={editConceptSubModalOpen}
              concept={conceptData}
              handleClose={() => setEditConceptSubModalOpen(false)}
              onEdit={() => getConceptDetail()}
            />
          ) : (
            <EditConceptMainModal
              open={editConceptSubModalOpen}
              concept={conceptData}
              selectedConceptList={selectedConceptList}
              handleClose={() => setEditConceptSubModalOpen(false)}
              onEdit={() => getConceptDetail()}
            />
          )}
        </>
      )}
    </div>
  );
};

export default ConceptModal;
