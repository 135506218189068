import React from "react";
import Modal from "@mui/material/Modal";
import { IoCloseOutline } from "react-icons/io5";
import { useRagContext } from "../../../contexts/RagContext";
import { exportTextFile } from "../../../utils";

const ActivityLogsModal: React.FC = () => {
  const { activityLogs, activityLogsModalVisible, closeActivityLogsModal } =
    useRagContext();

  const handleExportLog = () => {
    exportTextFile(
      `activity-logs.txt`,
      `${activityLogs.map((e: any) => e.output).join("\n")}`
    );
  };

  return (
    <Modal
      open={activityLogsModalVisible}
      onClose={() => closeActivityLogsModal()}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 w-[730px] xl:w-[974px] h-[590px] xl:h-[786px] bg-white rounded-[10px] shadow-lg max-h-screen overflow-auto">
        <div className="py-3 xl:py-4 px-[18px] xl:px-6 flex items-center justify-between border-b border-solid border-[#E4E4E7] gap-3 xl:gap-4">
          <div className="">
            <div className="text-[13px] xl:text-lg leading-[16px] xl:leading-[22px] font-bold text-[#18181b] mb-[3px] xl:mb-1">
              Activity Logs
            </div>
          </div>
          <button
            className="w-6 xl:w-8 h-6 xl:h-8 border border-solid border-[#D4D4D8] flex items-center justify-center flex-none rounded-full"
            onClick={() => closeActivityLogsModal()}
          >
            <IoCloseOutline className="w-[15px] xl:w-5 h-[15px] xl:h-5 text-[#09090B]" />
          </button>
        </div>
        <div className="p-[18px] xl:p-6">
          <div className="border border-solid border-[#D4D4D8] bg-white rounded-2xl mb-6 xl:mb-8 p-3 xl:p-4 h-[445px] xl:h-[594px] overflow-x-hidden overflow-y-auto">
            {activityLogs.map((e: any, index: number) => (
              <div
                key={index}
                className="text-xs xl:text-base font-normal text-[#000000cc]"
              >
                {e.output}
              </div>
            ))}
          </div>
          <div className="flex gap-3 xl:gap-4">
            <button
              className="outline-none border border-solid border-[#71717A] h-9 xl:h-12 flex items-center justify-center rounded-lg w-full"
              onClick={() => closeActivityLogsModal()}
            >
              <span className="text-xs xl:text-base font-bold text-[#71717A]">
                Cancel
              </span>
            </button>
            <button
              className="outline-none bg-[#3B82F6] h-9 xl:h-12 flex items-center justify-center rounded-lg w-full"
              onClick={() => handleExportLog()}
            >
              <span className="text-xs xl:text-base font-bold text-white">
                Export
              </span>
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ActivityLogsModal;
