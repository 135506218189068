import React, { useEffect } from "react";
import { Link, RouteComponentProps } from "react-router-dom";
import ArrowRightIcon from "../../Components/icons/ArrowRightIcon";
import { BreadCrumbMenu } from "../../Components/common/BreadCrumb";
import { useLayoutContext } from "../../contexts/LayoutContext";
import ResearchIcon from "../../Components/icons/ResearchIcon";
import BagIcon from "../../Components/icons/BagIcon";

type HomeComponentProps = RouteComponentProps;
const Home: React.FC<HomeComponentProps> = ({ history }) => {
  const { setBreadCrumbs } = useLayoutContext();
  useEffect(() => {
    const menus: BreadCrumbMenu[] = [
      {
        title: "Home",
        active: true,
        onClick: () => {},
      },
    ];
    setBreadCrumbs(menus);
  }, []);

  return (
    <div className="w-full h-full flex flex-col items-center justify-center gap-[30px] xl:gap-10 p-[30px] xl:p-10">
      <div className="">
        <div className="text-[27px] xl:text-4xl font-medium text-[#111927] mb-[30px] xl:mb-10 text-center">
          Venture diligence for internal innovation.
        </div>
        <div className="flex justify-center px-[6px] xl:px-2 gap-[9px] xl:gap-3">
          <Link
            to="/research"
            className="w-[277px] xl:w-[370px] bg-[#3b83f60c] rounded-[32px]"
          >
            <div
              className="flex flex-col gap-3 xl:gap-4 p-[18px] xl:p-6"
              data-event-name="Home: Research Tab Clicked"
            >
              <ResearchIcon
                className="w-[18px] xl:w-6 h-[18px] xl:h-6"
                fill="#3B82F6"
              />
              <div className="text-black text-[18px] xl:text-2xl font-medium">
                Research
              </div>
              <div className="text-[#52525B] text-xs xl:text-base font-normal">
                Analyze your docs & source material
                <br />
                Discover scholarly articles & publications
                <br />
                Explore the patent and IP landscape
                <br />
                Search the entire web for evidence
              </div>
              <div className="flex justify-end">
                <button className="w-6 xl:w-8 h-6 xl:h-8 rounded-lg bg-white border border-[#E5E7EB] flex items-center justify-center">
                  <ArrowRightIcon
                    className="w-[18px] xl:w-6 h-[18px] xl:h-6"
                    stroke="#3B82F6"
                  />
                </button>
              </div>
            </div>
          </Link>
          <Link
            to="/concept"
            className="w-[277px] xl:w-[370px] bg-[#3b83f60c] rounded-[32px]"
          >
            <div
              className="flex flex-col gap-3 xl:gap-4 p-[18px] xl:p-6"
              data-event-name="Home: Concept Tab Clicked"
            >
              <BagIcon
                className="w-[18px] xl:w-6 h-[18px] xl:h-6"
                stroke="#3B82F6"
              />
              <div className="text-black text-[18px] xl:text-2xl font-medium">
                Concept
              </div>
              <div className="text-[#52525B] text-xs xl:text-base font-normal">
                Generate venture concepts from data
                <br />
                Define initial pitch & business model
                <br />
                Assess market size and opportunities
                <br />
                Uncover potential buyers & suppliers
              </div>
              <div className="flex justify-end">
                <button className="w-6 xl:w-8 h-6 xl:h-8 rounded-lg bg-white border border-[#E5E7EB] flex items-center justify-center">
                  <ArrowRightIcon
                    className="w-[18px] xl:w-6 h-[18px] xl:h-6"
                    stroke="#3B82F6"
                  />
                </button>
              </div>
            </div>
          </Link>
        </div>
      </div>

      {/* Footer */}
      <footer className="w-full flex justify-center mt-[30px] xl:mt-10 p-3 xl:p-4 border-t border-gray-200">
        <span
          onClick={() => {
            history.push("/privacy");
          }}
          className="text-[#3B82F6] cursor-pointer"
        >
          Privacy Policy
        </span>
      </footer>
    </div>
  );
};

export default Home;
