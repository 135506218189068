import React from "react";

const ArrowRightIcon: React.FC<any> = (props: any) => {
  return (
    <svg
      viewBox="0 0 18 19"
      fill="none"
      stroke="#3B82F6"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M8.25 7.25L10.5 9.5L8.25 11.75"
        stroke="inherit"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ArrowRightIcon;
