import React, { useEffect, useState } from "react";
import { HiOutlineFolderMinus, HiOutlineFolderPlus } from "react-icons/hi2";
import { useRagContext } from "../../../contexts/RagContext";
import BpCheckbox from "../../common/BPCheckbox";
import $ from "jquery";

interface SourceMaterialFolderComponentProps {
  folder: any;
  fileList: any;
  compoenentFileList: any;
  setComponentFileList: any;
  lastSelectedTopFolderId: any;
  setLastSelectedTopFolderId: any;
}

interface TreeNode {
  id: string;
  name: string;
  children?: TreeNode[];
  path: string;
  secured_path: string;
  isExpanded?: boolean;
  isFolder?: boolean;
  isEdit?: boolean;
  isCreate?: boolean;
  isRequireOCR: boolean;
  folder_id: string;
  isOCRProcessing: boolean;
  url: string;
}

const SourceMaterialFolder: React.FC<SourceMaterialFolderComponentProps> = ({
  folder,
  compoenentFileList,
  setComponentFileList,
  lastSelectedTopFolderId,
  setLastSelectedTopFolderId,
}) => {
  const [tree, setTree] = useState<TreeNode[]>([]);
  const { folderList, getFilePath } = useRagContext();

  useEffect(() => {
    if (folder?.structure) {
      setTree(buildTree(folder?.structure));
    }
  }, [folder?.structure]);

  const isSelectedFolder = (folder_id: any): boolean => {
    let isSelected = false;
    folderList.forEach((parentFolder) => {
      if (
        isSelectedSubFolder(
          parentFolder?.structure[Object.keys(parentFolder?.structure)[0]],
          folder_id
        )
      ) {
        isSelected = true;
      }
    });

    return isSelected;
  };

  const isSelectedSubFolder = (folder: any, folder_id: any): boolean => {
    if (folder.id === folder_id) {
      let isSelected = true;
      if (Object.keys(folder.children).length === 0) return false;
      Object.keys(folder.children).forEach((key) => {
        let item = folder.children[key];
        if (
          !(
            (item.isFolder && isSelectedSubFolder(item, item.id)) ||
            (!item.isFolder && isSelectedFile(key, folder_id))
          )
        ) {
          isSelected = false;
        }
      });
      return isSelected;
    } else {
      let isSelected = false;
      if (Object.keys(folder.children).length === 0) return false;
      Object.keys(folder.children).forEach((key) => {
        let item = folder.children[key];
        if (item.isFolder && isSelectedSubFolder(item, folder_id)) {
          isSelected = true;
        }
      });
      return isSelected;
    }
  };

  const isHighlightedBrainstormFolder = (folder_id: any): boolean => {
    let isSelected = false;
    folderList.forEach((parentFolder) => {
      if (
        isHighlightedBrainstormSubFolder(
          parentFolder?.structure[Object.keys(parentFolder?.structure)[0]],
          folder_id
        )
      ) {
        isSelected = true;
      }
    });

    return isSelected;
  };

  const isHighlightedBrainstormSubFolder = (
    folder: any,
    folder_id: any
  ): boolean => {
    if (folder.id === folder_id) {
      let isSelected = false;
      if (Object.keys(folder.children).length === 0) return false;
      Object.keys(folder.children).forEach((key) => {
        let item = folder.children[key];
        if (
          (item.isFolder && isHighlightedBrainstormSubFolder(item, item.id)) ||
          (!item.isFolder && isSelectedFile(key, folder_id))
        ) {
          isSelected = true;
        }
      });
      return isSelected;
    } else {
      let isSelected = false;
      if (Object.keys(folder.children).length === 0) return false;
      Object.keys(folder.children).forEach((key) => {
        let item = folder.children[key];
        if (
          item.isFolder &&
          isHighlightedBrainstormSubFolder(item, folder_id)
        ) {
          isSelected = true;
        }
      });
      return isSelected;
    }
  };

  const isSelectedFile = (file_name: any, folder_id: string): boolean => {
    if (
      compoenentFileList.find(
        (e: any) => e === getFilePath(file_name, folder_id)
      )
    ) {
      return true;
    } else {
      return false;
    }
  };

  const toggleSelectFolder = (
    folder_id: any,
    topBrainstormFolderId: string
  ) => {
    let tempSelectedFiles: any;
    if (lastSelectedTopFolderId !== topBrainstormFolderId) {
      tempSelectedFiles = [];
    } else {
      tempSelectedFiles = JSON.parse(JSON.stringify(compoenentFileList));
    }

    folderList.forEach((parentFolder) => {
      toggleSelectBrainstormSubFolder(
        parentFolder?.structure[Object.keys(parentFolder?.structure)[0]],
        folder_id,
        isSelectedFolder(folder_id),
        tempSelectedFiles
      );
    });
    setLastSelectedTopFolderId(topBrainstormFolderId);
    setComponentFileList(tempSelectedFiles);
  };

  const toggleSelectBrainstormSubFolder = (
    folder: any,
    folder_id: any,
    selected: boolean,
    selectedFiles: any
  ) => {
    if (folder.id === folder_id) {
      Object.keys(folder.children).forEach((key) => {
        let item = folder.children[key];
        if (item.isFolder) {
          toggleSelectBrainstormSubFolder(
            item,
            item.id,
            selected,
            selectedFiles
          );
        } else {
          let index = selectedFiles.findIndex(
            (e: any) => e === getFilePath(key, folder_id)
          );
          if (selected) {
            selectedFiles.splice(index, 1);
          } else {
            if (index === -1) selectedFiles.push(getFilePath(key, folder_id));
          }
        }
      });
    } else {
      Object.keys(folder.children).forEach((key) => {
        let item = folder.children[key];
        if (item.isFolder) {
          toggleSelectBrainstormSubFolder(
            item,
            folder_id,
            selected,
            selectedFiles
          );
        }
      });
    }
  };

  const toggleSelectFile = (
    file_name: any,
    folder_id: string,
    topBrainstormFolderId: string
  ) => {
    let tempSelectedFiles: any;
    if (lastSelectedTopFolderId !== topBrainstormFolderId) {
      tempSelectedFiles = [];
    } else {
      tempSelectedFiles = JSON.parse(JSON.stringify(compoenentFileList));
    }
    let index = tempSelectedFiles.findIndex(
      (e: any) => e === getFilePath(file_name, folder_id)
    );
    if (index !== -1) {
      tempSelectedFiles.splice(index, 1);
    } else {
      tempSelectedFiles.push(getFilePath(file_name, folder_id));
    }
    setLastSelectedTopFolderId(topBrainstormFolderId);
    console.log("tempSelectedFiles", tempSelectedFiles);

    setComponentFileList(tempSelectedFiles);
  };

  const toggleNode = (e: any, node: TreeNode) => {
    node.isExpanded = !node.isExpanded;
    setTree([...tree]);
    const slashCount = (node.path.match(/\//g) || []).length || 1;
    let folderContainer: any = $("#start-brainstorm-folder-container");
    if (folderContainer) {
      folderContainer.animate({ scrollLeft: 50 * slashCount }, 500);
    }
  };

  const buildTree = (
    data: any,
    path: string = "",
    secured_path: string = ""
  ): TreeNode[] => {
    return Object.keys(data).map((key) => ({
      folder_id: folder.id,
      id: data[key].id,
      name: key,
      children: buildTree(
        data[key].children || {},
        path + "/" + key,
        secured_path + "/" + data[key].id
      ),
      path: path + "/" + key,
      secured_path: secured_path,
      isExpanded: isHighlightedBrainstormFolder(data[key].id),
      isFolder: data[key]?.isFolder ? data[key]?.isFolder : false,
      isEdit: false,
      isCreate: data[key]?.isCreate,
      isRequireOCR: data[key]?.isRequireOCR ? data[key]?.isRequireOCR : false,
      isOCRProcessing: false,
      url: data[key]?.url || null,
    }));
  };

  const renderTree = (nodes: TreeNode[], folderId?: string) => (
    <>
      {nodes.map((node, index) => (
        <div key={index}>
          {node.isFolder === true ? (
            <>
              <div
                key={index}
                className={`px-[9px] xl:px-3 py-[7px] xl:py-[10px] flex items-start gap-[9px] xl:gap-3 rounded-[10px] group sidebar-folder w-full mb-[3px] xl:mb-1 ${
                  isHighlightedBrainstormFolder(node.id) ? "bg-[#0000000d]" : ""
                }`}
              >
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    toggleNode(e, node);
                  }}
                  className="outline-none flex-none"
                >
                  {node.isExpanded ? (
                    <HiOutlineFolderMinus className="text-lg xl:text-2xl text-[#3B82F6] flex-none" />
                  ) : (
                    <HiOutlineFolderPlus className="text-lg xl:text-2xl text-[#3B82F6] flex-none" />
                  )}
                </button>
                <p
                  className="text-xs xl:text-base font-medium text-[#3B82F6] folder-name text-break min-w-[97px] xl:min-w-[130px] !max-w-none hover:!whitespace-nowrap hover:!overflow-hidden hover:!text-ellipsis"
                  onClick={(e) => {
                    toggleNode(e, node);
                  }}
                >
                  {node.name}
                </p>
                <button
                  className={`outline-none flex-none ml-auto flex h-[18px] xl:h-6 items-center ${
                    isSelectedFolder(node.id)
                      ? "visible"
                      : "invisible group-hover:visible"
                  }`}
                  onClick={(e) => {
                    e.stopPropagation();
                    toggleSelectFolder(node.id, node.folder_id);
                  }}
                >
                  <BpCheckbox
                    size="small"
                    className="w-[10px] xl:w-[14px] h-[10px] xl:h-[14px]"
                    checked={isSelectedFolder(node.id)}
                  ></BpCheckbox>
                </button>
              </div>
              {node.isExpanded && (
                <div
                  className={`relative pl-[27px] xl:pl-9 flex-col sidebar-folder-items ${
                    node.isExpanded ? "flex" : "hidden"
                  }`}
                >
                  {node.children && node.children.length > 0 ? (
                    renderTree(node.children, node.id)
                  ) : (
                    <></>
                  )}
                </div>
              )}
            </>
          ) : (
            <>
              <div
                className={`w-full sidebar-folder-item px-[9px] xl:px-3 py-[7px] xl:py-[10px] rounded-xl flex items-start gap-[3px] xl:gap-1 justify-between cursor-pointer group text-[#52525B] mb-[3px] xl:mb-1 ${
                  isSelectedFile(node.name, folderId as string)
                    ? "bg-[#E4E4E7]"
                    : "bg-transparent"
                }`}
              >
                <p className={`text-xs xl:text-base document-title`}>
                  {node?.url ? (
                    <a
                      href={node?.url}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-blue-500 underline"
                      onClick={(e) => e.stopPropagation()}
                    >
                      {node.name}
                    </a>
                  ) : (
                    node.name
                  )}
                </p>
                <div className="flex gap-[6px] xl:gap-2 ml-auto">
                  <button
                    className={`outline-none flex-none ml-auto flex h-[18px] xl:h-6 items-center ${
                      isSelectedFile(node.name, folderId as string)
                        ? "visible"
                        : "invisible group-hover:visible"
                    }`}
                    onClick={(e) => {
                      e.stopPropagation();
                      toggleSelectFile(
                        node.name,
                        folderId as string,
                        node.folder_id
                      );
                    }}
                  >
                    <BpCheckbox
                      size="small"
                      className="w-[10px] xl:w-[14px] h-[10px] xl:h-[14px]"
                      checked={isSelectedFile(node.name, folderId as string)}
                    ></BpCheckbox>
                  </button>
                </div>
              </div>
            </>
          )}
        </div>
      ))}
    </>
  );
  return <>{renderTree(tree)}</>;
};

export default SourceMaterialFolder;
