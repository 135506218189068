import React, { useState } from "react";
import Modal from "@mui/material/Modal";
import { IoCloseOutline } from "react-icons/io5";
import { FormControlLabel, Radio, RadioGroup } from "@mui/material";
import axios from "axios";
import { useRagContext } from "../../contexts/RagContext";
import { useUserContext } from "../../contexts/UserContext";
import { toast } from "react-toastify";

interface ShareFeedbackModalProps {}

const ShareFeedbackModal: React.FC<ShareFeedbackModalProps> = () => {
  const {
    shareFeedbackVisible,
    closeShareFeedbackModal,
    getFilePath,
    pageName,
    selectedFiles,
    chatTopicId,
    retriever,
    subRetriever,
    selectedConceptId,
  } = useRagContext();

  const { user } = useUserContext();

  const [feedbackType, setFeedbackType] = useState("idea");
  const [title, setTitle] = useState<string>("");
  const [description, setDescription] = useState<string>("");

  const handleSubmit = async () => {
    let fileList = selectedFiles.map((file: any) => {
      return getFilePath(file.file_name, file.folder_id);
    });

    const details = {
      pageName: pageName,
      chatTopicId: chatTopicId,
      retriever: retriever,
      subRetriever: subRetriever,
      selectedConceptId: selectedConceptId,
      fileList: fileList,
    };

    if (title === "") return;
    if (description === "") return;
    try {
      let response = await axios.post(
        process.env.REACT_APP_API_URL + "api/shareFeedback",
        {
          title: title,
          description: description,
          user_id: user?.id,
          user_name: user?.unsafeMetadata?.user_name,
          type: feedbackType,
          details: details,
        }
      );
      if (response?.status === 200) {
        setFeedbackType("idea");
        setTitle("");
        setDescription("");
        closeShareFeedbackModal();

        toast.success("Feedback submitted!", {
          position: "top-right",
          autoClose: 1000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: 0,
          toastId: "my_toast",
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Modal
      open={shareFeedbackVisible}
      onClose={closeShareFeedbackModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 w-[520px] bg-white rounded-[10px] shadow-lg">
        <div className="py-4 px-6 flex items-start justify-between border-b border-solid border-[#E4E4E7] gap-4">
          <div className="">
            <div className="text-lg leading-[22px] font-bold text-[#18181B] mb-1">
              Share Feedback
            </div>
            <div className="text-sm leading-[22px] font-medium text-[#71717A]">
              We would love to hear your thoughts, suggestions, concerns or
              problems with anything so we can improve!
            </div>
          </div>
          <button
            className="w-8 h-8 border border-solid border-[#D4D4D8] flex items-center justify-center flex-none rounded-full"
            onClick={() => closeShareFeedbackModal()}
          >
            <IoCloseOutline className="w-6 h-6 text-[#09090B]" />
          </button>
        </div>
        <div className="p-6">
          <div className="w-full mb-8">
            <div className="w-full mb-4">
              <div className="px-1 text-base font-normal text-[#09090B] mb-[6px]">
                Feedback Type*
              </div>
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
                value={feedbackType}
                onChange={(e) => setFeedbackType(e.target.value)}
              >
                <FormControlLabel
                  sx={{ marginRight: "32px" }}
                  value="idea"
                  control={<Radio size="small" disableRipple />}
                  label={
                    <span className="text-base font-medium text-[#52525B]">
                      Idea
                    </span>
                  }
                />
                <FormControlLabel
                  sx={{ marginRight: "32px" }}
                  value="issue"
                  control={<Radio size="small" disableRipple />}
                  label={
                    <span className="text-base font-medium text-[#52525B]">
                      Issue
                    </span>
                  }
                />
                <FormControlLabel
                  sx={{ marginRight: "32px" }}
                  value="praise"
                  control={<Radio size="small" disableRipple />}
                  label={
                    <span className="text-base font-medium text-[#52525B]">
                      Praise
                    </span>
                  }
                />
                <FormControlLabel
                  sx={{ marginRight: "32px" }}
                  value="other"
                  control={<Radio size="small" disableRipple />}
                  label={
                    <span className="text-base font-medium text-[#52525B]">
                      Something else
                    </span>
                  }
                />
              </RadioGroup>
            </div>
            <div className="w-full mb-4">
              <label
                htmlFor="share-feedback-title"
                className="px-1 text-base font-normal text-[#09090B] mb-[6px]"
              >
                Title*
              </label>
              <input
                type="text"
                id="share-feedback-title"
                name="share-feedback-title"
                className="w-full border border-solid border-[#D4D4D8] outline-none px-4 py-3 rounded-lg h-12 text-sm font-medium text-[#3F3F46]"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                placeholder="Enter feedback title"
              />
            </div>
            <div className="w-full">
              <label
                htmlFor="share-feedback-description"
                className="px-1 text-base font-normal text-[#09090B] mb-[6px]"
              >
                Describe Your Feedback*
              </label>
              <textarea
                id="share-feedback-description"
                name="share-feedback-description"
                className="w-full border border-solid border-[#D4D4D8] outline-none px-4 py-3 rounded-lg h-24 text-sm font-medium text-[#3F3F46]"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                placeholder="Enter your feedback"
              />
            </div>
          </div>
          <div className="flex gap-4">
            <button
              className="outline-none border border-solid border-[#71717A] h-12 flex items-center justify-center rounded-lg w-full"
              onClick={() => closeShareFeedbackModal()}
            >
              <span className="text-base font-bold text-[#71717A]">Cancel</span>
            </button>
            <button
              className={`outline-none ${
                title === "" || description === ""
                  ? "bg-[#3b83f680]"
                  : "bg-[#3B82F6]"
              } h-12 flex items-center justify-center rounded-lg w-full`}
              onClick={() => handleSubmit()}
              disabled={title === "" || description === ""}
            >
              <span className="text-base font-bold text-white">Submit</span>
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ShareFeedbackModal;
