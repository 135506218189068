import React from 'react'

const FilterIcon: React.FC<any> = (props: any) => {
  return (
    <svg
      width={20}
      height={20}
      viewBox="0 0 20 20"
      fill="none"
      stroke="#09090B"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.80431 2.5C3.08423 2.5 2.5 3.09405 2.5 3.82534V4.60537C2.5 5.14706 2.70599 5.66798 3.07446 6.05981L7.11258 10.3536L7.11436 10.3509C7.89393 11.149 8.33254 12.2279 8.33254 13.3528V17.1626C8.33254 17.4172 8.59891 17.5798 8.82 17.4597L11.1196 16.2066C11.4668 16.017 11.6834 15.6486 11.6834 15.2487V13.3428C11.6834 12.2242 12.1158 11.1499 12.8883 10.3536L16.9264 6.05981C17.294 5.66798 17.5 5.14706 17.5 4.60537V3.82534C17.5 3.09405 16.9167 2.5 16.1966 2.5H3.80431Z"
        stroke="inherit"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default FilterIcon
