import React from "react";
import { useLayoutContext } from "../contexts/LayoutContext";
import { BreadCrumbMenu } from "../Components/common/BreadCrumb";
import { MdKeyboardArrowRight } from "react-icons/md";

interface BreadCrumbProps {
  page: string;
}

const BreadCrumb: React.FC<BreadCrumbProps> = ({ page }) => {
  const { breadCrumbs } = useLayoutContext();
  const pageTitle: any = {
    home: "Home",
    research: "Research",
    concept: "Concept",
  };

  return (
    <div className="w-full h-[54px] px-[18px] flex items-center border-b border-solid border-[#D4D4D8] flex-none bg-white">
      {/* <div className="text-sm leading-[22px] font-medium text-[#71717A] cursor-pointer">
        {pageTitle[page]}
      </div> */}
        {breadCrumbs.map((menu: BreadCrumbMenu, index) => (
          <div className="flex gap-1 items-center" key={index}>
            <button
              className="border-none outline-none"
              onClick={() => {
                if (menu.onClick) {
                  menu.onClick()
                }
              }}
            >
              <span
                className={`text-sm leading-[22px] font-medium ${
                  menu.active ? 'text-[#18181B]' : 'text-[#71717A]'
                }`}
              >{menu.title}</span>
            </button>
            {index !== breadCrumbs.length - 1 && (
              <MdKeyboardArrowRight className={`w-4 h-4 text-[#18181B]`} />
            )}
          </div>
        ))}
    </div>
  );
};

export default BreadCrumb;
