import React from 'react'

const UpdatePassword: React.FC = () => {
  return (
    <div className="pt-4 px-[148px] pb-16">
      <div className="flex flex-col gap-5">
        <div className="w-full">
          <div className="text-base font-normal text-[#09090B] pl-1 pb-1">
            Old password
          </div>
          <input
            type="text"
            className="w-full h-12 rounded-lg bg-[#FCFCFC] border border-[#D4D4D8] px-4 py-3 text-[#3F3F46] text-sm leading-6 font-medium"
            placeholder="Enter your old password"
          />
        </div>
        <div className="w-full">
          <div className="text-base font-normal text-[#09090B] pl-1 pb-1">
            New password
          </div>
          <input
            type="text"
            className="w-full h-12 rounded-lg bg-[#FCFCFC] border border-[#D4D4D8] px-4 py-3 text-[#3F3F46] text-sm leading-6 font-medium"
            placeholder="Create new password"
          />
        </div>
        <div className="w-full">
          <div className="text-base font-normal text-[#09090B] pl-1 pb-1">
            Confirm new password
          </div>
          <input
            type="text"
            className="w-full h-12 rounded-lg bg-[#FCFCFC] border border-[#D4D4D8] px-4 py-3 text-[#3F3F46] text-sm leading-6 font-medium"
            placeholder="Re-enter new password"
          />
        </div>
        <button className="w-full h-12 flex items-center justify-center rounded-lg cursor-pointer bg-[#3B82F6] outline-none">
          <span className="text-base font-bold text-white">
            Update password
          </span>
        </button>
      </div>
    </div>
  )
}

export default UpdatePassword
