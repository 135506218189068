import React from 'react'

const DuplicateIcon: React.FC<any> = (props: any) => {
  return (
    <svg
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="#09090B"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.505 5.49509C9.74434 4.73443 8.64993 4.48962 7.40004 4.48962H6.17791C6.19254 3.47963 6.40898 2.96767 6.68833 2.68833C6.97768 2.39898 7.51659 2.17712 8.60004 2.17712H11.4C12.4835 2.17712 13.0224 2.39898 13.3118 2.68833C13.6011 2.97768 13.823 3.51659 13.823 4.60004V7.40004C13.823 8.48349 13.6011 9.0224 13.3118 9.31175C13.0324 9.5911 12.5204 9.80754 11.5105 9.82217V8.60004C11.5105 7.35015 11.2656 6.25574 10.505 5.49509ZM11.5098 11.5098C12.714 11.4956 13.7667 11.2433 14.505 10.505C15.2656 9.74434 15.5105 8.64993 15.5105 7.40004V4.60004C15.5105 3.35016 15.2656 2.25574 14.505 1.49509C13.7443 0.734434 12.6499 0.489624 11.4 0.489624H8.60004C7.35015 0.489624 6.25574 0.734434 5.49509 1.49509C4.75679 2.23339 4.50446 3.28613 4.49026 4.49026C3.28613 4.50446 2.23339 4.75679 1.49509 5.49509C0.734434 6.25574 0.489624 7.35015 0.489624 8.60004V11.4C0.489624 12.6499 0.734434 13.7443 1.49509 14.505C2.25574 15.2656 3.35016 15.5105 4.60004 15.5105H7.40004C8.64993 15.5105 9.74434 15.2656 10.505 14.505C11.2433 13.7667 11.4956 12.714 11.5098 11.5098ZM9.82296 10.668C9.82296 10.6676 9.82296 10.6671 9.82296 10.6667V8.60004C9.82296 7.51659 9.6011 6.97768 9.31175 6.68833C9.0224 6.39898 8.48349 6.17712 7.40004 6.17712H5.33337L4.60004 6.17712C3.51659 6.17712 2.97768 6.39898 2.68833 6.68833C2.39898 6.97768 2.17712 7.51659 2.17712 8.60004V11.4C2.17712 12.4835 2.39898 13.0224 2.68833 13.3118C2.97768 13.6011 3.51659 13.823 4.60004 13.823H7.40004C8.48349 13.823 9.0224 13.6011 9.31175 13.3118C9.6011 13.0224 9.82296 12.4835 9.82296 11.4V10.668Z"
        fill="inherit"
      />
    </svg>
  )
}

export default DuplicateIcon
