import React, { useEffect, useState } from "react";
import Modal from "@mui/material/Modal";
import { IoCloseOutline } from "react-icons/io5";
import { useRagContext } from "../../../../contexts/RagContext";
import axios from "axios";
import { fiveForceTabList } from "../ConceptModal";

interface EditConceptFiveForceModalProps {
  open?: boolean;
  handleClose?: () => void;
  onEdit?: () => void;
  concept: any;
}

const EditConceptFiveForceModal: React.FC<EditConceptFiveForceModalProps> = ({
  open,
  handleClose,
  onEdit,
  concept,
}) => {
  const { getConceptFolders } = useRagContext();
  const [currentTab, setCurrentTab] = useState("Competitors");
  const [value, setValue] = useState<any>({
    Competitors: [],
    Substitutes: [],
    New_Entrants: [],
    Buyers: [],
    Suppliers: [],
  });

  useEffect(() => {
    if (concept && open) {
      setValue(concept["FiveForces"]);
    } else {
      setValue({
        Competitors: [],
        Substitutes: [],
        New_Entrants: [],
        Buyers: [],
        Suppliers: [],
      });
    }
  }, [concept, open]);

  const handleSave = async () => {
    let params: any = {
      id: concept?.id,
      FiveForces: value,
    };
    try {
      let response = await axios.post(
        process.env.REACT_APP_API_URL + "api/editConcept",
        params
      );
      if (response?.status === 200) {
        getConceptFolders();
        if (handleClose) handleClose();
        if (onEdit) onEdit();
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Modal
      open={Boolean(open)}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 w-[730px] xl:w-[974px] h-[589px] xl:h-[786px] bg-white rounded-[10px] shadow-lg">
        <div className="py-[9px] xl:py-3 xl:py-4 px-[18px] xl:px-6 flex items-center justify-between border-b border-solid border-[#E4E4E7] gap-3 xl:gap-4">
          <div className="">
            <div className="text-[13px] xl:text-lg leading-[16px] xl:leading-[22px] font-bold text-[#18181b] mb-[3px] xl:mb-1">
              Five Forces
            </div>
          </div>
          <button
            className="w-6 xl:w-8 h-6 xl:h-8 border border-solid border-[#D4D4D8] flex items-center justify-center flex-none rounded-full"
            onClick={() => {
              if (handleClose) handleClose();
            }}
          >
            <IoCloseOutline className="w-[15px] xl:w-5 h-[15px] xl:h-5 text-[#09090B]" />
          </button>
        </div>
        <div className="p-[18px] xl:p-6">
          <div className="w-full mb-6 xl:mb-8">
            <div className="flex gap-[9px] xl:gap-[10px] border-b border-solid border-[#D4D4D8]">
              {fiveForceTabList.map((fiveFoceTabItem, index) => (
                <div
                  className={`px-[3px] xl:px-1 pt-[1px] pb-2 xl:pb-[11px] cursor-pointer text-[10px] xl:text-sm font-semibold border-b xl:border-b-2 border-solid ${
                    fiveFoceTabItem.key === currentTab
                      ? "text-[#3B82F6] border-[#3B82F6]"
                      : "text-[#A1A1AA] border-transparent"
                  }`}
                  onClick={() => setCurrentTab(fiveFoceTabItem.key)}
                  key={index}
                >
                  {fiveFoceTabItem.title}
                </div>
              ))}
            </div>
            <div className="w-full bg-[#FCFCFC] border-l border-r border-b border-solid border-[#D4D4D8] p-3 xl:p-4 overflow-x-hidden overflow-y-auto h-[418px] xl:h-[558px] rounded-b-2xl">
              {value[currentTab].map((fiveForceItem: any, index: number) => (
                <div
                  className="w-full flex flex-col gap-3 xl:gap-4"
                  key={index}
                >
                  <div className="w-full">
                    <label
                      htmlFor={`edit-${index}-name`}
                      className="text-xs xl:text-base px-[3px] xl:px-1 mb-[3px] xl:mb-1"
                    >
                      Name
                    </label>
                    <input
                      id={`edit-${index}-name`}
                      name={`edit-${index}-name`}
                      className="w-full border border-solid border-[#D4D4D8] outline-none px-3 xl:px-4 py-[9px] xl:py-3 rounded-lg text-[10px] xl:text-sm font-medium text-[#3F3F46] h-9 xl:h-12"
                      value={fiveForceItem.name}
                      onChange={(e) => {
                        setValue((prev: any) => {
                          return {
                            ...prev,
                            [currentTab]: prev[currentTab].map(
                              (prevEl: any, prevIndex: number) => {
                                if (prevIndex === index) {
                                  return {
                                    ...fiveForceItem,
                                    name: e.target.value,
                                  };
                                } else {
                                  return prevEl;
                                }
                              }
                            ),
                          };
                        });
                      }}
                    />
                  </div>
                  <div className="w-full">
                    <label
                      htmlFor={`edit-${index}-website_url`}
                      className="text-xs xl:text-base px-[3px] xl:px-1 mb-[3px] xl:mb-1"
                    >
                      Website Url
                    </label>
                    <input
                      id={`edit-${index}-website_url`}
                      name={`edit-${index}-website_url`}
                      className="w-full border border-solid border-[#D4D4D8] outline-none px-3 xl:px-4 py-[9px] xl:py-3 rounded-lg text-[10px] xl:text-sm font-medium text-[#3F3F46] h-9 xl:h-12"
                      value={fiveForceItem.website_url}
                      onChange={(e) => {
                        setValue((prev: any) => {
                          return {
                            ...prev,
                            [currentTab]: prev[currentTab].map(
                              (prevEl: any, prevIndex: number) => {
                                if (prevIndex === index) {
                                  return {
                                    ...fiveForceItem,
                                    website_url: e.target.value,
                                  };
                                } else {
                                  return prevEl;
                                }
                              }
                            ),
                          };
                        });
                      }}
                    />
                  </div>
                  <div className="w-full">
                    <label
                      htmlFor={`edit-${index}-relevance`}
                      className="text-xs xl:text-base px-[3px] xl:px-1 mb-[3px] xl:mb-1"
                    >
                      Relevance
                    </label>
                    <textarea
                      id={`edit-${index}-relevance`}
                      name={`edit-${index}-relevance`}
                      className="w-full border border-solid border-[#D4D4D8] outline-none px-3 xl:px-4 py-[9px] xl:py-3 rounded-lg text-[10px] xl:text-sm font-medium text-[#3F3F46] h-[60px] xl:h-20"
                      value={fiveForceItem.relevance}
                      onChange={(e) => {
                        setValue((prev: any) => {
                          return {
                            ...prev,
                            [currentTab]: prev[currentTab].map(
                              (prevEl: any, prevIndex: number) => {
                                if (prevIndex === index) {
                                  return {
                                    ...fiveForceItem,
                                    relevance: e.target.value,
                                  };
                                } else {
                                  return prevEl;
                                }
                              }
                            ),
                          };
                        });
                      }}
                    />
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="flex gap-3 xl:gap-4">
            <button
              className="outline-none border border-solid border-[#71717A] h-9 xl:h-12 flex items-center justify-center rounded-lg w-full"
              onClick={() => {
                if (handleClose) handleClose();
              }}
            >
              <span className="text-xs xl:text-base font-bold text-[#71717A]">
                Cancel
              </span>
            </button>
            <button
              className="outline-none bg-[#3B82F6] h-9 xl:h-12 flex items-center justify-center rounded-lg w-full"
              onClick={() => handleSave()}
            >
              <span className="text-xs xl:text-base font-bold text-white">
                Save
              </span>
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default EditConceptFiveForceModal;
