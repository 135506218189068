import React from "react";
import Header from "./Header";
import BreadCrumb from "./BreadCrumb";
import Sidebar from "./Sidebar";

interface LayoutProps {
  children?: React.ReactNode;
  page: string;
}

const Layout: React.FC<LayoutProps> = ({ children, page }) => {
  return (
    <div className="w-screen h-screen flex flex-col sm:w-screen md:w-screen lg:w-screen xl:w-screen 2xl:w-screen">
      <Header />
      <BreadCrumb page={page} />
      <div className="flex flex-1 overflow-hidden">
        <Sidebar page={page} />
        <div className="flex flex-1 relative">{children}</div>
      </div>
    </div>
  );
};

export default Layout;
