import React, { useEffect } from "react";
import { RouteComponentProps } from "react-router-dom";
import { BreadCrumbMenu } from "../../Components/common/BreadCrumb";
import { useLayoutContext } from "../../contexts/LayoutContext";

type PrivacyComponentProps = RouteComponentProps;
const Privacy: React.FC<PrivacyComponentProps> = ({ history }) => {
  const { setBreadCrumbs } = useLayoutContext();

  useEffect(() => {
    const menus: BreadCrumbMenu[] = [
      {
        title: "Privacy",
        active: true,
        onClick: () => {},
      },
    ];
    setBreadCrumbs(menus);
  }, []);

  return (
    <div className="w-full h-full p-20 font-Satoshi bg-white overflow-auto">
      <div className="w-full text-lg max-w-5xl mx-auto">
        <div className="text-center font-bold">
          GROWTH SIGNALS PRIVACY NOTICE
        </div>
        <div className="h-4"></div>
        <div className="">
          <span className="font-bold">Last Updated:</span> October 20, 2024
          <div className="h-4"></div>
          This privacy notice (“
          <span className="font-bold">Privacy Notice</span>”) describes how
          Profitable Signals, Inc. (“<span className="font-bold">we</span>”, “
          <span className="font-bold">us</span>”, “
          <span className="font-bold">our</span>”) collects, uses and discloses
          information about you when you use our website (growthsignals.com),
          applications, services, tools and features (collectively, the “
          <span className="font-bold">Services</span>”). For the purposes of
          this Privacy Notice, “<span className="font-bold">you</span>” and “
          <span className="font-bold">your</span>” means you as our customer.
          Please note that the Services are designed for users in the United
          States only and are not intended for users located outside the United
          States.
          <div className="h-4"></div>
          This Privacy Notice governs use of our Services between us and our
          customers. If you receive any derivative works from the output of our
          Services, namely, a customer-branded instance of our Services, this
          Privacy Notice does not apply. Our customer (the entity that provides
          access to the platform to you as the end user) is the controller of
          that any data and any other products collected via its separate
          instance. As such, we encourage you to review the customer’s policies
          for information on how they handle your personal data.
          <div className="h-4"></div>
          Please read this Privacy Notice carefully. By using any of the
          Services, you agree to the collection, use, and disclosure of your
          information as described in this Privacy Notice. If you do not agree
          to this Privacy Notice, please do not use or access the Services.
          <div className="h-4"></div>
          <div className="font-bold">1. CHANGES TO THIS PRIVACY NOTICE</div>
          <div className="h-4"></div>
          We may modify this Privacy Notice from time to time, in which case we
          will update the “Last Updated” date at the top of this Privacy Notice.
          If we make material changes to how we use or disclose information we
          collect, we will use reasonable efforts to notify you (such as by
          emailing you at the last email address you provided us, by posting
          notice of such changes on the Services, or by other means consistent
          with applicable law) and will take additional steps as required by
          applicable law. If you do not agree to any updates to this Privacy
          Notice, please do not continue using or accessing the Services.
          <div className="h-4"></div>
          <div className="font-bold">2. COLLECTION AND USE OF INFORMATION</div>
          <div className="h-4"></div>
          When you use or access the Services, we collect certain categories of
          information about you from a variety of sources.
          <div className="h-4"></div>
          <span className="italic">Information You Provide to Us</span>
          <div className="h-4"></div>
          Some features of the Services may require you to directly provide
          certain information about yourself. You may elect not to provide this
          information, but doing so may prevent you from using or accessing
          these features. Information that you directly submit through our
          Services includes:
          <div className="h-4"></div>
          <ul className="list-disc pl-16">
            <li className="mb-4">
              <span className="font-bold">Basic contact details</span>, such as
              name and email address. We use this information to provide the
              Services and to communicate with you (including to tell you about
              products or services that may be of interest to you).
            </li>
            <li className="mb-4">
              <span className="font-bold">Applicant details</span>, such as
              information included in your resume or CV, references, and job
              history. We use applicant details to process your application for
              employment and to evaluate your candidacy.
            </li>
            <li className="mb-4">
              <span className="font-bold">User-generated content</span>, such as
              text, videos, images, or files (“
              <span className="font-bold">Customer Inputs</span>”), submitted by
              you as well as new content that is generated and output based on
              such prompts (“<span className="font-bold">Customer Outputs</span>
              ”). As discussed in our terms, you agree not to include any
              personal data in any Customer Inputs. We may use Customer Inputs
              and Customer Outputs to improve the Services.
            </li>
            <li className="mb-4">
              <span className="font-bold">
                Any other information you choose to include in communications
                with us
              </span>
              , for example, when sending us a message.
            </li>
          </ul>
          <div className="h-4"></div>
          <span className="italic">Information Collected Automatically</span>
          <div className="h-4"></div>
          We may also use cookies or other tracking technologies to
          automatically collect certain information about your interactions with
          the Services. We collect and use this information to tailor your
          experience with the Services, run analytics, better understand user
          interactions with the Services. Such information includes:
          <div className="h-4"></div>
          <ul className="list-disc pl-16">
            <li className="mb-4">
              <span className="font-bold">Device information</span>, such as
              device type, operating system, unique device identifier, and
              internet protocol (IP) address.
            </li>
            <li className="mb-4">
              <span className="font-bold">Location information</span>, such as
              approximate location.
            </li>
            <li className="mb-4">
              <span className="font-bold">
                Other information regarding your interaction with the Services
              </span>
              , such as browser type, log data, date and time stamps and
              clickstream data.
            </li>
          </ul>
          <div className="h-4"></div>
          Most browsers accept cookies automatically, but you may be able to
          control the way in which your devices permit the use of cookies. If
          you so choose, you may block or delete certain of our cookies from
          your browser; however, blocking or deleting cookies may cause some of
          the Services, including any portal features and general functionality,
          to work incorrectly. Your browser settings may also allow you to
          transmit a “Do Not Track” signal when you visit various websites. Like
          many websites, our website is not designed to respond to “Do Not
          Track” signals received from browsers. To learn more about “Do Not
          Track” signals, you can visit{" "}
          <a
            href="http://www.allaboutdnt.com/"
            target="_blank"
            rel="noreferrer"
            className="text-[#3B82F6]"
          >
            http://www.allaboutdnt.com/
          </a>
          .<div className="h-4"></div>
          To opt out of tracking by Google Analytics,{" "}
          <a
            href="https://tools.google.com/dlpage/gaoptout"
            target="_blank"
            rel="noreferrer"
            className="text-[#3B82F6]"
          >
            here
          </a>
          .<div className="h-4"></div>
          <span className="italic">
            Information Collected From Other Sources
          </span>
          <div className="h-4"></div>
          We may obtain information about you from outside sources, including
          information that we collect directly from third parties and
          information from third parties that you choose to share with us. Such
          information includes:
          <div className="h-4"></div>
          <ul className="list-disc pl-16">
            <li className="mb-4">
              <span className="font-bold">Analytics data</span> we receive from
              analytics providers such as Google Analytics or Amplitude which we
              use to improve our website, communications and the Services.
            </li>
            <li className="mb-4">
              <span className="font-bold">
                Information we receive from career websites
              </span>
              , such as LinkedIn, Monster, or Indeed, which we use to process
              your application for employment.
            </li>
          </ul>
          <div className="h-4"></div>
          Any information we receive from outside sources will be treated in
          accordance with this Privacy Notice. We are not responsible for the
          accuracy of the information provided to us by third parties and are
          not responsible for any third party’s policies or practices. For more
          information, see the section below, Third Party Websites and Links.
          <div className="h-4"></div>
          In addition to the specific uses described above, we may use any of
          the above information to provide you with the Services and to maintain
          our business relationship, as a matter of our legitimate interests,
          including by enhancing the safety and security of our Services (e.g.,
          troubleshooting, data analysis, testing, system maintenance, and
          reporting), providing customer support, sending service and other
          non-marketing communications, monitoring and analyzing trends,
          conducting internal research and development, complying with
          applicable legal obligations, enforcing any applicable terms of
          service, and protecting the Services, our rights, and the rights of
          our employees, users or other individuals.
          <div className="h-4"></div>
          <div className="font-bold">3. DISCLOSURE OF YOUR INFORMATION</div>
          <div className="h-4"></div>
          We may disclose your information for legitimate purposes subject to
          this Privacy Notice, including the following categories of third
          parties:
          <div className="h-4"></div>
          <ul className="list-disc pl-16">
            <li className="mb-4">
              Our affiliates or others within our corporate group, as a matter
              of our legitimate interests to efficiently provide the Services.
            </li>
            <li className="mb-4">
              Vendors or other service providers who help us provide the
              Services, including for system administration, cloud storage,
              security, artificial intelligence services, customer relationship
              management, marketing communications and web analytics.
            </li>
            <li className="mb-4">
              Professional advisors, such as auditors, law firms, or accounting
              firms, as a matter of our legitimate interests to assess, protect,
              enforce and defend our rights and to comply with our legal and
              regulatory obligations.
            </li>
            <li className="mb-4">
              Third parties in connection with or anticipation of an asset sale,
              merger, bankruptcy, or other business transaction, as a matter of
              our legitimate interests to run a successful and efficient
              business.
            </li>
          </ul>
          <div className="h-4"></div>
          We may also disclose your information as needed to comply with
          applicable law or any obligations thereunder or in our legitimate
          interests to cooperate with law enforcement, judicial orders, and
          regulatory inquiries, to enforce any applicable terms of service, and
          to ensure the safety and security of our business, employees, and
          users.
          <div className="h-4"></div>
          <div className="font-bold">4. THIRD PARTY WEBSITES AND LINKS</div>
          <div className="h-4"></div>
          We may provide links to third-party websites or platforms. If you
          follow links to sites or platforms that we do not control and are not
          affiliated with us, you should review the applicable privacy notice,
          policies and other terms. We are not responsible for the privacy or
          security of, or information found on, these sites or platforms.
          Information you provide on public or semi-public venues, such as
          third-party social networking platforms, may also be viewable by other
          users of the Services and/or users of those third-party platforms
          without limitation as to its use. Our inclusion of such links does
          not, by itself, imply any endorsement of the content on such platforms
          or of their owners or operators.
          <div className="h-4"></div>
          <div className="font-bold">5. CHILDREN’S PRIVACY</div>
          <div className="h-4"></div>
          Our Services are not intended for children, and we do not seek or
          knowingly collect any personal information about children. If we
          become aware that we have unknowingly collected information about a
          child, in particular any child under 13 years of age, we will make
          commercially reasonable efforts to delete such information from our
          database. If you are the parent or guardian of a child under 13 years
          of age who has provided us with their personal information, you may
          contact us using the below information to request that it be deleted.
          <div className="h-4"></div>
          <div className="font-bold">6. DATA SECURITY AND RETENTION</div>
          <div className="h-4"></div>
          Despite our reasonable efforts to protect your information, no
          security measures are impenetrable, and we cannot guarantee “perfect
          security.” Any information you send to us electronically, while using
          the Services or otherwise interacting with us, may not be secure while
          in transit. We recommend that you do not use unsecure channels to send
          us sensitive or confidential information.
          <div className="h-4"></div>
          We retain your information for as long as is reasonably necessary for
          the purposes specified in this Privacy Notice or until you delete your
          files. When determining the length of time to retain your information,
          we consider various criteria, including whether we need the
          information to continue to provide you the Services, resolve a
          dispute, enforce our contractual agreements, prevent harm, promote
          safety, security and integrity, or protect ourselves, including our
          rights, property or products. You may choose to delete your files at
          any time by by contacting us at the email found in{" "}
          <span className="font-bold">Section 8</span>.
          <div className="h-4"></div>
          <div className="font-bold">7. CALIFORNIA PRIVACY RIGHTS</div>
          <div className="h-4"></div>
          Any California residents under the age of 18 who have registered to
          use the Services and who have posted content or information on the
          Services can request that such information be removed from the
          Services by contacting us at the information provided below. Such
          request must state that they personally posted such content or
          information and detail where the content or information is posted. We
          will make reasonable good faith efforts to remove the post from
          prospective public view or anonymize it so the minor cannot be
          individually identified. This removal process cannot ensure complete
          or comprehensive removal. For instance, third parties may have
          republished the post and archived copies of it may be stored by search
          engines and others that we do not control.
          <div className="h-4"></div>
          <div className="font-bold">8. HOW TO CONTACT US</div>
          <div className="h-4"></div>
          Should you have any questions about our privacy practices or this
          Privacy Notice, please email us at support@profitablesignals.com.
        </div>
      </div>
    </div>
  );
};

export default Privacy;
