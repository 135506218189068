import React, { useEffect, useState } from "react";
import { useUserContext } from "../contexts/UserContext";
import { Popover } from "@mui/material";
import logoutIcon from "../assets/svg/log-out.svg";
import settingIcon from "../assets/svg/settings.svg";
import { useClerk } from "@clerk/clerk-react";
import { useRagContext } from "../contexts/RagContext";

const ProfileComponent: React.FC = () => {
  const { user } = useUserContext();
  const { setting, handleSettingModalOpen } = useRagContext();

  const [popoverAnchorEl, setPopoverAnchorEl] =
    React.useState<HTMLElement | null>(null);
  const [popoverOpen, setPopoverOpen] = useState(false);

  useEffect(() => {
    if (setting) {
      if (setting?.verified === false) {
        handleSettingModalOpen();
      }
    }
  }, [setting]);

  const { signOut } = useClerk();
  const logout = async () => {
    await signOut();
  };

  const handlePopoverClose = (type: string) => {
    setPopoverOpen(false);
    setPopoverAnchorEl(null);
  };

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setPopoverOpen(true);
    setPopoverAnchorEl(event.currentTarget);
  };
  return (
    <>
      <div
        className={`justify-between items-center h-10 xl:h-[54px] rounded-lg px-3 py-2 flex cursor-pointer`}
        onClick={(e) => {
          e.preventDefault();
          handlePopoverOpen(e);
        }}
      >
        <div className="flex items-center gap-2 overflow-hidden">
          <div className="w-7 h-7 xl:w-[34px] xl:h-[34px] flex items-center justify-center bg-[#D4D4D8] rounded-lg flex-none text-black text-[10px] xl:text-sm leading-[18px] xl:leading-6 uppercase">
            {user?.unsafeMetadata?.user_name?.[0]}
          </div>
          <div className="w-full overflow-hidden text-ellipsis">
            <div className="w-full overflow-hidden text-ellipsis text-[10px] xl:text-sm font-medium leading-4 xl:leading-[22px] text-[#18181B]">
              {user?.unsafeMetadata?.user_name}
            </div>
            <div className="w-full overflow-hidden text-ellipsis text-[9px] xl:text-xs leading-[15px] xl:leading-5 text-[#0000009a]">
              {user?.primaryEmailAddress?.emailAddress}
            </div>
          </div>
        </div>
      </div>

      <Popover
        open={popoverOpen}
        anchorEl={popoverAnchorEl}
        onClose={() => handlePopoverClose("other")}
        className="mt-[10px] ml-[5px]"
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <div className="p-[4.5px] xl:p-[6px] bg-white rounded-lg flex flex-col gap-1 w-[176px] xl:w-[235px]">
          <div
            className="w-full px-[9px] xl:px-3 py-[7px] xl:py-[10px] flex gap-[9px] xl:gap-3 cursor-pointer"
            onClick={(e) => logout()}
          >
            <img
              src={logoutIcon}
              className="w-[18px] xl:w-6 h-[18px] xl:h-6 flex-none"
              alt=""
            />
            <div className="text-xs xl:text-base font-medium text-[#3F3F46]">
              Log out
            </div>
          </div>
          <div
            className="w-full px-[9px] xl:px-3 py-[7px] xl:py-[10px] flex gap-[9px] xl:gap-3 cursor-pointer"
            onClick={(e) => handleSettingModalOpen()}
            data-event-name="Profile: Settings Clicked"
          >
            <img
              src={settingIcon}
              className="w-[18px] xl:w-6 h-[18px] xl:h-6 flex-none"
              alt=""
            />
            <div
              className="text-xs xl:text-base font-medium text-[#3F3F46]"
              data-event-name="Profile: Settings Clicked"
            >
              Settings
            </div>
          </div>
        </div>
      </Popover>
    </>
  );
};

export default ProfileComponent;
