import React, { useEffect, useMemo, useState } from 'react'
import Modal from '@mui/material/Modal'
import { IoCloseOutline } from 'react-icons/io5'
import { useRagContext } from '../../../../contexts/RagContext'
import axios from 'axios'
import { conceptItemList } from '../ConceptModal'

interface EditConceptMainModalProps {
  open?: boolean
  handleClose?: () => void
  onEdit?: () => void
  concept: any
  selectedConceptList: any
}

const EditConceptMainModal: React.FC<EditConceptMainModalProps> = ({
  open,
  handleClose,
  onEdit,
  concept,
  selectedConceptList,
}) => {
  const { getConceptFolders } = useRagContext()
  const [value, setValue] = useState<string>('')
  const selectedConceptItem = useMemo(() => {
    return conceptItemList.find((e) => e.key === selectedConceptList)
  }, [selectedConceptList])

  useEffect(() => {
    if (concept && open) {
      setValue(concept[selectedConceptList])
    }
  }, [concept, open, selectedConceptList])

  const handleUpdate = async () => {
    let params: any = {
      id: concept?.id,
      [selectedConceptList]: value,
    }
    try {
      let response = await axios.post(
        process.env.REACT_APP_API_URL + 'api/editConcept',
        params,
      )
      if (response?.status === 200) {
        getConceptFolders()
        if (handleClose) handleClose()
        if (onEdit) onEdit()
      }
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <Modal
      open={Boolean(open)}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 w-[974px] h-[786px] bg-white rounded-[10px] shadow-lg">
        <div className="py-4 px-6 flex items-center justify-between border-b border-solid border-[#E4E4E7] gap-4">
          <div className="">
            <div className="text-lg leading-[22px] font-bold text-[#18181b] mb-1">
              {selectedConceptItem?.title}
            </div>
          </div>
          <button
            className="w-8 h-8 border border-solid border-[#D4D4D8] flex items-center justify-center flex-none rounded-full"
            onClick={() => {
              if (handleClose) handleClose()
            }}
          >
            <IoCloseOutline className="w-5 h-5 text-[#09090B]" />
          </button>
        </div>
        <div className="p-6">
          <div className="w-full flex flex-col gap-4 mb-8">
            <textarea
              id="edit-concept-value"
              name="edit-concept-value"
              className="w-full border border-solid border-[#D4D4D8] outline-none px-4 py-3 rounded-2xl text-sm font-medium text-[#3F3F46] h-[594px] resize-none"
              value={value}
              onChange={(e) => setValue(e.target.value)}
            />
          </div>
          <div className="flex gap-4">
            <button
              className="outline-none border border-solid border-[#71717A] h-12 flex items-center justify-center rounded-lg w-full"
              onClick={() => {
                if (handleClose) handleClose()
              }}
            >
              <span className="text-base font-bold text-[#71717A]">Cancel</span>
            </button>
            <button
              className="outline-none bg-[#3B82F6] h-12 flex items-center justify-center rounded-lg w-full"
              onClick={() => handleUpdate()}
            >
              <span className="text-base font-bold text-white">Update</span>
            </button>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default EditConceptMainModal
