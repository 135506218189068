import React, { useState, useEffect } from "react";
import { useUserContext } from "../../contexts/UserContext";
import { toast } from "react-toastify";
import { MdRadioButtonChecked, MdRadioButtonUnchecked } from "react-icons/md";

import axios from "axios";
import { useRagContext } from "../../contexts/RagContext";
import * as amplitude from "@amplitude/analytics-browser";

interface ConfigurationComponentProps {
  handleClose: () => void;
}

const Configuration: React.FC<ConfigurationComponentProps> = ({
  handleClose,
}) => {
  const [isSaving, setSaving] = useState(false);
  const [isOpenAI, setIsOpenAI] = useState(false);
  const [OPENAI_API_KEY, setOPENAI_API_KEY] = useState("");
  const [AZURE_OPENAI_API_KEY, setAZURE_OPENAI_API_KEY] = useState("");
  const [AZURE_ENDPOINT, setAZURE_ENDPOINT] = useState("");
  const [AZURE_OPENAI_GPT4_DEPLOYMENT, setAZURE_OPENAI_GPT4_DEPLOYMENT] =
    useState("");
  const [AZURE_OPENAI_GPT35_DEPLOYMENT, setAZURE_OPENAI_GPT35_DEPLOYMENT] =
    useState("");
  const [
    AZURE_OPENAI_EMBEDDING_DEPLOYMENT,
    setAZURE_OPENAI_EMBEDDING_DEPLOYMENT,
  ] = useState("");
  const [PINECONE_API_KEY, setPINECONE_API_KEY] = useState("");
  const [PINECONE_ENVIRONMENT, setPINECONE_ENVIRONMENT] = useState("");
  const [PINECONE_INDEX_NAME, setPINECONE_INDEX_NAME] = useState("");

  const { user, isLoaded, isSignedIn } = useUserContext();
  const { setting, getSettingData } = useRagContext();
  const [hasStartedTyping, setHasStartedTyping] = useState(false);
  useEffect(() => {
    setIsOpenAI(setting?.isOpenAI === undefined ? false : setting?.isOpenAI);
    setOPENAI_API_KEY(setting?.OPENAI_API_KEY);
    setAZURE_OPENAI_API_KEY(setting?.AZURE_OPENAI_API_KEY);
    setAZURE_ENDPOINT(setting?.AZURE_ENDPOINT);
    setAZURE_OPENAI_GPT4_DEPLOYMENT(setting?.AZURE_OPENAI_GPT4_DEPLOYMENT);
    setAZURE_OPENAI_GPT35_DEPLOYMENT(setting?.AZURE_OPENAI_GPT35_DEPLOYMENT);
    setAZURE_OPENAI_EMBEDDING_DEPLOYMENT(
      setting?.AZURE_OPENAI_EMBEDDING_DEPLOYMENT
    );
    setPINECONE_API_KEY(setting?.PINECONE_API_KEY);
    setPINECONE_ENVIRONMENT(setting?.PINECONE_ENVIRONMENT);
    setPINECONE_INDEX_NAME(setting?.PINECONE_INDEX_NAME);
  }, [setting]);

  const handleSave = async () => {
    try {
      let validate = false;
      if (isOpenAI) {
        if (
          OPENAI_API_KEY !== "" &&
          PINECONE_API_KEY !== "" &&
          PINECONE_ENVIRONMENT !== "" &&
          PINECONE_INDEX_NAME !== ""
        ) {
          validate = true;
        } else {
          validate = false;
        }
      } else {
        if (
          OPENAI_API_KEY !== "" &&
          AZURE_OPENAI_API_KEY !== "" &&
          AZURE_ENDPOINT !== "" &&
          AZURE_OPENAI_GPT4_DEPLOYMENT !== "" &&
          AZURE_OPENAI_GPT35_DEPLOYMENT !== "" &&
          AZURE_OPENAI_EMBEDDING_DEPLOYMENT !== "" &&
          PINECONE_API_KEY !== "" &&
          PINECONE_ENVIRONMENT !== "" &&
          PINECONE_INDEX_NAME !== ""
        ) {
          validate = true;
        } else {
          validate = false;
        }
      }
      if (!validate) {
        toast.warning("Please input all values for configuration fields", {
          position: "top-right",
          autoClose: 1000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: 0,
          toastId: "my_toast",
        });
        return;
      }
      setSaving(true);
      const response = await axios.post(
        process.env.REACT_APP_API_URL + "api/saveSetting",
        {
          team_id: user?.organizationMemberships[0]?.organization.id,
          isOpenAI: isOpenAI,
          OPENAI_API_KEY: OPENAI_API_KEY?.length > 90 ? "" : OPENAI_API_KEY,
          AZURE_OPENAI_API_KEY:
            AZURE_OPENAI_API_KEY?.length > 90 ? "" : AZURE_OPENAI_API_KEY,
          AZURE_ENDPOINT: AZURE_ENDPOINT?.length > 90 ? "" : AZURE_ENDPOINT,
          AZURE_OPENAI_GPT35_DEPLOYMENT:
            AZURE_OPENAI_GPT35_DEPLOYMENT?.length > 90
              ? ""
              : AZURE_OPENAI_GPT35_DEPLOYMENT,
          AZURE_OPENAI_GPT4_DEPLOYMENT:
            AZURE_OPENAI_GPT4_DEPLOYMENT?.length > 90
              ? ""
              : AZURE_OPENAI_GPT4_DEPLOYMENT,
          AZURE_OPENAI_EMBEDDING_DEPLOYMENT:
            AZURE_OPENAI_EMBEDDING_DEPLOYMENT?.length > 90
              ? ""
              : AZURE_OPENAI_EMBEDDING_DEPLOYMENT,
          PINECONE_API_KEY:
            PINECONE_API_KEY?.length > 90 ? "" : PINECONE_API_KEY,
          PINECONE_ENVIRONMENT:
            PINECONE_ENVIRONMENT?.length > 90 ? "" : PINECONE_ENVIRONMENT,
          PINECONE_INDEX_NAME:
            PINECONE_INDEX_NAME?.length > 90 ? "" : PINECONE_INDEX_NAME,
        }
      );
      if (response.status === 200) {
        handleClose();
        setSaving(false);
        toast.success("Saved setting successfully!", {
          position: "top-right",
          autoClose: 1000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: 0,
          toastId: "my_toast",
        });
      }
      getSettingData();
    } catch (error: any) {
      setSaving(false);
      toast.error(
        <div className="whitespace-pre-line">
          {error.response.data.message}
        </div>,
        {
          position: "top-right",
          autoClose: 10000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: false,
          progress: 0,
          toastId: "my_toast",
        }
      );
      getSettingData();
    }
  };

  return (
    <div>
      <div className="pt-3 xl:pt-4 px-[18px] xl:px-6 pb-[18px] xl:pb-6 border-b border-[#E4E4E7]">
        <div className="pb-3 xl:pb-4">
          <div className="text-xs xl:text-base font-normal text-[#09090B] pl-[3px] xl:pl-1 pb-[6px] xl:pb-2">
            Open AI Configuration
          </div>
          <div className="flex gap-[15px] xl:gap-5">
            <div
              className="flex gap-[6px] xl:gap-2 items-center cursor-pointer"
              onClick={() => setIsOpenAI(true)}
            >
              {isOpenAI ? (
                <MdRadioButtonChecked className="text-[13px] xl:text-lg text-[#3B82F6]" />
              ) : (
                <MdRadioButtonUnchecked className="text-[13px] xl:text-lg text-[#71717A]" />
              )}
              <span className="text-xs xl:text-base font-medium text-[#52525B]">
                OpenAI
              </span>
            </div>
            <div
              className="flex gap-[6px] xl:gap-2 items-center cursor-pointer"
              onClick={() => setIsOpenAI(false)}
            >
              {!isOpenAI ? (
                <MdRadioButtonChecked className="text-[13px] xl:text-lg text-[#3B82F6]" />
              ) : (
                <MdRadioButtonUnchecked className="text-[13px] xl:text-lg text-[#71717A]" />
              )}
              <span className="text-xs xl:text-base font-medium text-[#52525B]">
                Azure OpenAI
              </span>
            </div>
          </div>
        </div>
        {isOpenAI ? (
          <div className="flex flex-col gap-[6px] xl:gap-2 mb-3 xl:mb-4">
            <input
              id="openai_api_key"
              type={OPENAI_API_KEY?.length > 90 ? "password" : ""}
              className="w-full h-9 xl:h-12 rounded-lg bg-[#FCFCFC] border border-[#D4D4D8] px-3 xl:px-4 py-[9px] xl:py-3 text-[#3F3F46] text-[10px] xl:text-sm leading-[18px] xl:leading-6 font-medium"
              placeholder="OpenAI API Key"
              onChange={(e) => {
                setOPENAI_API_KEY(e.target.value);
              }}
              value={OPENAI_API_KEY}
            />
          </div>
        ) : (
          <div className="flex flex-col gap-[6px] xl:gap-2 mb-3 xl:mb-4">
            <input
              id="azure_openai_api_key"
              type={AZURE_OPENAI_API_KEY?.length > 90 ? "password" : ""}
              className="w-full h-9 xl:h-12 rounded-lg bg-[#FCFCFC] border border-[#D4D4D8] px-3 xl:px-4 py-[9px] xl:py-3 text-[#3F3F46] text-[10px] xl:text-sm leading-[18px] xl:leading-6 font-medium"
              placeholder="Azure OpenAI API Key"
              onChange={(e) => {
                setAZURE_OPENAI_API_KEY(e.target.value);
                // Track typing event in Amplitude
                if (!hasStartedTyping) {
                  amplitude.track("Settings: AZURE OPENAI API KEY Added", {
                    content: e.target.value,
                    length: e.target.value.length,
                  });
                  setHasStartedTyping(true); // Prevent tracking again until submission
                }
              }}
              value={AZURE_OPENAI_API_KEY}
            />
            <input
              id="azure_api_endpoint"
              type={AZURE_ENDPOINT?.length > 90 ? "password" : ""}
              className="w-full h-9 xl:h-12 rounded-lg bg-[#FCFCFC] border border-[#D4D4D8] px-3 xl:px-4 py-[9px] xl:py-3 text-[#3F3F46] text-[10px] xl:text-sm leading-[18px] xl:leading-6 font-medium"
              placeholder="Azure API Endpoint"
              onChange={(e) => {
                setAZURE_ENDPOINT(e.target.value);
                // Track typing event in Amplitude
                if (!hasStartedTyping) {
                  amplitude.track("Settings: Azure Endpoint Added", {
                    content: e.target.value,
                    length: e.target.value.length,
                  });
                  setHasStartedTyping(true); // Prevent tracking again until submission
                }
              }}
              value={AZURE_ENDPOINT}
            />
            <input
              id="gpt4_model_name"
              type={AZURE_OPENAI_GPT4_DEPLOYMENT?.length > 90 ? "password" : ""}
              className="w-full h-9 xl:h-12 rounded-lg bg-[#FCFCFC] border border-[#D4D4D8] px-3 xl:px-4 py-[9px] xl:py-3 text-[#3F3F46] text-[10px] xl:text-sm leading-[18px] xl:leading-6 font-medium"
              placeholder="GPT4 Model Name"
              onChange={(e) => {
                setAZURE_OPENAI_GPT4_DEPLOYMENT(e.target.value);
                // Track typing event in Amplitude
                if (!hasStartedTyping) {
                  amplitude.track("Settings: GPT4 Model Name Added", {
                    content: e.target.value,
                    length: e.target.value.length,
                  });
                  setHasStartedTyping(true); // Prevent tracking again until submission
                }
              }}
              value={AZURE_OPENAI_GPT4_DEPLOYMENT}
            />
            <input
              id="gpt35_model_name"
              type={
                AZURE_OPENAI_GPT35_DEPLOYMENT?.length > 90 ? "password" : ""
              }
              className="w-full h-9 xl:h-12 rounded-lg bg-[#FCFCFC] border border-[#D4D4D8] px-3 xl:px-4 py-[9px] xl:py-3 text-[#3F3F46] text-[10px] xl:text-sm leading-[18px] xl:leading-6 font-medium"
              placeholder="GPT3.5 Model Name"
              onChange={(e) => {
                setAZURE_OPENAI_GPT35_DEPLOYMENT(e.target.value);
                if (!hasStartedTyping) {
                  amplitude.track("Settings: GPT3.5 Model Name Added", {
                    content: e.target.value,
                    length: e.target.value.length,
                  });
                  setHasStartedTyping(true); // Prevent tracking again until submission
                }
              }}
              value={AZURE_OPENAI_GPT35_DEPLOYMENT}
            />
            <input
              id="embedding_name"
              type={
                AZURE_OPENAI_EMBEDDING_DEPLOYMENT?.length > 90 ? "password" : ""
              }
              className="w-full h-9 xl:h-12 rounded-lg bg-[#FCFCFC] border border-[#D4D4D8] px-3 xl:px-4 py-[9px] xl:py-3 text-[#3F3F46] text-[10px] xl:text-sm leading-[18px] xl:leading-6 font-medium"
              placeholder="Embedding Name"
              onChange={(e) => {
                setAZURE_OPENAI_EMBEDDING_DEPLOYMENT(e.target.value);
                if (!hasStartedTyping) {
                  amplitude.track("Settings: Embedding Name Added", {
                    content: e.target.value,
                    length: e.target.value.length,
                  });
                  setHasStartedTyping(true); // Prevent tracking again until submission
                }
              }}
              value={AZURE_OPENAI_EMBEDDING_DEPLOYMENT}
            />
          </div>
        )}
        <div className="text-xs xl:text-base font-normal text-[#09090B] pl-[3px] xl:pl-1 pb-[3px] xl:pb-1">
          Pinecone Configuration
        </div>
        <div className="flex flex-col gap-[6px] xl:gap-2 mb-3 xl:mb-4">
          <input
            id="pinecone_api_key"
            type={PINECONE_API_KEY?.length > 90 ? "password" : ""}
            className="w-full h-9 xl:h-12 rounded-lg bg-[#FCFCFC] border border-[#D4D4D8] px-3 xl:px-4 py-[9px] xl:py-3 text-[#3F3F46] text-[10px] xl:text-sm leading-[18px] xl:leading-6 font-medium"
            placeholder="Pinecone API Key"
            onChange={(e) => {
              setPINECONE_API_KEY(e.target.value);
              if (!hasStartedTyping) {
                amplitude.track("Settings: Pinecone API Key Added", {
                  content: e.target.value,
                  length: e.target.value.length,
                });
                setHasStartedTyping(true); // Prevent tracking again until submission
              }
            }}
            value={PINECONE_API_KEY}
          />
          <input
            id="pinecone_environment"
            type={PINECONE_ENVIRONMENT?.length > 90 ? "password" : ""}
            className="w-full h-9 xl:h-12 rounded-lg bg-[#FCFCFC] border border-[#D4D4D8] px-3 xl:px-4 py-[9px] xl:py-3 text-[#3F3F46] text-[10px] xl:text-sm leading-[18px] xl:leading-6 font-medium"
            placeholder="Pinecone Environment"
            onChange={(e) => {
              setPINECONE_ENVIRONMENT(e.target.value);
              if (!hasStartedTyping) {
                amplitude.track("Settings: Pinecone Enviorment Added", {
                  content: e.target.value,
                  length: e.target.value.length,
                });
                setHasStartedTyping(true); // Prevent tracking again until submission
              }
            }}
            value={PINECONE_ENVIRONMENT}
          />
          <input
            id="pinecone_index_name"
            type={PINECONE_INDEX_NAME?.length > 90 ? "password" : ""}
            className="w-full h-9 xl:h-12 rounded-lg bg-[#FCFCFC] border border-[#D4D4D8] px-3 xl:px-4 py-[9px] xl:py-3 text-[#3F3F46] text-[10px] xl:text-sm leading-[18px] xl:leading-6 font-medium"
            placeholder="Pinecone Index Name"
            onChange={(e) => {
              setPINECONE_INDEX_NAME(e.target.value);
              if (!hasStartedTyping) {
                amplitude.track("Settings: Pinecone Index Name Added", {
                  content: e.target.value,
                  length: e.target.value.length,
                });
                setHasStartedTyping(true); // Prevent tracking again until submission
              }
            }}
            value={PINECONE_INDEX_NAME}
          />
        </div>
      </div>
      <div className="p-[24px] xl:p-6 flex justify-end">
        <button
          className={`w-[132px] xl:w-[176px] h-9 xl:h-12 flex items-center justify-center rounded-lg cursor-pointer ${
            isSaving ? "bg-[#E4E4E7]" : "bg-[#3B82F6]"
          } outline-none`}
          onClick={() => handleSave()}
          data-event-name="Settings: Configuration Saved"
        >
          <span
            className={`text-xs xl:text-base font-bold ${
              isSaving ? "text-[#A1A1AA]" : "text-white"
            }`}
            data-event-name="Settings: Configuration Saved"
          >
            {" "}
            {isSaving ? "Saving..." : "Save"}
          </span>
        </button>
      </div>
    </div>
  );
};

export default Configuration;
