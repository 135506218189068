import React, { useEffect, useState } from 'react'
import Modal from '@mui/material/Modal'
import { FiMinus } from 'react-icons/fi'
import { IoIosCheckmark } from 'react-icons/io'
import { useRagContext } from '../../../../contexts/RagContext'
import ExpandModalIcon from '../../../icons/ExpandModalIcon'

const UploadFileModal: React.FC = () => {
  const {
    selectedUploadFiles,
    setSelectedUploadFiles,
    socket,
    socketId,
  } = useRagContext()
  const [open, setOpen] = useState(false)
  const [uploadedCount, setUploadedCount] = useState(0)
  const [successOpen, setSuccessOpen] = useState(false)

  useEffect(() => {
    if (socket) {
      socket.on('upload_progress', (data: any) => {
        if (data.socketId === socketId) {
          setUploadedCount(data.batchCount + data.index + 1)
        }
      })
    }

    return () => {
      if (socket) {
        return socket.off('upload_progress')
      }
    }
  }, [socket])

  useEffect(() => {
    if (
      selectedUploadFiles.length !== 0 &&
      uploadedCount === selectedUploadFiles.length
    ) {
      setTimeout(() => {
        setSuccessOpen(true)
        setSelectedUploadFiles([])
        setUploadedCount(0)
        setOpen(false)
      }, 3000)
      setTimeout(() => {
        setSuccessOpen(false)
      }, 6000)
    }
  }, [uploadedCount, selectedUploadFiles])

  return (
    <>
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 w-[436px] bg-white rounded-[10px] shadow-lg">
          <div className="px-6 py-4 flex items-center justify-between border-b border-solid border-[#E4E4E7]">
            <div className="text-lg leading-[22px] font-bold text-[#18181B]">
              Add Folder
            </div>
            <button
              className="w-8 h-8 border border-solid border-[#D4D4D8] flex items-center justify-center flex-none rounded-full"
              onClick={() => setOpen(false)}
            >
              <FiMinus className="w-5 h-5 text-[#09090B]" />
            </button>
          </div>
          <div className="p-6">
            <div className="text-base font-medium text-[#71717A] mb-[10px]">
              {`Uploading - ${Math.min(
                uploadedCount + 1,
                selectedUploadFiles.length,
              )}/${selectedUploadFiles.length} files`}
            </div>
            <div className="flex flex-col gap-[10px] max-h-[340px] overflow-x-hidden overflow-y-auto">
              {selectedUploadFiles.map((e: any, index: number) => (
                <div
                  className="px-4 py-3 shadow-sm border border-solid border-[#E4E4E7] flex items-center rounded-xl"
                  key={index}
                >
                  <div className="flex-1">
                    <div className="text-sm font-medium text-[#0F0F0F] mb-2">
                      {e.name}
                    </div>
                    <div className="h-1 rounded-full bg-[#71717A] overflow-hidden">
                      <div
                        className="h-1 rounded-e-full bg-[#16A34A] transition-all ease-in-out duration-[3s]"
                        style={{
                          width: uploadedCount > index ? `100%` : '0%',
                        }}
                      ></div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </Modal>
      {selectedUploadFiles.length > 0 && !open && (
        <div className="fixed bottom-12 left-1/2 -translate-x-1/2 shadow-lg bg-white rounded-xl flex items-center gap-8 py-3 px-6 h-[82px]">
          <div className="flex-1">
            <div className="text-base font-bold text-[#18181B]">{`Uploading - ${Math.min(
              uploadedCount + 1,
              selectedUploadFiles.length,
            )}/${selectedUploadFiles.length} files`}</div>
            <div className="text-xs leading-5 font-medium text-[#71717A] mb-[10px]">
              Uploading selected document file from your file manager
            </div>
            <div className="h-1 rounded-full bg-[#71717A] overflow-hidden">
              <div
                className="h-1 rounded-e-full bg-[#3B82F6] transition-all ease-in-out duration-[3s]"
                style={{
                  width: `${
                    (uploadedCount * 100) / selectedUploadFiles.length
                  }%`,
                }}
              ></div>
            </div>
          </div>
          <button
            className="border border-solid border-[#D4D4D8] w-8 h-8 flex items-center justify-center flex-none rounded-full"
            onClick={() => setOpen(true)}
          >
            <ExpandModalIcon className="w-[18px] h-[18px]" />
          </button>
        </div>
      )}
      {successOpen && (
        <div className="fixed bottom-12 left-1/2 -translate-x-1/2 shadow-lg bg-white rounded-xl flex items-center gap-8 py-3 px-6 h-[82px]">
          <div className="flex-1">
            <div className="text-base font-bold text-[#18181B]">
              Successfully!
            </div>
            <div className="text-xs leading-5 font-medium text-[#71717A] mb-[10px]">
              All the selected files are uploaded
            </div>
          </div>
          <button
            className="border border-solid border-[#D4D4D8] bg-[#DCFCE7] w-8 h-8 flex items-center justify-center flex-none rounded-full"
            onClick={() => setSuccessOpen(false)}
          >
            <IoIosCheckmark className="w-[18px] h-[18px] text-[#16A34A]" />
          </button>
        </div>
      )}
    </>
  )
}

export default UploadFileModal
