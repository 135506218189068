import React from "react";
import { useLayoutContext } from "../contexts/LayoutContext";
import { BreadCrumbMenu } from "../Components/common/BreadCrumb";
import { MdKeyboardArrowRight } from "react-icons/md";

interface BreadCrumbProps {
  page: string;
}

const BreadCrumb: React.FC<BreadCrumbProps> = ({ page }) => {
  const { breadCrumbs } = useLayoutContext();
  return (
    <div className="w-full h-10 xl:h-[54px] px-[13px] xl:px-[18px] flex items-center border-b border-solid border-[#D4D4D8] flex-none bg-white">
      {breadCrumbs.map((menu: BreadCrumbMenu, index) => (
        <div className="flex gap-[3px] xl:gap-1 items-center" key={index}>
          <button
            className="border-none outline-none h-[14px] xl:h-[22px] flex items-center"
            onClick={() => {
              if (menu.onClick) {
                menu.onClick();
              }
            }}
          >
            <span
              className={`text-[10px] xl:text-sm leading-4 xl:leading-[22px] font-medium ${
                menu.active ? "text-[#18181B]" : "text-[#71717A]"
              }`}
            >
              {menu.title}
            </span>
          </button>
          {index !== breadCrumbs.length - 1 && (
            <MdKeyboardArrowRight
              className={`w-3 xl:w-4 h-3 xl:h-4 text-[#18181B]`}
            />
          )}
        </div>
      ))}
    </div>
  );
};

export default BreadCrumb;
