// src/UserContext.tsx
import React, { createContext, useContext, useEffect, useState } from 'react';
import { useUser } from '@clerk/clerk-react';

interface UserContextType {
    user: any | null;
    isLoaded: boolean;
    isSignedIn: boolean;
}

const UserContext = createContext<UserContextType | undefined>(undefined);

export const UserProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const { isLoaded, isSignedIn, user } = useUser();
    const [userData, setUserData] = useState<any | null>(null);

    useEffect(() => {
        if (isLoaded && isSignedIn) {
            setUserData(user);
        }
    }, [isLoaded, isSignedIn, user]);

    return (
        <UserContext.Provider value={{ user: userData, isLoaded: !!isLoaded, isSignedIn: !!isSignedIn }}>
            {children}
        </UserContext.Provider>
    );
};

export const useUserContext = () => {
    const context = useContext(UserContext);
    if (!context) {
        throw new Error('useUserContext must be used within a UserProvider');
    }
    return context;
};
